import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Unstable_Grid2"
import { useState } from "react"
import { TbCircleCheck } from "react-icons/tb"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Form, Header } from "semantic-ui-react"
import PrincipalLayout from "../../../components/Common/PrincipalLayout"
import { CustomButton } from "../../../components/CustomButton/CustomButton"
import { CognitoAuthService } from "../../../Services/CognitoAuthService"
import styles from "./createAgency.module.css"

const SuccessCreateAgency = () => {
  const [code, setCode] = useState("")
  const [error, setError] = useState<null | string>(null)
  const [search, setSearch] = useSearchParams()

  const navigate = useNavigate()
  const loginPage = async () => {
    if (!code) {
      setError("Please enter the code")
      return
    }

    const email = search.get("email")?.replaceAll(" ", "+") || ""

    new CognitoAuthService()
      .confirmSignUp(email, code)
      .then((res) => {
        navigate("/admin")
      })
      .catch((reason) => {
        setError(reason as string)
      })
  }

  return (
    <PrincipalLayout>
      <Box sx={{ flexGrow: 1, color: "#000", padding: "15vw" }}>
        <Grid container spacing={6} rowSpacing={4}>
          <Grid xs={12}>
            <div className={styles.sucessIcon}>
              <TbCircleCheck size={80} style={{ color: "#86E0A7" }} />
            </div>
          </Grid>
          <Grid xs={12}>
            <Header as="h1" style={{ fontSize: "25px", textAlign: "center" }}>
              Account has been successfully created
            </Header>
          </Grid>
          <Grid xs={12}>
            <p
              style={{
                fontSize: "16px",
                color: "#384057",
                textAlign: "center",
              }}
            >
              Please use your User Name and Password to login
            </p>
          </Grid>
          <Grid xs={12}>
            <Header as="h3" style={{ fontSize: "25px", textAlign: "center" }}>
              Please enter the verification code you received via email
            </Header>
          </Grid>
          <Grid xs={12}>
            <TextField
              fullWidth
              value={code}
              onChange={(e) => setCode(e.target.value)}
              type="text"
              variant="outlined"
              placeholder="Verification Code"
            />
          </Grid>
          <Grid xs={12} sx={{ textAlign: "-webkit-center" }}>
            <CustomButton
              style={{
                margin: "14px",
                width: "40%",
                fontSize: "19px",
                background: "none",
                border: "1px solid #000094",
                color: "#000094",
              }}
              text="Login"
              onClick={() => loginPage()}
              sx={{ mt: 5, mb: 1 }}
            />
          </Grid>
          {error && (
            <Grid xs={12} sx={{ textAlign: "-webkit-center" }}>
              <p style={{ color: "red" }}>{JSON.stringify(error)}</p>
            </Grid>
          )}
        </Grid>
      </Box>
    </PrincipalLayout>
  )
}

export default SuccessCreateAgency
