/* eslint-disable react/no-unescaped-entities */
import {
  Alert,
  Box,
  Button,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { FC, ReactElement, useState, useEffect } from "react";
import { CustomButton } from "../components/CustomButton/CustomButton";
import { RequiredStringField } from "../Helpers/FormValidations";
import { styled } from "@mui/material/styles";
// import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
import useForm, { FormField } from "../Hooks/useForm";
import { LayoutSign } from "../Layout/layout-sign/layout-sign";
import { LayoutContainer } from "../Layout/layout.container";
import { useLocation, useNavigate } from "react-router-dom";
import { CognitoAuthService } from "../Services/CognitoAuthService";
import {
  updateFirstResponderbyId,
  sendPhoneCodeMFA,
} from "../Services/firstResponderService";
import { sendVerificationCodeMFA } from "../Services/emailService";
import { FirstResponderData } from "../Interfaces/FirstResponder";
import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";

const CssTextField = styled(TextField)({
  "& label": {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#858C94",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    background: "#FFF",
    borderRadius: "8px",
    width: "328px",
    color: "black",
  },
});

export const ValidationCode: FC<any> = (): ReactElement => {
  const navigate = useNavigate();
  const cognitoAuthService = new CognitoAuthService();
  const [isSentEmail, setIsSentEmail] = useState<boolean>(false);
  const { confirmSignUp } = useCognitoAwsV2();
  const [code, setCode] = useState<string>("");
  const [mfaCode, setMFACode] = useState<string>("");
  const location = useLocation();
  const firstResponder = location.state as FirstResponderData;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const { form, errors, handleChange, handleBlur, handleSubmit } = useForm(
    {
      code: "",
    },
    validateformField,
    submitForm
  );

  const sendVerificationCode = async () => {
    setIsLoading(true);
    setIsSentEmail(false);
    // TODO: SMS sending
    // await sendPhoneCodeMFA("+573102583518",firstResponder.subCognitoId);

    const customCode = Math.floor(100000 + Math.random() * 900000);
    await updateFirstResponderbyId(firstResponder.id, {
      mfaCode: String(customCode),
    })
      .then(async (res: any) => {
        console.log(res);
        await sendVerificationCodeMFA(firstResponder.email, String(customCode))
          .then(async (res: any) => {
            setIsSentEmail(true); //
          })
          .catch(async (err: any) => {
            setErrorMessage(err.message);
            setOpen(true);
          });
        setMFACode(res.mfaCode);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
        setErrorMessage("The Code was not generated");
        setOpen(true);
      });
  };

  async function submitForm() {
    if (!readyToSubmit()) return;
    if (!firstResponder.mfa) {
      await confirmSignUp(firstResponder.email, code)
        .then(async (res: any) => {
          await updateFirstResponderbyId(firstResponder.id, {
            mfaCode: "123",
            mfa: false,
          }).then(async (res: any) => {
            navigate("/SearchSubscriber", { state: firstResponder.email });
          });
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setOpen(true);
        });
    } else {
      if (code === mfaCode) {
        navigate("/SearchSubscriber", { state: firstResponder });
      } else {
        setErrorMessage("The code is invalid. Please try again.");
        setOpen(true);
      }
    }
  }

  function validateformField(fieldName: string, fieldValue: string): FormField {
    let value: string = "";

    switch (fieldName) {
      case "code": {
        value = RequiredStringField(fieldName, fieldValue);
        break;
      }
    }
    return {
      name: fieldName,
      value: value,
    } as FormField;
  }

  function readyToSubmit(): boolean {
    return !(!form.code || !!errors.code || isLoading);
  }

  useEffect(() => {
    setMFACode(firstResponder?.mfaCode);
  }, []);

  return (
    <>
      <LayoutSign
        children={
          <LayoutContainer>
            <Typography component="h2" variant="h5">
              Verification Code
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 3, mb: 0 }}
            >
              <CssTextField
                placeholder="Enter code"
                name="code"
                autoComplete="off"
                onChange={(e) => {
                  setCode(e.target.value);
                  handleChange(e, false);
                }}
                onBlur={handleBlur}
                value={form.code}
              />
              {isSentEmail ? (
                <Typography
                  fontStyle="normal"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="20PX"
                  margin="9px 40px"
                  textAlign="initial"
                  color="#FFFFFF"
                >
                  A verification code was sent to {firstResponder.email}
                </Typography>
              ) : null}

              {firstResponder.mfa ? (
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Button
                      onClick={() => sendVerificationCode()}
                      variant="text"
                      style={{
                        textTransform: "none",
                        height: 8,
                        fontFamily: "Source Sans Pro",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "white",
                      }}
                    >
                      Send via email
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => sendVerificationCode()}
                      variant="text"
                      style={{
                        textTransform: "none",
                        height: 8,
                        fontFamily: "Source Sans Pro",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "white",
                      }}
                    >
                      Resend code
                    </Button>
                  </Grid>
                </Grid>
              ) : null}

              <Grid item pt={4}>
                <Alert
                  severity="warning"
                  icon={false}
                  sx={{ fontSize: 10, background: "#fff6d7" }}
                >
                  If you do not see the email in a few minutes, check your{" "}
                  <b>"Junk Mail"</b> or <b>"Spam"</b> folder and add the
                  MyMedHistory's email to your <b>"Safe Sender List." </b>If
                  nothing helped, please try submitting the form again.
                </Alert>
              </Grid>

              <CustomButton
                text="Submit"
                disabled={!readyToSubmit()}
                isLoading={isLoading}
                type="submit"
                sx={{ mt: 5, mb: 1 }}
              />
            </Box>
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {errorMessage}
              </Alert>
            </Snackbar>
          </LayoutContainer>
        }
      ></LayoutSign>
    </>
  );
};

export default ValidationCode;
