import { FC } from "react"

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import Dialog from "@mui/material/Dialog"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"

import { useStepVerificationContext } from "../../contexts/StepVerificationContext"
import StepVerificationSuccess from "./StepVerificationSuccess"
import StepVerification from "./StepVerificationForm"
import StepVerificationStart from "./StepVerificationStart"
import StepVerificationDesactive from "./StepVerificationDesactive"

const StepVerificationDialog: FC<Props> = ({ open }) => {
  const {
    dialogStatus,
    isActive,
    handleClose,
    resetStatusDialog
  } = useStepVerificationContext();

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose()
      }}
      fullWidth
      maxWidth="md"
    >
      <Grid container direction="column" alignItems="center">
        <Grid item container p={1} direction="row-reverse">
          <IconButton
            onClick={() => {
              handleClose()
              resetStatusDialog()
            }}
            sx={{ color: "#000094" }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>

        {!isActive && dialogStatus.start && (
          <StepVerificationStart />
        )}

        {isActive && (
          <StepVerificationDesactive />
        )}

        {dialogStatus.stepVerify && (
          <StepVerification />
        )}

        {dialogStatus.stepverifySuccess && (
          <StepVerificationSuccess />
        )}
      </Grid>
    </Dialog>
  )
}
interface Props {
  open: boolean
}

export default StepVerificationDialog
