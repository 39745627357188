import { FC } from "react"

import { Stack } from "@mui/material"
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import SecurityUpdateGoodOutlinedIcon from '@mui/icons-material/SecurityUpdateGoodOutlined';
import Typography from "@mui/material/Typography"

import { useStepVerificationContext } from "../../contexts/StepVerificationContext"

const StepVerificationStart: FC = () => {
  const { setDialogStatus } = useStepVerificationContext();

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      pt={3}
      px={8}
      pb={8}
    >
      <Grid item color='#000094'>
        <AdminPanelSettingsOutlinedIcon sx={{
          fontSize: 76,
        }} />
      </Grid>

      <Grid item>
        <Typography color="#242627" fontSize={25} fontWeight={700}>
          Protect your account with 2-Step Verification
        </Typography>
      </Grid>

      <Grid item mt={0.5} width={700}>
        <Typography color="#384057" fontSize={16} fontWeight={500} align="center">
          Prevent hackers from accesing your account with an additional layer of security. When you sign in 2-Step verification helps make sure your personal information stays private, safe and secure.
        </Typography>
      </Grid>

      <Grid item mt={3} width={700}>
        <Stack direction="row" spacing={0.5} justifyContent="start" alignItems="center" >
          <SecurityUpdateGoodOutlinedIcon sx={{ color: '#000094' }} />
          <Typography color="#242627" fontSize={18} fontWeight={700}>
            Verification via Email
          </Typography>
        </Stack>
        <Typography marginLeft={3.5} color="#6A7288" fontSize={14} fontWeight={500} width={696}>
          In addition to your password, each time you log in, you’ll need to enter the verification code sent to your email. Please note that your email will be registered in our database.
        </Typography>
      </Grid>

      <Grid item mt={3}>
        <Button
          variant="contained"
          sx={{
            height: 48,
            width: 85,
            textTransform: 'none',
            fontSize: '1rem',
            color: '#FFF',
            borderRadius: '8px',
            backgroundColor: '#000094',
            fontWeight: 700,
          }}
          onClick={() => {
            setDialogStatus({ start: false, stepVerify: true, stepverifySuccess: false })
          }}
        >
          Start
        </Button>
      </Grid>
    </Grid>
  )
}

export default StepVerificationStart