import { ThemeOptions } from "@mui/material"

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        backgroundImage: `linear-gradient(180deg, #3B8AFF 0%, #0048B3 100%)`,
      },
    },
  },
}

const componentsDark = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        background: "#2D2C31",
      },
    },
  },
}

const typography = {
  fontFamily: [
    "-apple-system",
    "Gotham",
    '"Segoe UI"',
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
    '"Source Sans Pro"',
  ].join(","),
}

export const ThemePrimary: ThemeOptions = {
  palette: {
    mode: "light",
  },
  components: components,
  typography: typography,
}

export const ThemeBackgroundDark: ThemeOptions = {
  components: componentsDark,
  typography: typography,
}
