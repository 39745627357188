import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FC, useState } from "react";
import useAuthUserData from "../../Hooks/useAuthUserData";
import { useFirstRespondersForAgency } from "../../Hooks/useFirstResponderService";
import { registerFirstResponder } from "../../Services/firstResponderService";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const EditAdminUserDialog: FC<Props> = ({ open, handleClose }) => {
  const { user: authUser } = useAuthUserData();
  const { firstResponders, loading } = useFirstRespondersForAgency();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    if (
      !firstName ||
      !lastName ||
      !email ||
      !city ||
      !state ||
      !zipCode ||
      !address
    ) {
      setError("Missing Required Fields");
      return;
    }

    if (loading) {
      setError("Loading.. Please try again");
      return;
    }

    if (firstResponders?.some((fr) => fr.email === email)) {
      setError("User with this email already exists");
      return;
    }

    setIsLoading(true);
    console.log("🍉 registerFirstResponder");
    registerFirstResponder({
      firstName,
      lastName,
      email,
      city,
      state,
      zipCode,
      address,
      rol: "firstResponderAdmin",
      status: "invited",
      username: email,
      mfa: false,
      agency: authUser.agency,
    })
      .then(() => {
        setIsLoading(false);
        setSuccess(true);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
        setError("Email format invalid or already used by another user");
      });
  };

  const cleanForm = () => {
    setFirstName("");
    setLastName("");
    setCity("");
    setState("");
    setZipCode("");
    setAddress("");
    setEmail("");
    setError(null);
    setIsLoading(false);
    setSuccess(false);
  };

  const handleZipChange = (value: string) => {
    if (value.length > 0) {
      setZipCode(new RegExp(/\d+/g).test(value) ? value : zipCode);
    } else {
      setZipCode("");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        cleanForm();
      }}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          bgcolor: "white",
          color: "black",
        },
      }}
    >
      <Grid container direction="column" alignItems="center">
        <Grid item container p={2} direction="row-reverse">
          <IconButton
            onClick={() => {
              handleClose();
              cleanForm();
              window.location.reload();
            }}
            sx={{ color: "#000094" }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
        {success ? (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            pt={3}
            px={8}
            pb={8}
          >
            <Grid item color="#86E0A7">
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: 76,
                }}
              />
            </Grid>
            <Grid item>
              <Typography color="#242627" fontSize={25} fontWeight={700}>
                Admin user added and invited successfully
              </Typography>
            </Grid>
            <Grid item mt={0.5} textAlign="center">
              <Typography color="#242627" fontSize={16} fontWeight={400}>
                Invitation email with further instructions has been sent to the
                user
              </Typography>
              <Typography color="#384057" fontSize={16} fontWeight={600}>
                Sent to:{" "}
                <span
                  style={{
                    color: "#000094",
                  }}
                >
                  {email}
                </span>
              </Typography>
            </Grid>
            <Grid item mt={3}>
              <Button
                variant="outlined"
                sx={{
                  color: "#000094",
                  textTransform: "none",
                  border: "1px solid rgba(0, 0, 148, 0.4)",
                }}
                onClick={() => {
                  handleClose();
                  cleanForm();
                  window.location.reload();
                }}
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            direction="column"
            alignItems="left"
            px={8}
            pb={8}
            rowSpacing={3}
          >
            <Grid item>
              <Typography fontSize={25} fontWeight={700}>
                Add New Admin User
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="row"
              columnSpacing={3}
              alignItems="stretch"
            >
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  First Name
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  Last Name
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              columnSpacing={3}
              alignItems="stretch"
            >
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  Address
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  City
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              columnSpacing={3}
              alignItems="stretch"
            >
              <Grid
                item
                container
                direction="row"
                columnSpacing={3}
                alignItems="stretch"
                xs={6}
              >
                <Grid item xs={6}>
                  <Typography fontSize={13} fontWeight={600}>
                    State
                  </Typography>
                  <TextField
                    type="text"
                    variant="outlined"
                    placeholder="Required"
                    fullWidth
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize={13} fontWeight={600}>
                    Zip Code
                  </Typography>
                  <TextField
                    type="text"
                    variant="outlined"
                    placeholder="Required"
                    fullWidth
                    value={zipCode}
                    onChange={(e) => handleZipChange(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  Email
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid item display="inline">
              <LoadingButton
                loading={isLoading}
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: "#000094",
                  textTransform: "none",
                  padding: "8px 20px",
                  mr: 2,
                }}
                onClick={() => handleSubmit()}
              >
                Add and Send Invite
              </LoadingButton>
            </Grid>

            {error && (
              <Grid item>
                <Typography color="red" fontSize={13} fontWeight={600}>
                  {error}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default EditAdminUserDialog;
