import { Subscriber } from "./../Interfaces/subscriber";

import { MEMBERSHIP_API, USERS_API } from "./CONSTANTS";
import customAxios from "./customAxios";

export const getUsers: (qty?: number) => Promise<Subscriber[]> = (qty) => {
  console.log(`userServices > ${getUsers.name} called...`);

  let query = qty ? `?limit=${qty}` : "";

  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      customAxios
        .get<Subscriber[]>(`${USERS_API}${query}`)
        .then((res: any) => {
          console.log(`${getUsers.name} > axios res=`, res);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(`${getUsers.name} > axios err=`, err);
          reject(new Error(`Error in ${getUsers.name} axios!`));
        });
    } catch (error) {
      console.error(`${getUsers.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const checkAdulthood = (
  userId: string
): Promise<{ adult: boolean; message: string }> => {
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      customAxios
        .get(`${USERS_API}checkAdulthood/${userId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(new Error(err));
        });
    } catch (error: any) {
      reject(new Error(error));
    }
  });
};

export const getUser: (idUser: string) => Promise<Subscriber> = (idUser) => {
  console.log(`userServices > ${getUser.name} called...`);
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      customAxios
        .get<Subscriber>(`${USERS_API}${idUser}`)
        .then((res: any) => {
          console.log(`${getUser.name} > axios res=`, res);
          resolve(res.data);
        })
        .catch((err: any) => {
          console.error(`${getUser.name} > axios err=`, err);
          reject(new Error(`Error in ${getUser.name} axios!`));
        });
    } catch (error) {
      console.error(`${getUser.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const getUserIDByAccessCode: (accessCode: string) => Promise<any> = (
  accessCode
) => {
  console.log(`userServices > ${getUserIDByAccessCode.name} called...`);
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      customAxios
        .get<any>(`${USERS_API}getIdByAccessCode/${accessCode}`)
        .then((res: any) => {
          console.log(`${getUserIDByAccessCode.name} > axios res=`, res);
          resolve(res.data.id);
        })
        .catch((err: any) => {
          console.error(`${getUserIDByAccessCode.name} > axios err=`, err);
          reject(new Error(`Error in ${getUserIDByAccessCode.name} axios!`));
        });
    } catch (error) {
      console.error(`${getUserIDByAccessCode.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const forgotPassword = (email: string) => {
  console.log("userServices > createUser called...");
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .put(`${USERS_API}users/forgot-password/${email}`)
        .then((res: any) => {
          console.log(`${forgotPassword.name} axios res=`, res);
          resolve(res.data);
        })
        .catch((err: any) => {
          console.log(`${forgotPassword.name} axios err=`, err);
          reject(new Error(err.message));
        });
    } catch (error) {
      console.error(`${forgotPassword.name} axios err=`, error);
      reject(error);
    }
  });
};

export const signIn = (email: string, password: string) => {
  console.log(`userServices > ${signIn.name} called...`);
  const prms = new URLSearchParams({
    email,
    password,
  });
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .post(`${USERS_API}users/sign-in`, prms, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
        .then((res: any) => {
          console.log(`${signIn.name} axios res=`, res);
          resolve(res.data);
        })
        .catch((err: any) => {
          console.error(`${signIn.name} axios err=`, err);
          reject(err);
        });
    } catch (error) {
      console.error(`${signIn.name} axios err=`, error);
      reject(error);
    }
  });
};

export const confirmSignUp = (email: string, code: string) => {
  console.log(`userServices > ${confirmSignUp.name} called...`);
  const prms = new URLSearchParams({
    email,
    code,
  });
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .post(`${USERS_API}users/confirm-code`, prms, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
        .then((res: any) => {
          console.log(`${confirmSignUp.name} axios res=`, res);
          resolve(res.data);
        })
        .catch((err: any) => {
          console.error(`${confirmSignUp.name} axios err=`, err);
          reject(err);
        });
    } catch (error) {
      console.error(`${confirmSignUp.name} axios err=`, error);
      reject(error);
    }
  });
};

export const updateUser: (
  prms: Subscriber,
  userId: string
) => Promise<Subscriber> = (prms, userId) => {
  console.log("userServices > photo...");
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .put(`${USERS_API}/${userId}`, prms)
        .then((res: any) => {
          console.log(`${updateUser.name} axios res=`, res);
          resolve(res.data);
        })
        .catch((err: any) => {
          console.log(`${updateUser.name} axios err=`, err);
          reject(new Error(err.message));
        });
    } catch (error) {
      console.error(`${updateUser.name} axios err=`, error);
      reject(error);
    }
  });
};

export const addMemberFamily = (
  memberType: string,
  email: string,
  userId: string
) => {
  console.log(`userServices > ${signIn.name} called...`);
  const User = new URLSearchParams({
    memberType,
    email,
  });
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .post(`${USERS_API}${userId}`, User, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
        .then((res: any) => {
          console.log(`${signIn.name} axios res=`, res);
          resolve(res.data);
        })
        .catch((err: any) => {
          console.error(`${signIn.name} axios err=`, err);
          reject(err);
        });
    } catch (error) {
      console.error(`${signIn.name} axios err=`, error);
      reject(error);
    }
  });
};

export const getMemberFamily = (idParentMember: string) => {
  console.log(`userServices > ${getUser.name} called...`);

  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      customAxios
        .get(`${USERS_API}?s={"idParentMember":"${idParentMember}"}`)
        .then((res: any) => {
          console.log(`${getUser.name} > axios res=`, res);
          resolve(res.data);
        })
        .catch((err: any) => {
          console.error(`${getUser.name} > axios err=`, err);
          reject(new Error(`Error in ${getUser.name} axios!`));
        });
    } catch (error) {
      console.error(`${getUser.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const getFamily = (idParentMember: string): Promise<Subscriber[]> => {
  return new Promise((resolve, reject) => {
    try {
      //Route was fixed, on local env path contains // after entity name
      customAxios
        .get<Subscriber[]>(`${USERS_API}getFamily/${idParentMember}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          // console.log(`${MEMBERSHIP_API}/1/portal/getpaymentinfo`)
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getFamilyHolder = (
  idParentMember: string
): Promise<Subscriber[]> => {
  return new Promise((resolve, reject) => {
    try {
      //Route was fixed, on local env path contains // after entity name
      customAxios
        .get<Subscriber[]>(`${USERS_API}getFamilyHolder/${idParentMember}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          // console.log(`${MEMBERSHIP_API}/1/portal/getpaymentinfo`)
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getAccessCode = (userId: any) => {
  //  console.log(`${MEMBERSHIP_API}1/portal/getpaymentinfo`);
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(`${USERS_API}/getAccessCode/${userId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          // console.log(`${MEMBERSHIP_API}/1/portal/getpaymentinfo`)
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getTest = (idUser: string, price: string) => {
  console.log(`userServices > ${getTest.name} called...`);
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      customAxios
        .get(`${MEMBERSHIP_API}${idUser}/price/${price}`)
        .then((res: any) => {
          console.log(`${getTest.name} > axios res=`, res);
          resolve(res.data);
        })
        .catch((err: any) => {
          console.error(`${getTest.name} > axios err=`, err);
          reject(new Error(`Error in ${getTest.name} axios!`));
        });
    } catch (error) {
      console.error(`${getTest.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const getUsersByPromotion: (
  idPromotion: string
) => Promise<Subscriber[]> = (idPromotion) => {
  console.log(`userServices > ${getUser.name} called...`);

  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      customAxios
        .get<Subscriber[]>(`${USERS_API}?s={"idPromotion":"${idPromotion}"}`)
        .then((res: any) => {
          console.log(`${getUser.name} > axios res=`, res);
          resolve(res.data.data);
        })
        .catch((err: any) => {
          console.error(`${getUser.name} > axios err=`, err);
          reject(new Error(`Error in ${getUser.name} axios!`));
        });
    } catch (error) {
      console.error(`${getUser.name} > axios, Err===`, error);
      reject(error);
    }
  });
};

export const getRekognitionSession = async (
  idUser: string
): Promise<string> => {
  try {
    const response = await customAxios.get<string>(
      `${USERS_API}rekognitionSession/${idUser}`
    );
    return response.data;
  } catch (error) {
    console.error(`${getRekognitionSession.name} axios error=`, error);
    throw error;
  }
};

export const searchUsersByImage = async (
  imageBytes: Buffer
): Promise<string> => {
  try {
    const response = await customAxios.post<string>(
      `${USERS_API}searchUsersByImage`,
      { image: imageBytes }
    );
    return response.data;
  } catch (error) {
    console.error(`${searchUsersByImage.name} axios error=`, error);
    throw error;
  }
};
