import { Document } from './../Interfaces/document'
import { DOCUMENTS_API } from './CONSTANTS'
import customAxios from './customAxios'

export const convertBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => reject(error)
    } catch (error) {
      reject(error)
    }
  })
}

export const uploadDocuments = (prms: any, userId: string) => {
  const form = new FormData()
  const { data, ext, option, index, bucketKey, fileName } = prms
  form.append('data', data)
  form.append('ext', ext)
  form.append('option', option)
  form.append('index', index)
  form.append('fileName', fileName)
  form.append('bucketKey', bucketKey)

  return new Promise((resolve, reject) => {
    try {
      customAxios
        .post(`${DOCUMENTS_API}${userId}/upload-file`, prms)
        .then((res) => {
          // console.log(`${confirmSignUp.name} axios res=`, res)
          resolve(res.data)
        })
        .catch((err) => {
          // console.error(`${confirmSignUp.name} axios err=`, err)
          reject(err)
        })
    } catch (error) {
      // console.error(`${confirmSignUp.name} axios err=`, error)
      reject(error)
    }
  })
}

export const getDocuments: (userId: string) => Promise<Document[]> = (
  userId
) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get<Document[]>(`${DOCUMENTS_API}${userId}?limit=100`)
        .then((res: any) => {
          // console.log(`${confirmSignUp.name} axios res=`, res)
          resolve(res.data.data)
        })
        .catch((err) => {
          // console.error(`${confirmSignUp.name} axios err=`, err)
          reject(err)
        })
    } catch (error) {
      // console.error(`${confirmSignUp.name} axios err=`, error)
      reject(error)
    }
  })
}

export const getDocumentByBucketKey = (userId: string, bucketKey: string) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(`${DOCUMENTS_API}${userId}/download-file/${bucketKey}`, {
          responseType: 'arraybuffer'
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.headers['content-type']
          })
          const image = URL.createObjectURL(blob)
          resolve(image)
        })
        .catch((err) => {
          // console.error(`${confirmSignUp.name} axios err=`, err)
          reject(err)
        })
    } catch (error) {
      // console.error(`${confirmSignUp.name} axios err=`, error)
      reject(error)
    }
  })
}

export const updateDocuments = (userId: string, fileId: string, prms: any) => {
  const form = new FormData()
  const { data, ext, option, index } = prms
  form.append('data', data)
  form.append('ext', ext)
  form.append('option', option)
  form.append('index', index)
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .put(`${DOCUMENTS_API}${userId}/${fileId}`, prms)
        .then((res) => {
          // console.log(`${confirmSignUp.name} axios res=`, res)
          resolve(res.data)
        })
        .catch((err) => {
          // console.error(`${confirmSignUp.name} axios err=`, err)
          reject(err)
        })
    } catch (error) {
      // console.error(`${confirmSignUp.name} axios err=`, error)
      reject(error)
    }
  })
}

export const deleteDocuments = (userId: string, fileId: string) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .delete(`${DOCUMENTS_API}${userId}/${fileId}`)
        .then((res) => {
          // console.log(`${confirmSignUp.name} axios res=`, res)
          resolve(res.data)
        })
        .catch((err) => {
          // console.error(`${confirmSignUp.name} axios err=`, err)
          reject(err)
        })
    } catch (error) {
      // console.error(`${confirmSignUp.name} axios err=`, error)
      reject(error)
    }
  })
}
