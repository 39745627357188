import logo from "../../Assets/logo2.png";
import MobileOrGreater from './MediaSize'

export const LoginCompanyComponent = () => {
  return (
    <MobileOrGreater>
      <section className="principalLayout2"
      >
          <div className="logoMyMed">
            <div>
              <div>
                <img src={logo} className="pt-3px" alt="logo" style={{width : '33%'}}/>
              </div>
            </div>
            <p  className="mt-4" style={{ fontSize : '36px', fontWeight: '700', marginTop: '5px', marginBottom: '1px'}}>
                MyMedHistory
            </p>
            <p>
              First Responder Admin
            </p>
          </div>
      </section>
    </MobileOrGreater>
  )
}
export default LoginCompanyComponent
