import { FC, useEffect, useState } from "react";
import FrPersonalInfo from "../../components/FRPersonalInfo/frPersonalInfo";
import useAuthGuard from "../../Hooks/useAuthGuard";
import useAuthUserData from "../../Hooks/useAuthUserData";
import { registerScan, updateScanRegister } from "../../Services/scansService";
import { useGetID, useQuery } from "./../../Hooks/useSuscribersService";
import { useLocation } from "react-router-dom";

const Details = () => {
  useAuthGuard({
    requiredAdmin: false,
  });
  const { user } = useAuthUserData();
  const navState = useLocation();
  const [isScanRegistered, setIsScanRegistered] = useState(false);
  let query = useQuery();
  const { idSuscriber, loadUser } = useGetID(
    query.get("mmh_id")!.toLowerCase().replaceAll("mmh", "")
  );

  console.log({ scanMethod: navState.state?.scanMethod });

  useEffect(() => {
    const putScan = async () => {
      if (idSuscriber && user && !isScanRegistered) {
        if (navState?.state?.registerId)
          updateScanRegister(
            user?.id,
            navState?.state?.registerId,
            "Full Profile",
            navState?.state?.scanMethod ?? "MMHID"
          );
        else
          registerScan(user?.id, {
            idSubscriber: idSuscriber,
            type: "Full Profile",
            method: navState?.state?.scanMethod ?? "MMHID",
          });
        setIsScanRegistered(true);
      }
    };
    putScan();
  }, [idSuscriber, user]);

  if (loadUser) return <div>Loading...</div>;

  return (
    <div>
      {idSuscriber ? (
        <FrPersonalInfo idUser={idSuscriber} />
      ) : (
        <div>No subscriber info</div>
      )}
    </div>
  );
};

export default Details;
