import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
import LoadingButton from "@mui/lab/LoadingButton"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Dialog from "@mui/material/Dialog"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import { FC, useState } from "react"
import useAuthUserData from "../../Hooks/useAuthUserData"
import {
  sendFirstResponderAdminInvitation,
  sendFirstResponderInvitation,
} from "../../Services/emailService"
import { updateStatusFirstResponderbyId } from "../../Services/firstResponderService"

interface Props {
  bulk: boolean
  reInvite: boolean
  users: any[]
  open: boolean
  handleClose: () => void
  admin?: boolean
}

const SendInvitesDialog: FC<Props> = ({
  bulk,
  reInvite,
  users,
  open,
  handleClose,
  admin = false,
}) => {
  const { user: authedUser } = useAuthUserData()
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const notActiveUsers = users.filter(
    (user) => user.status !== "active" && user.status !== "blocked"
  )
  const [newChecked, setNewChecked] = useState(true)
  const [expiredChecked, setExpiredChecked] = useState(true)
  const [invitedChecked, setInvitedChecked] = useState(true)

  const handleInvite = async () => {
    setLoading(true)

    const usersToInvite = notActiveUsers.filter((user) => {
      if (user.status === "new" && newChecked) return true
      if (user.status === "expired" && expiredChecked) return true
      if (user.status === "invited" && invitedChecked) return true
      return false
    })

    const usersSendMailPromises = usersToInvite.map((user) => {
      return admin
        ? sendFirstResponderAdminInvitation({
            adminUsername: authedUser?.username || "adminUsername",
            email: user.email,
          })
        : sendFirstResponderInvitation({
            idCode: user.mmhId,
            password: user.otp,
            adminUsername: authedUser?.username || "adminUsername",
            email: user.email,
          })
    })

    const userChangeStatusPromises = usersToInvite.map((user) =>
      updateStatusFirstResponderbyId(user.id, "invited")
    )

    Promise.allSettled([...usersSendMailPromises, ...userChangeStatusPromises])
      .then((res) => {
        setLoading(false)
        setSuccess(true)
      })
      .catch((err) => {
        setLoading(false)
        setError(err.message)
      })
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setSuccess(false)
        handleClose()
      }}
      fullWidth
      maxWidth="md"
    >
      <Grid container direction="column" alignItems="center">
        <Grid item container p={2} direction="row-reverse">
          <IconButton
            onClick={() => {
              handleClose()
              setSuccess(false)
            }}
            sx={{ color: "#000094" }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
        {success ? (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            pt={3}
            px={8}
            pb={8}
          >
            <Grid item color="#86E0A7">
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: 76,
                }}
              />
            </Grid>
            <Grid item>
              <Typography color="#242627" fontSize={25} fontWeight={700}>
                Invitation{notActiveUsers.length === 1 ? "" : "s"} sent
                succesfully
              </Typography>
            </Grid>
            <Grid item mt={0.5}>
              <Typography color="#242627" fontSize={16} fontWeight={400}>
                Invitation email with login credentials has been sent/re-sent to{" "}
                {notActiveUsers.length === 1
                  ? "the selected user"
                  : `all selected users`}
              </Typography>
            </Grid>
            <Grid item mt={3}>
              <Button
                variant="outlined"
                sx={{
                  color: "#000094",
                  textTransform: "none",
                  border: "1px solid rgba(0, 0, 148, 0.4)",
                }}
                onClick={() => {
                  handleClose()
                  setSuccess(false)
                }}
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            pt={3}
            px={8}
            pb={8}
          >
            <Grid item color="#000094">
              <ForwardToInboxIcon
                sx={{
                  fontSize: 76,
                }}
              />
            </Grid>
            <Grid item>
              <Typography color="#242627" fontSize={25} fontWeight={700}>
                {reInvite ? "" : "Send"}{" "}
                {bulk ? "Bulk" : reInvite ? "Re-Send" : ""} Invite
                {notActiveUsers.length === 1 ? "" : "s"}
              </Typography>
            </Grid>
            <Grid item mt={0.5}>
              <Typography color="#242627" fontSize={16} fontWeight={400}>
                Invitation email with login credentials will be{" "}
                {reInvite ? "re" : ""}send
                {bulk ? "/re-send" : ""} to{" "}
                {users.length === 1
                  ? "the selected user"
                  : `all selected ${bulk ? "group of" : ""} users`}
              </Typography>
            </Grid>

            {bulk ? (
              <>
                <Grid item mt={3}>
                  <Typography color="#384057" fontSize={16} fontWeight={600}>
                    Please select groups to send invite
                  </Typography>
                </Grid>
                <Grid item mt={2}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newChecked}
                          onChange={(event) =>
                            setNewChecked(event.target.checked)
                          }
                        />
                      }
                      label={`New Users (${
                        users.filter((user) => user.status === "new").length
                      })`}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={expiredChecked}
                          onChange={(event) =>
                            setExpiredChecked(event.target.checked)
                          }
                        />
                      }
                      label={`Expired Users (${
                        users.filter((user) => user.status === "expired").length
                      })`}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={invitedChecked}
                          onChange={(event) =>
                            setInvitedChecked(event.target.checked)
                          }
                        />
                      }
                      label={`Invited Users (${
                        users.filter((user) => user.status === "invited").length
                      })`}
                    />
                  </FormGroup>
                </Grid>
              </>
            ) : (
              <>
                {users.some((user) => user.status === "active") && (
                  <Grid item mt={0.5}>
                    <div
                      style={{
                        color: "#696444",
                        backgroundColor: "#FFF7CA",
                        padding: "8px 12px",
                        borderRadius: "4px",
                      }}
                    >
                      Some of selected users are already &quot;Active&quot;,
                      they won&rsquo;t be invited
                    </div>
                  </Grid>
                )}
                <Grid item mt={0.5}>
                  <Typography color="#242627" fontSize={16} fontWeight={600}>
                    Users to invite:{" "}
                    <span
                      style={{
                        color: "#000094",
                      }}
                    >
                      {notActiveUsers.length === 1
                        ? `${users[0].firstName} ${users[0].lastName}`
                        : `${notActiveUsers.length}`}
                    </span>
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item mt={3}>
              <LoadingButton
                loading={loading}
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: "#000094",
                  textTransform: "none",
                  padding: "8px 20px",
                }}
                onClick={handleInvite}
              >
                {bulk ? "Send Invites" : "Confirm"}
              </LoadingButton>
            </Grid>
            {error && (
              <Grid item mt={3}>
                <Typography color="#FF0000" fontSize={16} fontWeight={600}>
                  {error}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Dialog>
  )
}

export default SendInvitesDialog
