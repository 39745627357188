import {
  CssBaseline,
  createTheme,
  ThemeProvider,
  styled as muStyled,
} from "@mui/material";
import Container from "@mui/material/Container";
import React, { FC, ReactElement } from "react";
import AlertMessageQRScan from "../components/AlertMessage/AlertMessageQRScan";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import useAuthGuard from "../Hooks/useAuthGuard";
import { LayoutCenterContainer } from "../Layout/layoutCenter.container";
import FaceScanner from "../components/FaceScanner/FaceScanner";
import { ThemeBackgroundDark } from "../Styles/theme";

export const ScanFace: FC<any> = (): ReactElement => {
  const theme = createTheme(ThemeBackgroundDark);
  useAuthGuard({
    requiredAdmin: false,
  });

  const [isLoadingData, setLoadingData] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [adviceError, setAdviceError] = React.useState(
    "biometricErrorNotFound"
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!isLoadingData ? (
        <>
          <FaceScanner
            setLoadingData={setLoadingData}
            setAdviceError={setAdviceError}
            setError={setError}
          />
          <AlertMessageQRScan open={error} advice={adviceError} />
        </>
      ) : (
        <LayoutCenterContainer
          children={
            <Container>
              <LoadingIndicator titleBefore="Processing..." />
            </Container>
          }
        ></LayoutCenterContainer>
      )}
    </ThemeProvider>
  );
};
export default ScanFace;
