import { FC, ReactElement } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Logo } from "../../components/Logo/Logo";
import logo2 from "../../Assets/logo2.png";

import { Grid, Typography } from "@mui/material";

import "./layout-sign.css";

interface LayaoutProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export const LayoutSign: FC<LayaoutProps> = (props): ReactElement => {
  const theme = useTheme();

  return (
    <div className="sign-container-component">
      <Grid className="sign-container2">
        <Grid>
          <Typography
            fontSize={29}
            fontWeight={400}
            sx={{ paddingTop: 3 }}>
            Welcome to
          </Typography>
          <Typography fontSize={29} fontWeight={600} sx={{ paddingBottom: 4 }}>
            MyMedHistory App
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <img src={logo2} alt="logo" className="logo-mymed" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className="sign-container-form-login">{props.children}</div>
        </Grid>      
      </Grid>
    </div>
  );
};
