import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined"
import DeleteUsersDialog from "../../DeleteUsersDialog"
import SendInvitesDialog from "../../SendInvitesDialog"
import Button from "@mui/material/Button"
import { FC, useState } from "react"
// import { ActionProps, ActionProps2 } from "../utils"
import { ActionProps } from "../utils"

const Expired: FC<ActionProps> = ({ users, isSelection }) => {
  const [openDialog, setOpenDialog] = useState<
    "bulkInvite" | "invite" | "delete" | "reInvite" | null
  >(null)
  
  return (
    <>
      <Button
        variant="outlined"
        sx={{
          color: "#000094",
          textTransform: "none",
          border: "1px solid rgba(0, 0, 148, 0.4)",
        }}
        onClick={() => setOpenDialog("reInvite")}
        startIcon={<ForwardToInboxIcon />}
      >
        {isSelection ?
        'Re-Invite selected (' + users.length + ')':'Re-Invite All Listed (' + users.length + ')'}
      </Button>
      <Button
        variant="outlined"
        sx={{
          marginLeft: "14px",
          color: "#000094",
          textTransform: "none",
          border: "1px solid rgba(0, 0, 148, 0.4)",
        }}
        onClick={() => setOpenDialog("delete")}
        startIcon={<DeleteOutlineIcon />}
      >{isSelection ?
        'Delete Selected (' + users.length + ')':'Delete All Listed (' + users.length + ')'}
      </Button>
      <SendInvitesDialog
        open={openDialog !== null && openDialog !== "delete"}
        users={users}
        handleClose={() => setOpenDialog(null)}
        bulk={openDialog === "bulkInvite"}
        reInvite={openDialog === "reInvite"}
      />
      <DeleteUsersDialog
        open={openDialog === "delete"}
        users={users}
        handleClose={() => setOpenDialog(null)}
      />
    </>
  )
}

export default Expired
