import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { LayoutSign } from "../Layout/layout-sign/layout-sign";
// import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { CustomButton } from "../components/CustomButton/CustomButton";
import ForgotPasswordPopup from "../components/ForgotPasswordPopup/ForgotPasswordPopup";
import useLocalStorage from "../Hooks/useLocalStorage";

export default function ForgotPassword() {
  const navigate = useNavigate();
  // const { isLoading, forgotPassword, forgotPasswordSubmit } = useCognitoAwsV2();
  const [codeVerified, setCodeVerified] = React.useState<boolean>(true);
  const [email, setEmail] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [newPasswordError, setNewPasswordError] = React.useState<string>("");
  const [verifyCode, setVerifyCode] = React.useState<string>("");
  const [verifyCodeError, setVerifyCodeError] = React.useState<string>("");
  const [succeedAlert, setSucceedAlert] = React.useState<boolean>(false);
  const [errorAlert, setErrorAlert] = React.useState<boolean>(false);
  const [errorAlertMsg, setErrorAlertMsg] = React.useState<string>("");
  const [showPasswordNew, setShowPasswordNew] = React.useState<boolean>(false);

  const { URLparams } = useParams();

  const [emailSent, setEmailSent] = useLocalStorage(
    "SeedClassroomEmailSent",
    ""
  );

  interface IFormInputs {
    email: string;
    verifyCode: string;
    newPassword: string;
  }

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email format")
      .required("This field is required"),
    verifyCode: yup.string().required("This field is required"),
    newPassword: yup
      .string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/,
        "The password must be 8 digits, some capital letters and a special character"
      )
      .required("This field is required"),
  });

  const [initialValues, setInitialValues] = React.useState<IFormInputs>({
    email: "",
    verifyCode: "",
    newPassword: "",
  });

  // const {
  //   handleSubmit,
  //   reset,
  //   formState: { errors },
  //   control,
  //   setValue,
  // } = useForm<any>({
  //   // resolver: yupResolver(schema),
  // });

  useEffect(() => {
    if (URLparams) {
      const params = new URLSearchParams(URLparams);

      const verifyCode = params.get("verifyCode") || "";
      const email = params.get("email") || "";

      const formNewData = {
        verifyCode,
        email,
        newPassword: "",
      };

      setInitialValues(formNewData);
      // reset(formNewData);

      setVerifyCode(verifyCode);
      setEmail(email);
    }
  }, [URLparams]);

  // const formSubmitHandler: SubmitHandler<any> = async (data: IFormInputs) => {
  //   try {
  //     const { email, newPassword, verifyCode } = data;

  //     if (codeVerified) {
  //       await forgotPasswordSubmit(email, verifyCode, newPassword);
  //       setSucceedAlert(true);
  //       setTimeout(() => {
  //         setEmailSent("");
  //         navigate("/");
  //       }, 2000);
  //     } else {
  //       await forgotPassword(email);
  //       setCodeVerified(true);
  //       setEmailSent(email);
  //     }
  //     setErrorAlert(false);
  //   }
  //   catch (err: any) {
  //     console.log(err);
  //     setErrorAlertMsg(err?.message);
  //     setErrorAlert(true);
  //   }
  // };

  function readyToRequestVerificationCode(): boolean {
    return !(!email || !!emailError);
  }

  function readyToResetPassword(): boolean {
    return !(
      !newPassword ||
      !verifyCode ||
      !!newPasswordError ||
      !!verifyCodeError
    );
  }

  return (
    <>
      <LayoutSign
        children={
          <Container>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Forgot Password
              </Typography>

              <Box
                component="form"
                noValidate
                // onSubmit={handleSubmit(formSubmitHandler)}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Lost your password? Please enter your username or email
                      address. You will receive a confirmation code to create a
                      new password via email.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Controller
          name="email"
          control={control}
          defaultValue={initialValues.email}
          render={({ field }) => (
            <TextField
              {...field}
              id="email"
              type="email"
              label="Email"
              variant="standard"
              disabled={false}
              error={!!errors.email}
              helperText={errors.email ? errors.email?.message : ""}
              fullWidth
            />
          )}
        /> */}
                  </Grid>
                  {codeVerified && (
                    <>
                      <Grid item xs={12}>
                        {/* <Controller
                  name="verifyCode"
                  control={control}
                  defaultValue={initialValues.verifyCode}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="verifyCode"
                      type="text"
                      label="Verification Code"
                      variant="standard"
                      disabled={false}
                      error={!!errors.verifyCode}
                      helperText={
                        errors.verifyCode
                          ? errors.verifyCode?.message
                          : ""
                      }
                      fullWidth
                    />
                  )}
                /> */}
                      </Grid>
                      <Grid item xs={12}>
                        {/* <Controller
                  name="newPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field}
                      id="pass"
                      type={showPasswordNew ? "text" : "password"}
                      label="New Password"
                      variant="standard"
                      error={!!errors.newPassword}
                      helperText={errors.newPassword ? errors.newPassword?.message : ""}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => setShowPasswordNew(!showPasswordNew)}
                            style={{ cursor: "pointer" }}
                          >
                            {showPasswordNew ? (
                              <VisibilityOffOutlined color="disabled" />
                            ) : (
                              <VisibilityOutlined color="disabled" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /> */}
                      </Grid>
                    </>
                  )}
                </Grid>

                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>

                {!codeVerified ? (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      width: 200,
                      marginTop: 40,
                      textTransform: "unset",
                    }}
                    sx={{ mt: 3, mb: 2 }}
                    disabled={!readyToRequestVerificationCode()}
                  >
                    {"Request code"}
                  </Button>
                ) : (
                  <CustomButton
                    text="Reset Password"
                    // type="submit"
                    sx={{ mt: 5, mb: 1 }}
                    onClick={() => setOpenDialog(true)}
                  />
                )}
                <Stack sx={{ width: "100%" }} spacing={2}>
                  {errorAlert && (
                    <Alert severity="error">{errorAlertMsg}</Alert>
                  )}
                  {succeedAlert && (
                    <Alert severity="success">
                      Password reset successfully!
                    </Alert>
                  )}
                </Stack>
              </Box>
            </Box>
          </Container>
        }
      ></LayoutSign>
      <ForgotPasswordPopup
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
      />
    </>
  );
}
