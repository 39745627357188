import Grid from "@mui/material/Grid";
import { FC, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import DashboardLayout from "../../components/Common/DashboardLayout";
import ManageUsersActions from "../../components/ManageUsersActions";
import ManageUsersFilter from "../../components/ManageUsersFilter";
import ManageUsersTable from "../../components/ManageUsersTable";
import useAuthGuard from "../../Hooks/useAuthGuard";
import { useFirstRespondersForAgency } from "../../Hooks/useFirstResponderService";
import { FirstResponderData } from "../../Interfaces/FirstResponder";

export const STATUSES = {
  all: {
    label: "All Users",
    color: "#000094",
  },
  new: {
    label: "New Users",
    color: "#5891FF",
  },
  expired: {
    label: "Expired Users",
    color: "#FF8642",
  },
  invited: {
    label: "Invited Users",
    color: "#7B61FF",
  },
  active: {
    label: "Active Users",
    color: "#01BB4B",
  },
  unverified: {
    label: "Unverified Users",
    color: "#01BB4B"
  },
  blocked: {
    label: "Blocked Users",
    color: "#FC6868",
  },
  owner: {
    label: "Blocked Users",
    color: "#FC6868",
  },
};

export type StatusesType = keyof typeof STATUSES;

const ManageUsers: FC = () => {
  useAuthGuard({
    requiredAdmin: true,
  });
  const location = useLocation();
  const { firstResponders, loading } = useFirstRespondersForAgency();
  const [status, setStatus] = useState<StatusesType>("all");
  const [searchInput, setSearchInput] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<any[] | null>(null);
  const [filteredUsers, setFilteredUsers] = useState<any[]>(
    firstResponders || []
  );
  const [search, setSearch] = useSearchParams();
  const [modalMessage, setModalMessage] = useState("");
  const [advice, setAdvice] = useState("");

  useEffect(() => {
    setAdvice(search.get("advice") || "");
    setModalMessage(search.get("message") || "");
  }, [search]);

  useEffect(() => {
    // Update filtered users based on the route condition
    const isManageUsersRoute = location.pathname.endsWith("manageUsers");
    const _filteredUsers = firstResponders.filter(
      (user: FirstResponderData) => {
        const isRoleMatched = isManageUsersRoute
          ? user.rol === "firstResponder"
          : true;
        const isStatusMatched =
          status === "all" ? true : user.status === status;
        const searchableAttributes = [
          "firstName",
          "lastName",
          "email",
          "badge",
          "mmhId",
          "city",
          "state",
          "zipCode",
        ] as const;
        const isSearchInputMatched = searchableAttributes.reduce(
          (acc, attr) => {
            return (
              acc ||
              String(user[attr as (typeof searchableAttributes)[number]])
                ?.toLowerCase()
                .includes(searchInput.toLowerCase())
            );
          },
          false
        );

        return isRoleMatched && isStatusMatched && isSearchInputMatched;
      }
    );

    setFilteredUsers(_filteredUsers);
  }, [searchInput, status, loading, firstResponders, location]);

  return (
    <DashboardLayout page="manageUsers">
      {advice && advice != "" ? (
        <AlertMessage advice={advice} message={modalMessage} />
      ) : (
        ""
      )}
      <Grid container direction="column" rowSpacing={3} alignItems="stretch">
        {/* Actions and filter */}
        <Grid item container direction="row" justifyContent="space-between">
          <Grid item>
            <ManageUsersActions
              status={status}
              users={selectedUsers ?? filteredUsers}
              isSelection={selectedUsers !== null}
            />
          </Grid>
          <Grid item>
            <ManageUsersFilter
              status={status}
              setStatus={setStatus}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
            />
          </Grid>
        </Grid>
        {/* Table */}
        <Grid item>
          <ManageUsersTable
            users={filteredUsers.filter((fr) => fr.rol === "firstResponder")}
            setSelectedUsers={setSelectedUsers}
            isloading={!!loading}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ManageUsers;
