import CloseIcon from "@mui/icons-material/Close"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import Alert from "@mui/material/Alert"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import { FC, useEffect, useState } from "react"

interface Props {
  advice: string
}

const AlertMessageErrorUpload: FC<Props> = ({ advice }) => {
  const [open, setOpen] = useState(true)
  const [tittle, setTittle] = useState("")
  const [message, setMessage] = useState("")

  useEffect(() => {
    switch (advice) {
      case "invalideFile":
        setTittle("Invalide File")
        setMessage(
          "The file you’re trying to upload doesn’t meet the requirements"
        )
        break
      case "missingFile":
        setTittle("No File uploaded")
        setMessage("Please upload the file with users data to continue")
        break
      case "missingRequiredMapping":
        setTittle("Not all data is mapped")
        setMessage(
          "Please match all user properties with the data from your file"
        )
        break
    }
  }, [])

  return (
    <Collapse in={open}>
      <Alert
        icon={<HighlightOffIcon fontSize="inherit" />}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false)
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        variant="filled"
        severity="error"
        style={{
          background: "#FC6868E5",
          margin: "auto",
          color: "#FFF",
        }}
      >
        <Typography color="#fff" fontSize={14} fontWeight={700}>
          {tittle}
        </Typography>
        <Typography color="#fff" fontSize={14}>
          {message}
        </Typography>
      </Alert>
    </Collapse>
  )
}

export default AlertMessageErrorUpload
