import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
import Button from "@mui/material/Button"
import { FC, useState } from "react"
import DeleteUsersDialog from "../../DeleteUsersDialog"
import SendInvitesDialog from "../../SendInvitesDialog"
import { ActionProps } from "../utils"

const All: FC<ActionProps> = ({ users, isSelection }) => {
  const [openDialog, setOpenDialog] = useState<
    "bulkInvite" | "invite" | "delete" | "reInvite" | null
  >(null)

  return (
    <>
      {!isSelection ? (
        <Button
          variant="outlined"
          sx={{
            color: "#000094",
            textTransform: "none",
            border: "1px solid rgba(0, 0, 148, 0.4)",
          }}
          startIcon={<ForwardToInboxIcon />}
          onClick={() => setOpenDialog("bulkInvite")}
        >
          Send Bulk Invite {!isSelection ? "" : `(${users.length})`}
        </Button>
      ) : (
        <>
          <Button
            variant="outlined"
            sx={{
              color: "#000094",
              textTransform: "none",
              border: "1px solid rgba(0, 0, 148, 0.4)",
              mr: 2,
            }}
            startIcon={<ForwardToInboxIcon />}
            onClick={() => setOpenDialog("invite")}
          >
            Invite Selected ({users.length})
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#000094",
              textTransform: "none",
              border: "1px solid rgba(0, 0, 148, 0.4)",
            }}
            startIcon={<DeleteOutlineOutlinedIcon />}
            onClick={() => setOpenDialog("delete")}
          >
            Delete Selected ({users.length})
          </Button>
        </>
      )}
      <SendInvitesDialog
        open={openDialog !== null && openDialog !== "delete"}
        users={users}
        handleClose={() => setOpenDialog(null)}
        bulk={openDialog === "bulkInvite"}
        reInvite={openDialog === "reInvite"}
      />
      <DeleteUsersDialog
        open={openDialog === "delete"}
        users={users}
        handleClose={() => setOpenDialog(null)}
      />
    </>
  )
}

export default All
