import GridLoader from "react-spinners/ClipLoader";

export const corporateColors: any = {
  seedColor: "#FFFFFF",
};

export const LoadingIndicator = ({
  state = true,
  titleBefore = "",
  details = null,
}) => {
  const styleLoading: any = {
    position: "fixed",
    color: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <div style={styleLoading}>
      <h3>{titleBefore}</h3>
      {details && <p>{details}</p>}
      <GridLoader size={30} color={corporateColors.seedColor} loading={state} />
    </div>
  );
};
