import { useMediaQuery } from 'react-responsive'

type Props = {
  children: any,
};

const Desktop = ({ children } : Props) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}

const MobileOrGreater = ({ children }: Props) => {
  const isMobile = useMediaQuery({ minWidth: 640 })
  return isMobile ? children : null
}
export default MobileOrGreater
export { Desktop }
