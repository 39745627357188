import styles from './helpVideos.module.css'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Pagination from '@mui/material/Pagination';
import { AiOutlineSearch } from 'react-icons/ai'
import VideoCard from '../../components/videoCard/videoCard'
import { useEffect, useState } from 'react'
import usePagination from "./Pagination";
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from "@mui/material/styles";
import HamburguerMenu from "../../components/HamburguerMenu/Hamburguer";
import data from './MOCK_DATA.json';

const HelpVideos = () => {
  const LABELS = {
    MY_MED: 'MyMedHistory',
    LOGOUT: 'Log out',
    HEADER_TITLE: 'Your Account',
    HEADER_MESSAGE:
      'Welcome to your MyMedHistory account. You will find all of the information needed to update and manage your account.',
  }
  const matches = useMediaQuery('(max-width: 760px)')
  const [filteredVideos, setFilteredVideos] = useState(data)
  const [videos, setVideos] = useState(data)
  const [searchInput, setSearchInput] = useState('')
  const [categoryInput, setCategoryInput] = useState('default')
  const [orderInput, setOrderInput] = useState('default')
  const [numberPages, setNumberPages] = useState(0)
  let [page, setPage] = useState(1);
  const PER_PAGE = (matches) ? 2-numberPages : 4-numberPages;
  const count = Math.ceil(filteredVideos.length/ PER_PAGE);
  const _DATA = usePagination(filteredVideos, PER_PAGE);
  
  console.log(count)
  const handleChange = (e : any, p : any) => {
    setPage(p);
    _DATA.jump(p);
  };

  const CustomPagination = styled(Pagination)(({ theme }) => ({
    '& .MuiPagination-ul': {
      padding: "22px"
    },
    '& .MuiButtonBase-root': {
      color: "#007BFF"
    },
    
  }));
  useEffect(() => {
    const newVideos = videos
      .filter(
        (v) =>
          v.title.toLowerCase().includes(searchInput.toLowerCase()) ||
          v.description.toLowerCase().includes(searchInput.toLowerCase())
      )
      .filter(
        (v) =>
          categoryInput === 'default' ||
          v.category.toLowerCase() === categoryInput.toLowerCase()
      )

    if (orderInput !== 'default') {
      newVideos.sort((v1, v2) => {
        if (orderInput === 'asc')
          return v1.duration < v2.duration
            ? -1
            : v1.duration > v2.duration
            ? 1
            : 0
        return v1.duration > v2.duration
          ? -1
          : v1.duration < v2.duration
          ? 1
          : 0
      })
    }
    handleChange(1,1)
    setFilteredVideos(newVideos)
  }, [searchInput, categoryInput, orderInput])

  return (
    
    <div className={styles.helpVideos}> 
    <div style={{border: "none"}}>
      <HamburguerMenu />
    </div>
      <div className={styles.helpVideosCard}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs>
            <div className={styles.helpVideosCardTitleLabel}>TRAINING VIDEOS</div>
          </Grid>
          <Grid
            item
            xs
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={styles.helpVideosCardTitleSearch}
          >
            <Grid 
            item 
            xs
            container
            direction="column"
            columnSpacing={0.2}
            justifyContent="flex-end">
              <TextField
                variant="standard"
                placeholder='Search'
                style={{width : "236px"}}
                onChange={(e) => setSearchInput(e.target.value)}
                InputProps={{
                  endAdornment: <AiOutlineSearch size="30px" color="#858C94" style={{marginLeft: "184px",position: "absolute"}}/>,
                  disableUnderline: true,
                  sx: {
                    bgcolor: '#f4f6f9',
                    borderRadius: '8px',
                    border: '1px',
                    width: '25%',
                    marginLeft: '18px',
                    marginBottom: '16px'
                  },
                }}
                inputProps={{
                  className: styles.helpVideosCardTitleSearchInput,
                }}               
              />
            </Grid>
            <div className={styles.testClass}>
            <Grid
              item
              xs
              container
              direction="row"
              columnSpacing={0.2}
              justifyContent="flex-end"
              display="flex"
              flexDirection="row-reverse"
              className='testClass'
            >
              <Grid item>
              <div className={styles.helpVideosCardTitleSearchSelect}>
                  <select
                    defaultValue="default"
                    onChange={(e) => setOrderInput(e.target.value)}
                  >
                    <option value="default">Order per duration</option>
                    <option value="asc">Ascendent</option>
                    <option value="desc">Descendent</option>
                  </select>
                </div>
              </Grid>
              <Grid item>
              <div className={styles.helpVideosCardTitleSearchSelect}>
                  <select
                    defaultValue="default"
                    onChange={(e) => setCategoryInput(e.target.value)}
                  >
                    <option value="default">Filter by category</option>                   
                    {Array.from(new Set(videos.map((v) => v.category))).map((c,index) => {
                      return (
                        <option value={c} key={c}>
                          {c.toUpperCase()}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </Grid>
            </Grid>
            </div>
          </Grid>
          <Grid
            display={(matches) ? "contents" : "flex"}
            item
            container
            direction="row"
            spacing={2}
            justifyContent="flex-start" 
            alignItems="flex-start"
            sx={{ mt: 4 }}
          >
          {_DATA.currentData().map((v : any) => {
            //console.log(_DATA.currentData().length)
              return (
                <Grid item maxWidth={(matches) ? "100% !important" : ""} xs={6} key={v.id}>
                  <VideoCard video={v} />
                </Grid>
              )
            })}
          </Grid>
          <Grid alignSelf="end">
            <CustomPagination
              count={count}
              size="small"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
            </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default HelpVideos
