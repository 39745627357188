import { FC, ReactElement } from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  styled as muStyled,
} from "@mui/material";
import Typography from "@mui/material/Typography";

//Icons
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import ChangePasswordDialog from "../../components/ChangePasswordPopup";
import SuccessfulPassChangeDialog from "../../components/SuccessfulPasswordChange";
import {
  DialogSetting,
  useStepVerificationContext,
} from "../../contexts/StepVerificationContext";
import EditProfileDialog from "../EditProfileDialog";
import StepVerificationDialog from "../StepVerificationDialog/StepVerificationDialog";

interface StyledButtonProps extends ButtonProps {
  active?: boolean | string;
}
const StyledButton = muStyled(Button, {
  shouldForwardProp: (prop) => prop !== "badge",
})<StyledButtonProps>(({ active = false }) => ({
  // width: '100%',
  marginLeft: "64px",
  height: 40,
  textTransform: "none",
  fontSize: "1rem",
  color: active ? "#000094" : "#FFF",
  borderRadius: "8px",
  backgroundColor: active ? "#FFF" : "#000094",
  boxShadow: "none",
  border: "1px solid #000094",
  ...(active && {
    "&:hover": {
      backgroundColor: "#000094",
      color: "#FFF",
    },
  }),
}));

const ContainerCard = muStyled(Box)<BoxProps>(() => ({
  width: "100%",
  height: 170,
  borderRadius: "8px",
  backgroundColor: "white",
  position: "relative",
  marginBottom: "16px",
}));

export const CustomCard: FC<CustomCardProps> = ({ ...props }): ReactElement => {
  const { title, subtitle, badge, icon, buttonText, nameDialog, onSubmit } =
    props;
  const { dialogToOpen, setDialogToOpen, isActive } =
    useStepVerificationContext();

  const getCustomIcon = () => {
    switch (icon) {
      case "AccountCircle":
        return <AccountCircleOutlinedIcon style={{ color: "#000094" }} />;
      case "Lock":
        return <LockOutlinedIcon style={{ color: "#000094" }} />;
      case "AdminPanelSettings":
      default:
        return <AdminPanelSettingsOutlinedIcon style={{ color: "#000094" }} />;
    }
  };

  return (
    <>
      <ContainerCard>
        <div
          style={{
            position: "relative",
            left: "34px",
            top: "32px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginRight: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {getCustomIcon()}
            </div>
            <div>
              <Typography color="#000000" fontSize={19} fontWeight={600}>
                {title}
              </Typography>
            </div>
            {badge ? (
              isActive ? (
                <div
                  style={{
                    marginLeft: "8px",
                    borderRadius: "6px",
                    background: "rgba(1, 187, 75, 0.12)",
                  }}
                >
                  <div style={{ padding: "4px 8px" }}>
                    <Typography color="#01BB4B" fontSize={14} fontWeight={400}>
                      Activated
                    </Typography>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    marginLeft: "8px",
                    borderRadius: "6px",
                    background: "rgba(252, 104, 104, 0.12)",
                  }}
                >
                  <div
                    style={{
                      padding: "4px 8px",
                      background: "transparent",
                      opacity: 1,
                    }}
                  >
                    <Typography color="#FC6868" fontSize={14} fontWeight={400}>
                      Not-Active
                    </Typography>
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
        <div
          style={{
            position: "relative",
            left: "64px",
            top: "32px",
          }}
        >
          <Typography color="#6A7288" fontSize={14} fontWeight={500}>
            {subtitle}
          </Typography>
        </div>
        <div
          style={{
            position: "relative",
            top: "32px",
          }}
        >
          <StyledButton
            active={
              isActive && nameDialog === "step-verification"
                ? isActive
                : undefined
            }
            onClick={() => {
              if (onSubmit && isActive) {
                onSubmit();
              }
              setDialogToOpen(nameDialog);
            }}
            // onClick={() => console.log(nameDialog)}
            variant="contained"
            sx={{ mt: 2, mb: 1 }}
            disabled={false}
          >
            {badge ? (isActive ? "Deactivate" : "Activate") : buttonText}
          </StyledButton>
        </div>
      </ContainerCard>

      <StepVerificationDialog open={dialogToOpen === "step-verification"} />
      <ChangePasswordDialog open={dialogToOpen === "change-password"} />
      <SuccessfulPassChangeDialog
        open={dialogToOpen === "successful-change-password"}
      />
      <EditProfileDialog open={dialogToOpen === "edit-profile"} />
    </>
  );
};

interface CustomCardProps {
  title: string;
  icon: "AccountCircle" | "Lock" | "AdminPanelSettings";
  nameDialog: DialogSetting;
  subtitle?: string;
  buttonText?: string;
  badge?: boolean;
  onSubmit?: () => void;
}
