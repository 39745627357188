import { FC, useState } from "react"

import { styled } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
import Stack from '@mui/material/Stack';

import { ActionProps } from "../utils";
import { validateBulkEmpty } from "../../../Helpers/FormValidations";
import SendInvitesDialog from "../../SendInvitesDialog";
import DeleteUsersDialog from "../../DeleteUsersDialog";

const ActionButton = styled(Button)<ButtonProps>(() => ({
  color: '#000094',
  textTransform: 'none',
  border: '1px solid rgba(0, 0, 148, 0.4)',
  textAlign: 'center',
  lineHeight: '5px',
}));

const Invited: FC<ActionProps> = ({ users, isSelection }) => {
  const [openDialog, setOpenDialog] = useState<
    "bulkInvite" | "invite" | "delete" | null
  >(null)

  return (
    <Stack spacing={1} direction="row">
      <ActionButton
        variant="outlined"
        startIcon={<ForwardToInboxIcon />}
        onClick={() => setOpenDialog("invite")}
      >
        Re-Invite All Listed {validateBulkEmpty(users, isSelection)}
      </ActionButton>

      <ActionButton
        variant="outlined"
        startIcon={<DeleteOutlineIcon />}
        onClick={() => setOpenDialog("delete")}
      >
        Delete All Listed {validateBulkEmpty(users, isSelection)}
      </ActionButton>

      <SendInvitesDialog
        reInvite={false}
        open={openDialog !== null && openDialog !== "delete"}
        users={users}
        handleClose={() => setOpenDialog(null)}
        bulk={openDialog === "bulkInvite"}
      />
      <DeleteUsersDialog
        open={openDialog === "delete"}
        users={users}
        handleClose={() => setOpenDialog(null)}
      />
    </Stack>
  )
}

export default Invited;