import { useState, useEffect } from "react";
import { FC, ReactElement } from "react";
import {
  Alert,
  Dialog,
  Grid,
  IconButton,
  Link,
  Snackbar,
  styled as muStyled,
  Typography,
} from "@mui/material";
import ReactInputVerificationCode from "react-input-verification-code";
import VerificationCodeIcon from "../../Assets/verificationcode.svg";
import "./VerificationCode.css";
import { CustomButton } from "../CustomButton/CustomButton";
import { LayoutSign } from "../../Layout/layout-sign/layout-sign";
import { FirstResponderData } from "../../Interfaces/FirstResponder";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useNavigate } from "react-router-dom";

interface Props {
  user?: FirstResponderData;
  open: boolean;
  handleClose: () => void;
  handleCode: () => void;
}
export const VerificationCode: FC<Props> = ({
  user,
  open,
  handleClose,
  handleCode,
}) => {
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();

  const handleCloseSnack = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handlerSubmit = () => {
    setIsLoading(true);
    if (value === user?.mfaCode) {
      navigate("manageUsers");
    } else {
      setErrorMessage("The code is not valid.");
      setOpenSnack(true);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        // setSuccess(null);
        // cleanForm();
      }}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          bgcolor: "white",
          color: "black",
        },
      }}
    >
      <Grid item container p={2} direction="row-reverse">
        <IconButton
          onClick={() => {
            handleClose();
          }}
          sx={{ color: "#000094" }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </Grid>
      <Grid
        className={"VerificationCode"}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs="auto">
          <img src={VerificationCodeIcon} height={80} width={80} />
          <Typography
            style={{ color: "#242627", fontWeight: 700, fontSize: 25 }}
          >
            2-Step Verification
          </Typography>
          <Typography
            style={{ color: "#384057", fontWeight: 500, fontSize: 16 }}
          >
            We’ve sent you a confirmation code via email, please enter it in a
            fields below
          </Typography>
        </Grid>
        <Grid
          item
          sm
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
          sx={{mb:5,mt:5}}
        >
          <ReactInputVerificationCode
            value={value}
            placeholder="0"
            length={5}
            onChange={(newValue) => {
              setValue(newValue);

              if (newValue !== "") {
                setError("");
              }
            }}
          />
        </Grid>
        <Grid item xs>
          <Grid
            item
            sm
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            rowSpacing={2}
          >
            <Typography
              style={{ color: "#6A7288", fontWeight: 500, fontSize: 13 }}
            >
              Didn’t receive a code?
            </Typography>
            <Typography
              onClick={() => handleCode()}
              sx={{ textDecoration: "none" }}
              style={{
                color: "#000094",
                fontWeight: 500,
                fontSize: 13,
                paddingLeft: 10,
                cursor: "pointer",
              }}
            >
              Re-Send
            </Typography>
          </Grid>
          <CustomButton
            isLoading={isLoading}
            text="Confirm"
            type="button"
            onClick={handlerSubmit}
            sx={{ mb: 1, marginLeft: 0, marginRight: 0, marginTop: 5 }}
          ></CustomButton>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
