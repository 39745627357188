import * as React from "react";
import * as Yup from "yup";

import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import UserPool from "../Services/UserPool";
import LabelAux from "../components/Informatives/LabelAux";
import CustomAlert from "../components/CustomAlert/customAlert";
import { updateFirstResponderbyId } from "../Services/firstResponderService";
import { LayoutSign } from "../Layout/layout-sign/layout-sign";
// import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CustomButton } from "../components/CustomButton/CustomButton";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { CognitoAuthService } from "../Services/CognitoAuthService";
import { FirstResponderData } from "../Interfaces/FirstResponder";
import ModalTermsAndConditions from "../../src/pages/FRAdmin/Agency/termAndConditions";
import { useState } from "react";
export default function ChangePassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const firstResponderUser = location.state as FirstResponderData;
  const cognitoAuthService = new CognitoAuthService();
  const [cognitoUser, setCognitoUser] = React.useState<any>();
  const [yupSchema, setYupSchema] = React.useState<any>();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showCopyPassword, setShowCopyPassword] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState({
    type: "",
    message: "",
    show: false,
  });
  const [isLoadingButton, setIsLoadingButton] = React.useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowCopyPassword = () =>
    setShowCopyPassword(!showCopyPassword);
  const handleMouseDownCopyPassword = () =>
    setShowCopyPassword(!showCopyPassword);

  const [isChecked, setIsChecked] = useState(false);

  const handleAgree = (
    isChecked: boolean | ((prevState: boolean) => boolean)
  ) => {
    setIsChecked(isChecked);
  };

  const passwordErrorMessage = "Passwords don't match";
  const passwordLabelRules =
    " Password must contain at least 8 characters, " +
    "any characters (text or numbers) are allowed. " +
    "Must have one capital letter, one number, " +
    "and one special character.";

  const passwordRules = <LabelAux content={passwordLabelRules}></LabelAux>;
  const passwordRepeatRules = (
    <LabelAux content={"Repeat your new password"}></LabelAux>
  );
  const passwordRegExp = /^(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/;

  const formik = useFormik({
    initialValues: { password: "", passwordCopy: "" },
    validationSchema: yupSchema,
    onSubmit: (formData: any) => {},
  });

  const loadForm = () => {
    const yupDefinition: any = {};
    yupDefinition.password = Yup.string()
      .required()
      .matches(passwordRegExp, "The password is not valid");
    yupDefinition.passwordCopy = Yup.string()
      .required()
      .equals([Yup.ref("password")], passwordErrorMessage);

    const schema = Yup.object().shape(yupDefinition);
    setYupSchema(schema);
  };

  const validateForm = () => {
    const message: string[] = [];
    let isValid = true;

    if (!formik.isValid) {
      const passError = formik.errors.password || "";
      const passErrorCopy = formik.errors.passwordCopy || "";

      if (
        formik.errors.password != undefined &&
        !formik.errors.password.includes("required")
      )
        message.push(passError);
      if (
        formik.errors.passwordCopy != undefined &&
        !formik.errors.passwordCopy.includes("required")
      )
        message.push(passErrorCopy);
      if (
        formik.values.password.length == 0 ||
        formik.values.passwordCopy.length == 0
      )
        message.push(passwordErrorMessage);
    } else {
      if (
        formik.values.password.length == 0 ||
        formik.values.passwordCopy.length == 0
      )
        message.push(passwordErrorMessage);
    }
    return {
      isValid: message.length <= 0,
      message,
    };
  };

  const handlerChangePassword = (e: any) => {
    formik.setValues({
      ...formik.values,
      password: e.target.value,
    });
  };

  const handlerChangePasswordCopy = (e: any) => {
    formik.setValues({
      ...formik.values,
      passwordCopy: e.target.value,
    });
  };

  const handlerSubmit = () => {
    // debugger; // eslint-disable-line no-debugger
    setIsLoadingButton(true);
    formik
      .submitForm()
      .then(async () => {
        const validationState = validateForm();
        if (formik.isValid && validationState.isValid) {
          //TODO : cree el usuario en cognito y luego lo actualice cambiandole el estado a "active" y poniendo el otp en null
          await cognitoAuthService
            .createUser({
              firstName: firstResponderUser.firstName,
              lastName: firstResponderUser.lastName,
              city: firstResponderUser.city,
              state: firstResponderUser.state,
              office: firstResponderUser.office,
              email: firstResponderUser.email,
              username: firstResponderUser.lastName,
              rol: "firstResponder",
              password: formik.values.password,
              status: firstResponderUser.status,
            })
            .then(async (response) => {
              await updateFirstResponderbyId(firstResponderUser.id, {
                otp: "",
                status: "active",
                rol: "firstResponder",
                subCognitoId: response,
                mfa: false,
              })
                .then((res: any) => {
                  console.log(res);
                  navigate("/ValidationCode", {
                    state: firstResponderUser,
                  });
                })
                .catch((err: any) => {
                  console.log(err);
                  alert(err.message);
                });
            })
            .catch(() => {});
        } else {
          setIsLoadingButton(false);
          setMessageAlert({
            type: "error",
            message: validationState.message[0],
            show: true,
          });
        }
      })
      .catch(() => setIsLoadingButton(false));
  };

  React.useEffect(() => {
    const cognitoUserData = UserPool.getCurrentUser();
    setCognitoUser(cognitoUserData);
    loadForm();
  }, []);

  return (
    <>
      <LayoutSign
        children={
          <Container>
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography fontSize={29} fontWeight={400} sx={{ paddingTop: 2 }}>
                Hi, {firstResponderUser.firstName}
              </Typography>
              <Typography
                fontSize={29}
                fontWeight={600}
                sx={{ paddingBottom: 4 }}
              >
                Create your own password
              </Typography>

              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack>
                      {messageAlert.show && (
                        <div style={{ marginBottom: "24px" }}>
                          <CustomAlert
                            type={messageAlert.type}
                            message={messageAlert.message}
                          ></CustomAlert>
                        </div>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      className="MiuTextField"
                      placeholder="Password"
                      variant="outlined"
                      size="small"
                      type={showPassword ? "text" : "password"}
                      onChange={handlerChangePassword}
                      error={Boolean(formik.errors.password)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {passwordRules}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      className="MiuTextField"
                      placeholder="Repeat Password"
                      size="small"
                      variant="outlined"
                      type={showCopyPassword ? "text" : "password"}
                      onChange={handlerChangePasswordCopy}
                      error={Boolean(formik.errors.passwordCopy)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={handleClickShowCopyPassword}
                              onMouseDown={handleMouseDownCopyPassword}
                            >
                              {showCopyPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    {passwordRepeatRules}
                  </Grid>
                </Grid>
                <Grid xs={12} sx={{ textAlign: "center" }}>
                  <label>
                    By checking the box, you accept ...
                    <br />
                    <ModalTermsAndConditions handleAgree={handleAgree} />
                  </label>
                </Grid>
                <CustomButton
                  text="Complete"
                  isLoading={isLoadingButton}
                  type="button"
                  onClick={handlerSubmit}
                  sx={{ mb: 1, marginLeft: 0, marginRight: 0 }}
                  disabled={!isChecked}
                ></CustomButton>
              </form>
            </Box>
          </Container>
        }
      ></LayoutSign>
    </>
  );
}
