import { Box, Container } from '@mui/material'
import { FC, ReactElement } from 'react';
import { styled as muStyled } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';

const styles = {
    boxContainer: {
        alignItems: "center",
        textAlign: "center",
        height: '100vh',
        display: 'flex',
        padding: 0
    }
}

const StyledContainer = muStyled(Container, {
    shouldForwardProp: (prop: any) => prop !== 'isRaised',
})<{ isRaised?: boolean; }>(({ isRaised = false }) => ({
    paddingBottom: isRaised ? 0 : 60,
    position: "relative",
    zIndex: 5,
    height: '100%'
}));

interface LayoutContainerProps {
    isRaised?: boolean;
    children: React.ReactNode | React.ReactNode[];
    sx?: {};
}

export const LayoutCenterContainer: FC<LayoutContainerProps> = (props): ReactElement => {
    return (
        <StyledContainer isRaised={props.isRaised} maxWidth={false} sx={props.sx}>
            <CssBaseline />
            <Box sx={styles.boxContainer}>
                {props.children}
            </Box>
        </StyledContainer>
    )
}
