export const FR_STATUS = {
  ACTIVE: 'active',
  EXPIRED: 'expired',
  INVITED: 'invited',
  BLOCKED: 'blocked',
  NEW: 'new',
  PENDING: 'pending',
}

export const FR_ROLE = {
  FIRST_RESPONDER_OWNER: 'firstResponderOwner',
  FIRST_RESPONDER_ADMIN: 'firstResponderAdmin',
  FIRST_RESPONDER_USER: 'firstResponder'
}
