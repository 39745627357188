import * as React from "react";
import * as Yup from "yup";

import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import UserPool from "../Services/UserPool";
import LabelAux from "../components/Informatives/LabelAux";
import CustomAlert from "../components/CustomAlert/customAlert";
import { CognitoAuthService } from "../Services/CognitoAuthService";
import { LayoutSign } from "../Layout/layout-sign/layout-sign";
// import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CustomButton } from "../components/CustomButton/CustomButton";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";

export default function VerificationCodePassword() {
  const cognitoAuthService = new CognitoAuthService();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [cognitoUser, setCognitoUser] = React.useState<any>();
  const [yupSchema, setYupSchema] = React.useState<any>();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showCopyPassword, setShowCopyPassword] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState({
    type: "",
    message: "",
    show: false,
  });
  const [isLoadingButton, setIsLoadingButton] = React.useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowCopyPassword = () =>
    setShowCopyPassword(!showCopyPassword);
  const handleMouseDownCopyPassword = () =>
    setShowCopyPassword(!showCopyPassword);

  const passwordErrorMessage = "Passwords don't match";
  const verificationCodeErrorMessage = "Verification Code is required";
  const passwordLabelRules =
    " Password must contain at least 8 characters, " +
    "any characters (text or numbers) are allowed. " +
    "Must have one capital letter, one number, " +
    "and one special character.";

  const passwordRules = <LabelAux content={passwordLabelRules}></LabelAux>;
  const passwordRepeatRules = (
    <LabelAux content={"Repeat your new password"}></LabelAux>
  );
  const passwordRegExp = /^(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/;

  const formik = useFormik({
    initialValues: { password: "", passwordCopy: "", verificationCode: "" },
    validationSchema: yupSchema,
    onSubmit: (formData: any) => {},
  });

  const loadForm = () => {
    const yupDefinition: any = {};
    yupDefinition.password = Yup.string()
      .required()
      .matches(passwordRegExp, "The password is not valid");
    yupDefinition.passwordCopy = Yup.string()
      .required()
      .equals([Yup.ref("password")], passwordErrorMessage);
    yupDefinition.verificationCode = Yup.string().required(
      verificationCodeErrorMessage
    );

    const schema = Yup.object().shape(yupDefinition);
    setYupSchema(schema);
  };

  const validateForm = () => {
    const message: string[] = [];
    let isValid = true;

    if (!formik.isValid) {
      const passError = formik.errors.password || "";
      const passErrorCopy = formik.errors.passwordCopy || "";

      if (
        formik.errors.password != undefined &&
        !formik.errors.password.includes("required")
      )
        message.push(passError);
      if (
        formik.errors.passwordCopy != undefined &&
        !formik.errors.passwordCopy.includes("required")
      )
        message.push(passErrorCopy);
      if (
        formik.values.password.length == 0 ||
        formik.values.passwordCopy.length == 0
      )
        message.push(passwordErrorMessage);

      if (formik.values.verificationCode.length == 0)
        message.push(verificationCodeErrorMessage);
    } else {
      if (
        formik.values.password.length == 0 ||
        formik.values.passwordCopy.length == 0
      )
        message.push(passwordErrorMessage);
    }
    return {
      isValid: message.length <= 0,
      message,
    };
  };

  const handlerChangeVerificationCode = (e: any) => {
    formik.setValues({
      ...formik.values,
      verificationCode: e.target.value,
    });
  };

  const handlerChangePassword = (e: any) => {
    formik.setValues({
      ...formik.values,
      password: e.target.value,
    });
  };

  const handlerChangePasswordCopy = (e: any) => {
    formik.setValues({
      ...formik.values,
      passwordCopy: e.target.value,
    });
  };

  const handlerSubmit = () => {
    setIsLoadingButton(true);
    formik
      .submitForm()
      .then(() => {
        const validationState = validateForm();
        //temporal fix for special charactere on query params
        let emailQueryParam = String(params.get("email")).replace(" ", "+");
        if (formik.isValid && validationState.isValid) {
          setMessageAlert({ type: "error", message: "", show: false });
          cognitoAuthService
            .confirmForgotPassword(
              emailQueryParam,
              formik.values.verificationCode,
              formik.values.password
            )
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              setIsLoadingButton(false);
              setMessageAlert({
                type: "error",
                message:
                  "The verification code is not valid. Please try again.",
                show: true,
              });
            });
        } else {
          setIsLoadingButton(false);
          setMessageAlert({
            type: "error",
            message: validationState.message[0],
            show: true,
          });
        }
      })
      .catch(() => setIsLoadingButton(false));
  };

  React.useEffect(() => {
    const cognitoUserData = UserPool.getCurrentUser();
    setCognitoUser(cognitoUserData);
    loadForm();
  }, []);

  return (
    <>
      <LayoutSign
        children={
          <Container>
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack>
                      {messageAlert.show && (
                        <div style={{ marginBottom: "24px" }}>
                          <CustomAlert
                            type={messageAlert.type}
                            message={messageAlert.message}
                          ></CustomAlert>
                        </div>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="verificationCode"
                      fullWidth
                      className="MiuTextField"
                      placeholder="Verification Code"
                      size="small"
                      variant="outlined"
                      onChange={handlerChangeVerificationCode}
                      error={Boolean(formik.errors.verificationCode)}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      className="MiuTextField"
                      placeholder="Password"
                      variant="outlined"
                      size="small"
                      type={showPassword ? "text" : "password"}
                      onChange={handlerChangePassword}
                      error={Boolean(formik.errors.password)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {passwordRules}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      className="MiuTextField"
                      placeholder="Repeat Password"
                      size="small"
                      variant="outlined"
                      type={showCopyPassword ? "text" : "password"}
                      onChange={handlerChangePasswordCopy}
                      error={Boolean(formik.errors.passwordCopy)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={handleClickShowCopyPassword}
                              onMouseDown={handleMouseDownCopyPassword}
                            >
                              {showCopyPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    {passwordRepeatRules}
                  </Grid>
                </Grid>
                <CustomButton
                  text="Complete"
                  isLoading={isLoadingButton}
                  type="button"
                  onClick={handlerSubmit}
                  sx={{ mb: 1, marginLeft: 0, marginRight: 0 }}
                ></CustomButton>
              </form>
            </Box>
          </Container>
        }
      ></LayoutSign>
    </>
  );
}
