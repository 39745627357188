import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGetUserByCognitoId } from '../Hooks/useFirstResponderService';

const initialValue = {
  start: true,
  stepVerify: false,
  stepverifySuccess: false
}

const stepVerificationContext = createContext<StepVerificationContext | null>(null);

export function StepVerificationContextProvider({
  children,
}: StepVerificationContextProviderProps) {
  const { response: { mfa, ...userInfo } } = useGetUserByCognitoId()
  const [dialogToOpen, setDialogToOpen] = useState<DialogSetting | boolean>(null)
  const [isActive, setIsActive] = useState<boolean>(mfa)
  const [dialogStatus, setDialogStatus] = useState<DialogStatus>(initialValue)

  useEffect(() => {
    if (mfa) {
      setIsActive(mfa)
    }
  }, [mfa])

  const handleClose = useCallback(() => { setDialogToOpen(!dialogToOpen) }, []);

  const resetStatusDialog = useCallback(() => setDialogStatus(initialValue), []);

  const value = useMemo(
    () => ({
      mfa,
      userInfo,
      dialogToOpen,
      setDialogToOpen,
      isActive,
      setIsActive,
      dialogStatus,
      setDialogStatus,
      handleClose,
      resetStatusDialog
    }),
    [
      mfa,
      userInfo,
      dialogToOpen,
      setDialogToOpen,
      isActive,
      setIsActive,
      dialogStatus,
      setDialogStatus,
      handleClose,
      resetStatusDialog
    ]
  );

  return (
    <stepVerificationContext.Provider value={value}>
      {children}
    </stepVerificationContext.Provider>
  );
}

export function useStepVerificationContext() {
  const context = useContext(stepVerificationContext);

  if (!context) {
    throw new Error(
      'stepVerificationContext must be used within a StepVerificationContextProvider'
    );
  }

  return context;
}

export interface StepVerificationContext {
  mfa: boolean
  userInfo: any
  dialogToOpen: DialogSetting | boolean
  setDialogToOpen: React.Dispatch<React.SetStateAction<DialogSetting | boolean>>
  isActive: boolean
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>
  dialogStatus: DialogStatus
  setDialogStatus: React.Dispatch<React.SetStateAction<DialogStatus>>
  handleClose: () => void
  resetStatusDialog: () => void
}

export interface StepVerificationContextProviderProps {
  children: ReactNode;
}

export type DialogSetting = "edit-profile" | "step-verification" | "change-password" | "successful-change-password" | null

export interface DialogStatus {
  start: boolean
  stepVerify: boolean
  stepverifySuccess: boolean
}
