import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
// import { useCognitoAwsV2 } from "../Hooks/useCognitoAwsv2";

function OnEnterRoute() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(false);

  // const { isSessionValid } = useCognitoAwsV2();
  useEffect(() => {
    if (isLoading == true) {
      validateUserSession();
    }
  }, []);

  async function validateUserSession(): Promise<void> {
    // const isValid = await isSessionValid();
    const isValid = true;
    console.log(isValid);

    setIsValid(isValid);
    setIsLoading(false);
  }
  // return (
  //   <>{!isLoading && (isValid ? <Navigate to="Login" /> : <Outlet />)}</>
  // );

  return <>{<Outlet />}</>;
}

export default OnEnterRoute;
