import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Allergy } from "./../Interfaces/allergy";
import { Disability } from "./../Interfaces/disability";
import { Document } from "./../Interfaces/document";
import { MedicalCondition } from "./../Interfaces/medicalcondition";
import { Medication } from "./../Interfaces/medication";
import { Membership } from "./../Interfaces/membership";
import { Pet } from "./../Interfaces/pet";
import { Subscriber } from "./../Interfaces/subscriber";
import { Supplement } from "./../Interfaces/supplement";
import { Surgery } from "./../Interfaces/surgery";
import { Vaccine } from "./../Interfaces/vaccine";
import {
  getDocumentByBucketKey,
  getDocuments,
} from "./../Services/documentService";
import {
  getAllergies,
  getDisabilitys,
  getMedicalConditions,
  getMedications,
  getMembership,
  getPets,
  getSupplements,
  getSurgerys,
  getVaccines,
} from "./../Services/suscriberService";
import {
  getUser,
  getUserIDByAccessCode,
  getUsers,
} from "./../Services/userService";

export function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useGetID(idUser: string) {
  const [idSuscriber, setIdSuscriber] = useState("");
  const [loadUser, setLoadUser] = useState(true);

  useEffect(() => {
    getUserIDByAccessCode(idUser).then((subs: any) => {
      setIdSuscriber(subs);
      setLoadUser(false);
    });
  }, []);

  return { idSuscriber, loadUser };
}

export function useSubscribers(customSubscribers?: Subscriber[], qty?: number) {
  if (customSubscribers) {
    return { subscribers: customSubscribers, loading: false };
  }

  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUsers(qty).then((subs: any) => {
      setSubscribers(subs);
      setLoading(false);
    });
  }, []);

  return { subscribers, loading };
}

export function useSubscriber(id: string) {
  const [subscriber, setSubscriber] = useState<Subscriber | null>(null);
  const [loadingSuscriber, setLoadingSuscriber] = useState(true);

  useEffect(() => {
    getUser(id).then((sub: any) => {
      console.log("sub", sub);
      setSubscriber(sub);
      setLoadingSuscriber(false);
    });
  }, []);

  return { subscriber, loadingSuscriber, setSubscriber };
}

export function useSubscriberByAccessCode(accessCode: string) {
  const [subscriber, setSubscriber] = useState<any | null>(null);
  const [loadingSuscriber, setLoadingSuscriber] = useState(true);

  useEffect(() => {
    getUserIDByAccessCode(accessCode).then((sub: any) => {
      setSubscriber(sub);
      setLoadingSuscriber(false);
    });
  }, []);

  return { subscriber, loadingSuscriber, setSubscriber };
}

export function useAllergy(id: string) {
  const [allergy, setAllergy] = useState<Allergy | null>(null);
  useEffect(() => {
    getAllergies(id).then((sub) => {
      setAllergy(sub);
    });
  }, []);

  return { allergy, setAllergy };
}

export function useDisability(id: string) {
  const [disability, setDisability] = useState<Disability | null>(null);
  useEffect(() => {
    getDisabilitys(id).then((sub) => {
      setDisability(sub);
    });
  }, []);

  return { disability, setDisability };
}

export function useMedicalCondition(id: string) {
  const [medicalcondition, setMedicalCondition] =
    useState<MedicalCondition | null>(null);
  useEffect(() => {
    getMedicalConditions(id).then((sub) => {
      setMedicalCondition(sub);
    });
  }, []);

  return { medicalcondition, setMedicalCondition };
}

export function useMedication(id: string) {
  const [medication, setMedication] = useState<Medication | null>(null);
  useEffect(() => {
    getMedications(id).then((sub) => {
      setMedication(sub);
    });
  }, []);

  return { medication, setMedication };
}

export function usePet(id: string) {
  const [pet, setPet] = useState<Pet | null>(null);
  useEffect(() => {
    getPets(id).then((sub) => {
      setPet(sub);
    });
  }, []);

  return { pet, setPet };
}

export function useSupplement(id: string) {
  const [supplement, setSupplement] = useState<Supplement | null>(null);
  useEffect(() => {
    getSupplements(id).then((sub) => {
      setSupplement(sub);
    });
  }, []);

  return { supplement, setSupplement };
}

export function useSurgery(id: string) {
  const [surgery, setSurgery] = useState<Surgery | null>(null);
  useEffect(() => {
    getSurgerys(id).then((sub) => {
      setSurgery(sub);
    });
  }, []);

  return { surgery, setSurgery };
}

export function useVaccine(id: string) {
  const [vaccine, setVaccine] = useState<Vaccine | null>(null);
  useEffect(() => {
    getVaccines(id).then((sub) => {
      setVaccine(sub);
    });
  }, []);

  return { vaccine, setVaccine };
}

export function useMembership(id: string) {
  const [membership, setMembership] = useState<Membership | null>(null);
  const [loadingMembership, setLoadingMembership] = useState(true);
  useEffect(() => {
    getMembership(id).then((mem) => {
      const orderMembership =
        mem.data != null
          ? mem.data.sort((n1, n2) => {
              if (n1.createdAt > n2.createdAt) {
                return 1;
              }
              if (n1.createdAt < n2.createdAt) {
                return -1;
              }
              return 0;
            })
          : mem.data;
      mem.data = orderMembership;
      setMembership(mem);
      setLoadingMembership(false);
    });
  }, []);

  return { membership, loadingMembership, setMembership };
}

export function useDocuments(id: string, option: string) {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [avatar, setAvatar] = useState("");
  const [insuranceFront, seInsuranceFront] = useState("");
  const [fileName, setFileName] = useState("");
  const [physician, setPhysician] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDocuments(id).then((sub) => {
      setDocuments(sub);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (documents && documents.length > 0) {
      const avatar = documents.filter((d) => d.option === option)[0];
      if (avatar) {
        getDocumentByBucketKey(id || "", avatar.bucketKey).then((sub: any) => {
          if (option == "avatar") {
            setAvatar(sub);
          }
          if (option == "health_insurance_front") {
            seInsuranceFront(sub);
          }
          if (option == "care_physician") {
            setPhysician(sub);
            setFileName(avatar.fileName);
          }
          setLoading(false);
        });
        // const file = await convertBase64(result)
      }
    }
  }, [documents]);
  return {
    documents,
    loading,
    setDocuments,
    avatar,
    insuranceFront,
    physician,
    fileName,
  };
}

export function useSomeDocuments(id: string, option: string) {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [avatar, setAvatar] = useState("");
  const [insuranceFront, seInsuranceFront] = useState("");
  const [fileName, setFileName] = useState("");
  const [physician, setPhysician] = useState<any>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDocuments(id).then((sub) => {
      setDocuments(sub);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const arr: any = [];
    if (documents && documents.length > 0) {
      const avatar = documents.filter(
        (d: Document, index: number) => d.option === option
      );
      avatar.map((element) => {
        getDocumentByBucketKey(id || "", element.bucketKey).then((sub: any) => {
          if (option == "care_physician") {
            arr.push({ name: element.fileName, blob: sub });
            setFileName(element.fileName);
          }
          setLoading(false);
        });
        // const file = await convertBase64(result)
      });
      setPhysician(arr);
    }
  }, [documents]);
  return {
    documents,
    loading,
    setDocuments,
    avatar,
    insuranceFront,
    physician,
    fileName,
  };
}
