import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import { CgUnblock } from "react-icons/cg";
import { useUpdateStatusFirstResponder } from "../../Hooks/useFirstResponderService";

interface Props {
  bulk: boolean;
  users: any[];
  open: boolean;
  handleClose: () => void;
}

const UnblockDialog: FC<Props> = ({ bulk, users, open, handleClose }) => {
  const notActiveUsers = users.filter((user) => user.status === "blocked");
  const [success, setSuccess] = useState(false);
  const [newChecked, setNewChecked] = useState(true);
  const [expiredChecked, setExpiredChecked] = useState(true);
  const [invitedChecked, setInvitedChecked] = useState(true);

  const { handleClickButton, isloading, response } =
    useUpdateStatusFirstResponder(
      notActiveUsers.map((x) => x.id).join(","),
      "active"
    );

  useEffect(() => {
    if (response && response.affected && response.affected > 0) {
      let message =
        "?advice=unblocked&message=" +
        notActiveUsers
          .slice(0, 2)
          .map((x) => x.firstName + " " + x.lastName)
          .join(", ");
      let lengthMessage =
        response.affected >= 3
          ? " and " + (response.affected - 2) + " more "
          : "";
      window.location.replace(window.location.href + message + lengthMessage);
    }
  }, [response]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setSuccess(false);
        handleClose();
      }}
      fullWidth
      maxWidth="md"
    >
      <Grid container direction="column" alignItems="center">
        <Grid item container p={2} direction="row-reverse">
          <IconButton
            onClick={() => {
              setSuccess(false);
              handleClose();
            }}
            sx={{ color: "#000094" }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          pt={3}
          px={8}
          pb={8}
        >
          <Grid item color="#000094">
            <CgUnblock
              style={{
                fontSize: 76,
              }}
            />
          </Grid>
          <Grid item>
            <Typography color="#242627" fontSize={25} fontWeight={700}>
              Confirm User Unblock
            </Typography>
          </Grid>
          <Grid item mt={0.5}>
            <Typography color="#242627" fontSize={16} fontWeight={400}>
              Access to the system for this user will be restored
            </Typography>
          </Grid>
          <Grid item mt={0.5}>
            <Typography color="#384057" fontSize={16} fontWeight={500}>
              User to unblock:{" "}
              <label style={{ color: "#000094" }}>
                {notActiveUsers.length === 1
                  ? `${users[0].firstName} ${users[0].lastName}`
                  : `${notActiveUsers.length}`}
              </label>
            </Typography>
          </Grid>
          <Grid item mt={3}>
            <Button
              size="large"
              variant="contained"
              sx={{
                color: "#FFF",
                textTransform: "none",
                border: "1px solid rgba(0, 0, 148, 0.4)",
              }}
              onClick={() => {
                handleClickButton!();
                handleClose();
              }}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default UnblockDialog;
