import { FC, ReactElement } from 'react'
import { Button, ButtonProps, CircularProgress, Box, styled as muStyled } from '@mui/material';


const StyledButton = muStyled(Button)({
    width: '100%',
    height: 48,
    textTransform: 'none',
    fontSize: '1rem',
    color: '#fff',
    borderRadius: '8px',
    backgroundColor: '#000094'
});

const StyledProgress = muStyled(CircularProgress)(({ theme }) => ({
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '40%',
    left: '50%',
    marginTop: '15px',
    marginLeft: '-12px'
}));

interface CustomButtonProps extends ButtonProps {
    text: string,
    isLoading?: boolean
}

export const CustomButton: FC<CustomButtonProps> = (props): ReactElement => {
    
    const { text, isLoading = false, ...rest } = props;
    
    return (
        <Box sx={{ position: 'relative' }}>
            <StyledButton
                variant="contained"
                sx={{ mt: 2, mb: 1 }}
                disabled={isLoading}
                {...rest}>
                {text}
            </StyledButton>
            {isLoading && <StyledProgress size={24} />}
        </Box>
    )
}