import Typography from "@mui/material/Typography"
import { FC } from "react"
import { StatusesType } from "../../pages/FRAdmin/ManageUsers"
import All from "./All"
import Expired from "./Expired"
import Invited from "./Invited"
import Blocked from "./Blocked"
import Active from "./Active"
import New from "./New"

interface Props {
  status: StatusesType
  users: any[]
  isSelection: boolean
}

const ManageUsersActions: FC<Props> = ({ status, users, isSelection }) => {
  switch (status) {
    case "all":
      return <All users={users} isSelection={isSelection} />
    case "expired":
      return <Expired users={users} isSelection={isSelection} />
    case "invited":
      return <Invited users={users} isSelection={isSelection} />
    
    case "active":
      return <Active users={users} isSelection={isSelection} />

    case "blocked":
      return <Blocked users={users} isSelection={isSelection} />

    case "new":
      return <New users={users} isSelection={isSelection} />
    default:
      return <Typography variant="h6">No actions available</Typography>
  }
}

export default ManageUsersActions
 