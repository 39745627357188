import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FirstResponderData } from "../../Interfaces/FirstResponder";

import { STATUSES, StatusesType } from "../../pages/FRAdmin/ManageUsers";
import BlockDialog from "../BlockDialog";
import DeleteUsersDialog from "../DeleteUsersDialog";
import EditUserDialog from "../EditUserDialog";
import SendInvitesDialog from "../SendInvitesDialog";
import UnblockDialog from "../UnblockDialog";

const columns: GridColDef[] = [
  {
    field: "fullName",
    headerName: "Full Name",
    flex: 2,
    renderCell: (params) => {
      return (
        <>
          <Avatar sx={{ mr: 1.5, bgcolor: "#000094", fontSize: 16 }}>{`${
            params.row.firstName[0] || ""
          }${params.row.lastName[0] || ""}`}</Avatar>
          {`${params.row.firstName || ""} ${params.row.lastName || ""}`}
        </>
      );
    },
    valueGetter: (params) => {
      return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
    },
  },
  {
    field: "id",
    headerName: "FR ID",
    flex: 1,
    valueGetter: (params) => `FR${params.row.mmhId}`,
  },
  {
    field: "badge",
    headerName: "Badge #",
    flex: 1,
    valueGetter: (params) => params.row.badge || "No badge",
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => {
      let status = params.value as StatusesType;
      return (
        <div
          style={{
            color: STATUSES[status].color,
            backgroundColor: STATUSES[status].color + "1F",
            paddingRight: "8px",
            paddingLeft: "8px",
            paddingTop: "4px",
            paddingBottom: "4px",
            borderRadius: "6px",
          }}
        >
          {status.toUpperCase()}
        </div>
      );
    },
  },
  {
    field: "updatedAt",
    headerName: "Last Updated",
    flex: 1,
    valueFormatter: (params) => {
      return new Date(
        params.value ?? params.api.getRowParams(params.id).row.createdAt
      ).toLocaleDateString();
    },
    valueGetter: (params) => {
      return params.row.updatedAt || params.row.createdAt;
    },
  },
  { field: "city", headerName: "City", flex: 1 },
  { field: "state", headerName: "State", flex: 1 },
  { field: "zipCode", headerName: "Zip Code", flex: 1 },
  {
    field: "actions",
    headerName: "Actions",
    flex: 1,
    sortable: false,
    renderCell: (params) => <Actions user={params.row} />,
  },
];

const Actions = ({ user }: { user: any }) => {
  const navigate = useNavigate();
  const [dialogToOpen, setDialogToOpen] = useState<
    "invite" | "delete" | "edit" | "block" | "unblock" | null
  >(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {user.status === "new" ||
      user.status === "invited" ||
      user.status === "expired" ? (
        <IconButton
          sx={{ color: "#000094" }}
          onClick={() => setDialogToOpen("invite")}
        >
          <ForwardToInboxIcon />
        </IconButton>
      ) : (
        <IconButton
          sx={{ color: "#000094" }}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <IconButton
        sx={{ color: "#000094" }}
        onClick={() => setDialogToOpen("edit")}
      >
        <ModeEditOutlineOutlinedIcon />
      </IconButton>
      <IconButton
        sx={{ color: "#000094" }}
        onClick={() => setDialogToOpen("delete")}
      >
        <DeleteOutlineOutlinedIcon />
      </IconButton>
      <SendInvitesDialog
        reInvite={false}
        open={dialogToOpen === "invite"}
        users={[user]}
        bulk={false}
        handleClose={() => setDialogToOpen(null)}
      />
      <DeleteUsersDialog
        open={dialogToOpen === "delete"}
        users={[user]}
        handleClose={() => setDialogToOpen(null)}
      />
      <BlockDialog
        bulk={false}
        users={[user]}
        open={dialogToOpen === "block"}
        handleClose={() => setDialogToOpen(null)}
      />
      <UnblockDialog
        bulk={false}
        users={[user]}
        open={dialogToOpen === "unblock"}
        handleClose={() => setDialogToOpen(null)}
      />
      <EditUserDialog
        user={user as FirstResponderData}
        open={dialogToOpen === "edit"}
        handleClose={() => setDialogToOpen(null)}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {user.status === "active" && (
          <MenuItem
            onClick={() => {
              console.log("user", user);
              navigate(
                `/admin/scanHistory?id=${
                  user.id
                }&name=${user.firstName.replaceAll(
                  " ",
                  "-"
                )}-${user.lastName.replaceAll(" ", "-")}`
              );
            }}
          >
            Scan History
          </MenuItem>
        )}
        {user.status === "active" && (
          <MenuItem
            onClick={() => {
              handleClose();
              setDialogToOpen("block");
            }}
          >
            Block user
          </MenuItem>
        )}
        {user.status === "blocked" && (
          <MenuItem
            onClick={() => {
              handleClose();
              setDialogToOpen("unblock");
            }}
          >
            Unblock user
          </MenuItem>
        )}
        <MenuItem onClick={handleClose}>Reset password</MenuItem>
      </Menu>
    </>
  );
};

interface Props {
  users?: any[];
  setSelectedUsers: (users: any[] | null) => void;
  isloading: boolean;
}

const ManageUsersTable: FC<Props> = ({
  users,
  setSelectedUsers,
  isloading,
}) => {
  const [pageSize, setPageSize] = useState(10);

  return (
    <DataGrid
      sx={{
        color: "black",
        border: 0,
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        ".MuiDataGrid-row": {
          borderRadius: "4px",
          backgroundColor: "#FFFFFF",
        },
        ".MuiDataGrid-columnHeaders": {
          borderRadius: "4px",
          backgroundColor: "#E0E2E9",
        },
        "& .MuiCheckbox-root": {
          color: "#000094",
        },
        ".MuiDataGrid-columnHeaderTitle": {
          fontWeight: 400,
        },
      }}
      loading={isloading}
      rows={users ?? []}
      columns={columns}
      disableColumnMenu
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 20, 50]}
      checkboxSelection
      autoHeight
      pagination
      getRowSpacing={(_) => {
        return {
          top: 6,
        };
      }}
      disableSelectionOnClick
      onSelectionModelChange={(selectedUserIds) => {
        setSelectedUsers(
          !users || !selectedUserIds.length
            ? null
            : users.filter((user) => selectedUserIds.includes(user.id))
        );
      }}
    />
  );
};

export default ManageUsersTable;
