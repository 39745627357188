import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { FC, useEffect, useState } from "react";
import { MdOutlineQrCode } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import handsPhone from "../../Assets/fr-HandsPhone.svg";
import useAuthUserData from "../../Hooks/useAuthUserData";
import { useCognitoAwsV2 } from "../../Hooks/useCognitoAwsv2";
import {
  useSomeDocuments,
  useSubscriber,
} from "../../Hooks/useSuscribersService";
import { Subscriber } from "../../Interfaces/subscriber";
import { checkAdulthood, getFamily } from "../../Services/userService";
import AlertMessageQRScan from "../../components/AlertMessage/AlertMessageQRScan";
import HamburguerMenu from "../HamburguerMenu";
import MemberCard from "./MemberCard";
import styles from "./frPersonalInfo.module.css";

interface Props {
  idUser: string;
  scanMethod?: string;
  registerId?: any;
}

const MembersInfo: FC<Props> = ({ idUser, scanMethod, registerId }) => {
  const { user } = useAuthUserData();
  const { subscriber } = useSubscriber(idUser);
  // const { allergy } = useAllergy(idUser);
  // const { medication } = useMedication(idUser);
  // const { pet } = usePet(idUser);
  // const { surgery } = useSurgery(idUser);
  // const { avatar } = useDocuments(idUser, "avatar");
  const { physician } = useSomeDocuments(idUser, "care_physician");
  const { signOut } = useCognitoAwsV2();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(30);
  const [startInterval, setStartInterval] = useState(false);
  const [startTimmeModal, setStartTimmeModal] = useState(true);
  const [loading, setLoading] = useState(false);

  const [familyMembers, setFamilyMembers] = useState<Subscriber[]>([]);
  const [checkAdulthoodDone, setCheckAdulthoodDone] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (subscriber) {
      getFamily(subscriber.idParentMember ?? subscriber?.id).then((res) => {
        setFamilyMembers(res);
      });
    }
    setLoading(false);
  }, [subscriber]);

  /*Modal States*/
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setStartTimmeModal(true);
    setStartInterval(false);
    setSeconds(30);
  };

  function docsCarePhysicianFunction() {
    let documentMultiple: any = [];
    physician.forEach((element: any, index: number) => {
      documentMultiple.push(<a href={element.blob}>{element.name}</a>);
      documentMultiple.push(<br></br>);
    });
    return documentMultiple;
  }

  useEffect(() => {
    if (startTimmeModal) {
      setTimeout(() => {
        handleOpen();
        setSeconds(30);
        setStartInterval(true);
        setStartTimmeModal(false);
      }, (user?.activeSessionLength ?? 60) * 60 * 1000); // user session length or 60 minutes by default
    }
  }, [startTimmeModal, user]);

  useEffect(() => {
    subscriber?.accessCode == undefined || subscriber?.accessCode == null
      ? localStorage.setItem("AccessCodeFirstResponder", "")
      : localStorage.setItem(
        "AccessCodeFirstResponder",
        subscriber?.accessCode
      );
  }, [subscriber]);

  useEffect(() => {
    if (startInterval) {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        handleClose();
        navigate("/");
      }
    }
  }, [startInterval, seconds]);

  useEffect(() => {
    (async () => {
      if (!checkAdulthoodDone && familyMembers.length > 0) {
        let memberList = [];
        for (const member of familyMembers) {
          if (member.memberType === "Child") {
            const { adult } = await checkAdulthood(member?.id);
            const uMember = {
              ...member,
              memberType: adult === true ? "Adult" : "Child",
            };
            memberList.push(uMember);
          } else memberList.push(member);
        }
        setFamilyMembers(memberList);
        setCheckAdulthoodDone(true);
      }
    })();
  }, [familyMembers, checkAdulthoodDone]);

  function padTo2Digits(num: any) {
    return num.toString().padStart(2, "0");
  }

  function numberToMounth(num: any) {
    let arrayMonth = [
      "January ",
      "February ",
      "March ",
      "April ",
      "May ",
      "June ",
      "July ",
      "August ",
      "September ",
      "October ",
      "November ",
      "December ",
    ];
    return arrayMonth[num];
  }

  function formatDate(date: Date) {
    return [
      numberToMounth(date.getMonth()) + padTo2Digits(date.getDate() + 1),
      date.getFullYear(),
    ].join(", ");
  }

  if (loading || subscriber === null) {
    return <>Loading...</>;
  } else {
    if (!subscriber!.active || subscriber!.accessCode == null) {
      return <AlertMessageQRScan open={true} advice={"subscriberNotFound"} />;
    } else {
      return (
        <>
          <HamburguerMenu />
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={styles.modal}>
                <Grid>
                  <p className={styles.modalTitle}>Details Page expired</p>
                  <p className={styles.modalSubtitle}>
                    The information shown on this page will expired in
                  </p>
                  <p className={styles.seconds}>{seconds}</p>
                  <img
                    className={styles.image}
                    src={handsPhone}
                    width={131}
                    height={113}
                  />
                  <p className={styles.modalSubtitle}>
                    To continue viewing this page please Stay Signed in
                  </p>
                </Grid>
                <Grid>
                  <Button
                    className={styles.modalButtons}
                    variant="contained"
                    onClick={async () => {
                      await signOut().then(() => {
                        handleClose();
                        navigate("/");
                      });
                    }}
                  >
                    Log out
                  </Button>
                  <Button
                    variant="contained"
                    className={styles.modalButtons}
                    onClick={handleClose}
                  >
                    Stay Signed in
                  </Button>
                </Grid>
              </Box>
            </Modal>
          </div>

          <Box
            sx={{
              color: "white",
              padding: 1,
              mt: 1,
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={1}
              mb={2}
            >
              <Grid item mt={1}>
                <MdOutlineQrCode fontSize={26} />
              </Grid>
              <Grid item ml={1}>
                <Typography
                  color="white"
                  fontSize={18}
                  fontWeight={500}
                  fontFamily="Source Sans Pro"
                >
                  You have scanned the QR code of:
                </Typography>
              </Grid>
            </Grid>
            {subscriber && <MemberCard registerId={registerId} scanMethod={scanMethod} frId={user?.id} member={subscriber} outlined />}

            <Typography
              color="white"
              fontSize={20}
              fontWeight={600}
              fontFamily="Source Sans Pro"
              sx={{
                mt: 6,
              }}
            >
              Family Members
            </Typography>
            <Typography
              color="white"
              fontSize={18}
              fontWeight={300}
              fontFamily="Source Sans Pro"
            >
              {familyMembers.length === 0
                ? "This user has no family members on their account"
                : "Select a family member to see their information"}
            </Typography>

            {familyMembers.length > 0 && subscriber && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={2}
              >
                {familyMembers.map((member: Subscriber) =>
                  member?.id !== subscriber?.id ? (
                    <Grid item key={member?.id} mt={2} columnSpacing={2} p={0.5}>
                      <MemberCard scanMethod={scanMethod} frId={user?.id} member={member} />
                    </Grid>
                  ) : null
                )}
              </Grid>
            )}
          </Box>
        </>
      );
    }
  }
};

export default MembersInfo;
