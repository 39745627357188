import LoginCompanyComponent from "./LoginCompanyComponent";
import "./PrincipalLayout.css";

type Props = {
  children: any;
  revert: boolean;
};

const PrincipalLayout = ({ children, revert }: Props) => {
  return (
    <div className="principalLayoutSection">
      {!revert && <LoginCompanyComponent />}
      <section className={"principalLayout"}>{children}</section>
      {revert && <LoginCompanyComponent />}
    </div>
  );
};

PrincipalLayout.defaultProps = {
  revert: false,
};
export default PrincipalLayout;
