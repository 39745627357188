import { Box, Container, CssBaseline } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import BoxNotificationEmail from "../components/BoxNotificationEmail/BoxNotificationEmail";
import { CustomButton } from "../components/CustomButton/CustomButton";
import EmailForm from "../components/Email/email";
import LabelAux from "../components/Informatives/LabelAux";
import { LayoutSign } from "../Layout/layout-sign/layout-sign";
import { sendRecoverIDCodeNotification } from "../Services/emailService";

export const CodeRecovery: FC<any> = (): ReactElement => {
  const navigate = useNavigate();
  const [isForm, setIsForm] = React.useState<any>(true);
  const [email, setEmail] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const emailLabel = (
    <LabelAux content={"Enter your email to recover your ID Code"}></LabelAux>
  );

  const handlerSubmitEmail = async (email: string) => {
    // TODO: Call service to send e-mailreact-qr-reader
    // debugger // eslint-disable-line no-debugger
    setIsLoading(true);
    await sendRecoverIDCodeNotification(email).then((res) => {
      setEmail(email);
      setIsLoading(false);
      setIsForm(false);
    });
  };

  const notificationEmailLabel = `A recovery code has been sent to your ${email}. Please check your email for further instructions.`;

  const handlerNavigate = () => {
    navigate("/");
  };

  return (
    <>
      <LayoutSign
        children={
          <Container>
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              pt={3}
            >
              {isForm && (
                <EmailForm
                  title="ID Code Recovery"
                  handlerSendEmail={handlerSubmitEmail}
                  elementLabel={emailLabel}
                  isLoading={isLoading}
                />
              )}
              {!isForm && (
                <div>
                  <BoxNotificationEmail
                    title="Email Sent"
                    elementLabel={notificationEmailLabel}
                  />
                  <CustomButton
                    text="Back to login"
                    type="button"
                    onClick={handlerNavigate}
                    sx={{ mb: 1, marginLeft: 0, marginRight: 0, marginTop: 5 }}
                    isLoading={isLoading}
                  ></CustomButton>
                </div>
              )}
            </Box>
          </Container>
        }
      ></LayoutSign>
    </>
  );
};

export default CodeRecovery;
