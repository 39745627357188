import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MessageIcon from "@mui/icons-material/Message";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { FC, Fragment, useEffect, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { BsDot } from "react-icons/bs";
import { HiOutlineClipboardDocument } from "react-icons/hi2";
import { MdBloodtype, MdError } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import handsPhone from "../../Assets/fr-HandsPhone.svg";
import organDonor from "../../Assets/organ_donor.png";
import avatarPlaceholder from "../../Assets/user_placeholder.jpg";
import useAuthUserData from "../../Hooks/useAuthUserData";
import { useCognitoAwsV2 } from "../../Hooks/useCognitoAwsv2";
import { QRCode } from "react-qrcode-logo";
import {
  useAllergy,
  useDisability,
  useDocuments,
  useMedicalCondition,
  useMedication,
  usePet,
  useSomeDocuments,
  useSubscriber,
  useSupplement,
  useSurgery,
  useVaccine,
} from "../../Hooks/useSuscribersService";
import { Subscriber } from "../../Interfaces/subscriber";
import { getDocumentByBucketKey } from "../../Services/documentService";
import { getFamily } from "../../Services/userService";
import HamburguerMenu from "../HamburguerMenu";
import styles from "./frPersonalInfo.module.css";
import { Dialog } from "@mui/material";

interface Props {
  idUser: string;
}

const FrPersonalInfo: FC<Props> = ({ idUser }) => {
  const origin = window.location.origin;
  const baseUrl = origin.includes("localhost")
    ? "http://localhost:3001"
    : origin.includes("test")
    ? "https://test.firstresponder.mymedhistory.com"
    : origin.includes("dev")
    ? "https://dev.firstresponder.mymedhistory.com"
    : "https://firstresponder.mymedhistory.com";
  const { user } = useAuthUserData();
  const { subscriber } = useSubscriber(idUser);
  const [maxIndex, setMaxIndex] = useState(-1);
  const { allergy: allergies } = useAllergy(idUser);
  const { medication } = useMedication(idUser);
  const { vaccine } = useVaccine(idUser);
  const { medicalcondition } = useMedicalCondition(idUser);
  const { supplement } = useSupplement(idUser);
  const { disability } = useDisability(idUser);
  const { pet } = usePet(idUser);
  const { surgery } = useSurgery(idUser);
  const { avatar, documents } = useDocuments(idUser, "avatar");
  const { physician } = useSomeDocuments(idUser, "care_physician");
  const { signOut } = useCognitoAwsV2();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(30);
  const [startInterval, setStartInterval] = useState(false);
  const [startTimmeModal, setStartTimmeModal] = useState(true);
  const [docsUrl, setDocsUrl] = useState<
    {
      id: string;
      url: string;
    }[]
  >([]);

  const [familyMembers, setFamilyMembers] = useState<Subscriber[]>([]);

  const [commsModal, setCommsModal] = useState<{
    type: string | null;
    open: boolean;
    contact: string | null;
  }>({
    type: null,
    open: false,
    contact: null,
  });

  useEffect(() => {
    if (subscriber) {
      getFamily(subscriber?.idParentMember ?? subscriber?.id).then((res) => {
        setFamilyMembers(res);
      });
    }
  }, [subscriber]);

  useEffect(() => {
    const maxIndexCalc = documents.reduce((max: any, item: any) => {
      if (
        (item.option === "health_insurance_front" ||
          item.option === "health_insurance_back") &&
        item.index > max
      ) {
        return item.index;
      }
      return max;
    }, -1);
    setMaxIndex(maxIndexCalc);
    const promises = documents.map(async (doc) => {
      const blob = await getDocumentByBucketKey(doc.idUser, doc.bucketKey);
      return {
        id: doc.id,
        url: blob as string,
      };
    });

    Promise.all(promises)
      .then((results) => {
        setDocsUrl(results);
      })
      .catch((err) => {
        console.log("err :::", err);
      });
  }, [documents]);

  /*Modal States*/
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setStartTimmeModal(true);
    setStartInterval(false);
    setSeconds(30);
  };

  function docsCarePhysicianFunction() {
    let documentMultiple: any = [];
    physician.forEach((element: any, index: number) => {
      documentMultiple.push(<a href={element.blob}>{element.name}</a>);
      documentMultiple.push(<br></br>);
    });
    return documentMultiple;
  }

  useEffect(() => {
    if (startTimmeModal) {
      setTimeout(() => {
        handleOpen();
        setSeconds(30);
        setStartInterval(true);
        setStartTimmeModal(false);
      }, (user?.activeSessionLength ?? 60) * 60 * 1000); // user session length or 60 minutes by default
    }
  }, [startTimmeModal, subscriber]);

  useEffect(() => {
    subscriber?.accessCode == undefined
      ? localStorage.setItem("AccessCodeFirstResponder", "")
      : localStorage.setItem(
          "AccessCodeFirstResponder",
          subscriber?.accessCode
        );
  }, [subscriber]);

  useEffect(() => {
    if (startInterval) {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        handleClose();
        navigate("/");
      }
    }
  }, [startInterval, seconds]);

  function removeDuplicates(arr: any) {
    return arr.filter((item: any, index: any) => arr.indexOf(item) === index);
  }

  function padTo2Digits(num: any) {
    return num.toString().padStart(2, "0");
  }

  function numberToMounth(num: any) {
    let arrayMonth = [
      "January ",
      "February ",
      "March ",
      "April ",
      "May ",
      "June ",
      "July ",
      "August ",
      "September ",
      "October ",
      "November ",
      "December ",
    ];
    return arrayMonth[num];
  }

  function formatDate(date: Date) {
    return [
      numberToMounth(date.getMonth()) + padTo2Digits(date.getDate() + 1),
      date.getFullYear(),
    ].join(", ");
  }

  const getHeightWeightEyeColorBlock = () => {
    return (
      <Grid container direction="row" p={2}>
        <Grid
          item
          xs={4}
          sm={4}
          mb={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            fontWeight={700}
            fontSize={25}
            sx={{
              color: "#000094",
            }}
          >
            HEIGHT
          </Typography>

          {subscriber?.heightFt !== null && subscriber?.heightIn !== null ? (
            <span>
              <Typography fontWeight={500} fontSize={18}>
                {`${subscriber?.heightFt} Feet `}
                <span
                  style={{
                    color: "#999999",
                  }}
                >
                  (ft)
                </span>
              </Typography>
              <Typography fontWeight={500} fontSize={18}>
                {`${subscriber?.heightIn} Inches `}
                <span
                  style={{
                    color: "#999999",
                  }}
                >
                  (in)
                </span>
              </Typography>
            </span>
          ) : (
            <span
              style={{
                color: "#999999",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Not provided
            </span>
          )}
        </Grid>

        <Grid
          item
          xs={4}
          sm={4}
          mb={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            fontWeight={700}
            fontSize={25}
            sx={{
              color: "#000094",
            }}
          >
            WEIGHT
          </Typography>

          <Typography fontWeight={500} fontSize={18}>
            {subscriber?.weight && `${subscriber?.weight} Pounds `}
            <span
              style={{
                color: "#999999",
              }}
            >
              {subscriber?.weight ? `(lbs)` : `Not provided`}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          mb={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            fontWeight={700}
            fontSize={25}
            sx={{
              color: "#000094",
            }}
          >
            EYE COLOR
          </Typography>

          <Typography fontWeight={500} fontSize={18}>
            {subscriber?.eyeColor ? (
              `${subscriber?.eyeColor}`
            ) : (
              <span
                style={{
                  color: "#999999",
                }}
              >
                Not provided
              </span>
            )}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getAllergiesBlock = () => {
    return (
      <Box
        sx={{
          my: 2.5,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {getAlertTitle("ALLERGIES", 257)}
        {!allergies?.data.length ? (
          <Typography fontWeight={700} fontSize={23}>
            No known allergies
          </Typography>
        ) : (
          allergies?.data.map((allergy) => {
            return (
              <Grid
                key={allergy.id}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                rowSpacing={1}
              >
                <Grid item>
                  <BsDot size={40} />
                </Grid>
                <Grid item xs>
                  <Typography fontWeight={700} fontSize={23}>
                    {allergy.name}
                  </Typography>
                  <Typography
                    fontWeight={500}
                    fontSize={allergy.reaction ? 16 : 15}
                    sx={{
                      color: allergy.reaction ? "#DA1414" : "#999999",
                    }}
                  >
                    {allergy.reaction || "Unknown reaction"}
                  </Typography>
                </Grid>
              </Grid>
            );
          })
        )}
      </Box>
    );
  };

  const getBirthMarksBock = () => {
    return (
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography
          fontWeight={700}
          fontSize={25}
          sx={{
            color: "#000094",
          }}
        >
          DISTINGUISHING MARKS
        </Typography>
        {subscriber &&
          (subscriber?.birthmark?.length > 0 ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              rowSpacing={1}
            >
              <Grid item>
                <BsDot size={40} />
              </Grid>
              <Grid item xs>
                <Typography fontWeight={700} fontSize={23}>
                  {subscriber?.birthmark}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography
              fontWeight={700}
              fontSize={23}
              sx={{
                color: "#999999",
              }}
            >
              Not provided
            </Typography>
          ))}
      </Box>
    );
  };

  const getSurgeriesBlock = () => {
    return (
      <Box
        sx={{
          my: 2.5,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 2,
        }}
      >
        <Typography
          fontWeight={700}
          fontSize={25}
          sx={{
            color: "#000094",
          }}
        >
          SURGERIES
        </Typography>
        {!surgery?.data.length ? (
          <Typography fontWeight={700} fontSize={23}>
            Haven&rsquo;t had any surgeries
          </Typography>
        ) : (
          surgery?.data.map((surgery) => {
            return (
              <Grid
                key={surgery.id}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                rowSpacing={1}
              >
                <Grid item>
                  <BsDot size={40} />
                </Grid>
                <Grid item xs>
                  <Typography fontWeight={700} fontSize={23}>
                    {surgery.name}
                  </Typography>
                  {!surgery.year &&
                  !surgery.deviceImplant &&
                  !surgery.additionalInfo ? (
                    <Typography
                      fontWeight={500}
                      fontSize={15}
                      sx={{
                        color: "#999999",
                      }}
                    >
                      No information provided
                    </Typography>
                  ) : (
                    <>
                      <Typography fontWeight={500} fontSize={16}>
                        <span
                          style={{
                            color: "#555555",
                          }}
                        >
                          Year:{" "}
                        </span>
                        {surgery.year || "N/A"}
                      </Typography>
                      <Typography fontWeight={500} fontSize={16}>
                        <span
                          style={{
                            color: "#555555",
                          }}
                        >
                          Additional Info:{" "}
                        </span>
                        {surgery.additionalInfo || "N/A"}
                      </Typography>
                      <Typography
                        fontWeight={500}
                        fontSize={16}
                        sx={{
                          color: "#DA1414",
                        }}
                      >
                        <span
                          style={{
                            color: "#555555",
                          }}
                        >
                          Device/Implants:{" "}
                        </span>
                        {surgery.deviceImplant || "N/A"}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            );
          })
        )}
      </Box>
    );
  };

  const getDisabilitiesBlock = () => {
    return (
      <Grid
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography
          fontWeight={700}
          fontSize={25}
          sx={{
            color: "#000094",
          }}
        >
          DISABILITIES
        </Typography>
        {!disability?.data.length ? (
          <Typography fontWeight={700} fontSize={23}>
            Don&rsquo;t have any disabilities
          </Typography>
        ) : (
          disability?.data.map((disability) => {
            return (
              <Grid
                key={disability.id}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                rowSpacing={1}
              >
                <Grid item>
                  <BsDot size={40} />
                </Grid>
                <Grid item xs>
                  <Typography fontWeight={700} fontSize={23}>
                    {disability.name}
                  </Typography>
                  {!disability.description ? (
                    <Typography
                      fontWeight={500}
                      fontSize={15}
                      sx={{
                        color: "#999999",
                      }}
                    >
                      No information provided
                    </Typography>
                  ) : (
                    <Typography fontWeight={500} fontSize={16}>
                      <span
                        style={{
                          color: "#555555",
                        }}
                      >
                        Specific needs:{" "}
                      </span>
                      {disability.description || "N/A"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            );
          })
        )}
      </Grid>
    );
  };

  const getAlertTitle = (title: string, width: any) => {
    return (
      <Grid
        container
        sx={{
          gap: 10,
          width,
          height: 46,
          background: "#DA1414",
          borderRadius: 10,
          justifyContent: "center",
        }}
      >
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <ReportProblemIcon sx={{ color: "white" }} />
          <span
            style={{
              color: "white",
              marginLeft: 10,
              fontSize: "25px",
            }}
          >
            {title}
          </span>
          <ReportProblemIcon sx={{ color: "white", marginLeft: "0.7rem" }} />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <HamburguerMenu />
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={styles.modal}>
            <Grid>
              <p className={styles.modalTitle}>Details Page expired</p>
              <p className={styles.modalSubtitle}>
                The information shown on this page will expired in
              </p>
              <p className={styles.seconds}>{seconds}</p>
              <img
                className={styles.image}
                src={handsPhone}
                width={131}
                height={113}
              />
              <p className={styles.modalSubtitle}>
                To continue viewing this page please Stay Signed in
              </p>
            </Grid>
            <Grid>
              <Button
                className={styles.modalButtons}
                variant="contained"
                onClick={async () => {
                  await signOut().then(() => {
                    handleClose();
                    navigate("/");
                  });
                }}
              >
                Log out
              </Button>
              <Button
                variant="contained"
                className={styles.modalButtons}
                onClick={handleClose}
              >
                Stay Signed in
              </Button>
            </Grid>
          </Box>
        </Modal>
      </div>
      <IconButton
        aria-label="back"
        onClick={() => navigate("/SearchSubscriber")}
        sx={{
          ml: 2,
          mb: -1,
        }}
      >
        <ArrowBackIcon
          sx={{
            color: "#fff",
          }}
        />
      </IconButton>
      <Box
        sx={{
          bgcolor: "white",
          color: "black",
          maxWidth: "960px",
          margin: "auto",
        }}
      >
        {/* Subscriber profile name and DOB section */}
        <Box
          sx={{
            p: 2,
            textAlign: "center",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columnSpacing={1}
            item
          >
            <Grid item>
              <QRCode
                value={`${baseUrl}/subscriberInfo?mmh_id=MMH${
                  subscriber?.accessCode || "none"
                }&uuid=${subscriber?.id}`}
                size={200}
              />
            </Grid>
            <Grid item>
              <Typography
                fontWeight={600}
                fontSize={15}
                textAlign="start"
                sx={{
                  color: "#555555",
                  "@media (max-width: 375px)": {
                    textAlign: "center",
                  },
                }}
              >
                Subscriber Profile
              </Typography>
              <Typography
                fontWeight={800}
                fontSize={28}
                textAlign="start"
                sx={{
                  "@media (max-width: 375px)": {
                    textAlign: "center",
                  },
                }}
                lineHeight={1.5}
              >
                {subscriber?.firstName} {subscriber?.lastName}
              </Typography>
              <Typography
                fontWeight={800}
                textAlign="start"
                fontSize={20}
                sx={{
                  "@media (max-width: 375px)": {
                    textAlign: "center",
                  },
                }}
              >
                DOB:{" "}
                {new Date(subscriber?.birthday || "").toLocaleDateString(
                  "en-US",
                  {
                    timeZone: "UTC",
                  }
                )}
              </Typography>
            </Grid>
          </Grid>

          <Divider
            textAlign="center"
            sx={{
              color: "black",
              bgcolor: "black",
              margin: "0 auto",
              width: "55%",
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
          />

          {/* Organ donor, blood type */}
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={3}
            columnSpacing={3}
            mt={1}
            mb={1}
          >
            {/* Organ donor */}
            <Grid
              xs={6}
              sm={6}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              columnSpacing={1}
            >
              <Grid item xs="auto">
                <img
                  src={organDonor}
                  width={31}
                  height={60}
                  style={
                    subscriber?.organDonor
                      ? {}
                      : {
                          filter: "opacity(0.4)",
                        }
                  }
                />
              </Grid>
              <Grid item xs={6} sm={3} ml={1} justifyContent="end">
                <Typography
                  fontWeight={900}
                  fontSize={24}
                  sx={{
                    lineHeight: "25px",
                    "@media (max-width: 375px)": {
                      fontSize: "20px",
                    },
                    color: subscriber?.organDonor ? "#007036" : "#999999",
                  }}
                >
                  {subscriber?.organDonor ? "ORGAN DONOR" : "NOT A DONOR"}
                </Typography>
              </Grid>
            </Grid>
            {/* Blood type */}
            <Grid
              xs={6}
              sm={6}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={5} sm={2}>
                <MdBloodtype
                  style={{
                    fontSize: 70,
                    color: "#CE0000",
                  }}
                />
              </Grid>
              <Grid item xs={7} sm={3}>
                <Typography
                  fontWeight={600}
                  fontSize={17}
                  style={{ lineHeight: "14px", width: "140px" }}
                  align="left"
                >
                  BLOOD TYPE
                </Typography>
                <Typography
                  fontWeight={900}
                  fontSize={32}
                  sx={{
                    color: "#CE0000",
                    textAlign: "left",
                  }}
                >
                  {subscriber?.bloodType === "Unav"
                    ? "Unavailable"
                    : subscriber?.bloodType}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Divider
          sx={{
            color: "black",
            bgcolor: "black",
            width: "100%",
          }}
        />

        {/* MMH ID, Profile pic, Medication alerts, allergies section */}
        <Grid container direction="row" columnSpacing={4} rowSpacing={2} p={2}>
          {/* Profile pic */}
          <Grid item sm={12} md={5}>
            {/* Member id */}
            <Typography fontWeight={700} fontSize={25} lineHeight={1.5} pb={2}>
              <span
                style={{
                  color: "#000094",
                }}
              >
                MEMBER ID:
              </span>{" "}
              {subscriber?.accessCode}
            </Typography>

            <img
              src={
                Object.keys(avatar).length === 0 ? avatarPlaceholder : avatar
              }
              style={{
                objectFit: "cover",
                height: "28rem",
              }}
            />
          </Grid>

          {/* Medication alert, allergies section */}
          <Grid item sm={12} md={7}>
            {medication?.data.filter((medication) => medication.alert)
              .length ? (
              medication?.data
                .filter((medication) => medication.alert)
                .map((medication, index) => {
                  return (
                    <>
                      <Grid
                        key={medication.id}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        rowSpacing={1}
                        marginBottom="1rem"
                        marginTop="0.2rem"
                        width={isTablet ? "100%" : isMobile ? "80%" : "100%"}
                        flexDirection={
                          isTablet ? "column" : isMobile ? "row" : "column"
                        }
                      >
                        {index == 0 ? (
                          <>{getAlertTitle("MEDICATION ALERTS", "24rem")}</>
                        ) : (
                          ""
                        )}
                        <Grid
                          item
                          xs
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            marginLeft: "-0.5rem",
                          }}
                        >
                          <BsDot
                            size={40}
                            style={{ marginRight: "1rem", color: "red" }}
                          />
                          <div>
                            <Typography
                              fontWeight={700}
                              fontSize={23}
                              style={{ color: "red" }}
                            >
                              {medication.name}
                            </Typography>
                            {!medication.dose &&
                            !medication.doctor &&
                            !medication.frequency &&
                            !medication.pharmacy &&
                            !medication.pharmacy_number ? (
                              <Typography
                                fontWeight={500}
                                fontSize={15}
                                sx={{
                                  color: "#999999",
                                }}
                              >
                                No information provided
                              </Typography>
                            ) : (
                              <>
                                <Typography fontWeight={500} fontSize={16}>
                                  <span
                                    style={{
                                      color: "#555555",
                                    }}
                                  >
                                    Dose:{" "}
                                  </span>
                                  {medication.dose || "N/A"}
                                </Typography>
                                <Typography fontWeight={500} fontSize={16}>
                                  <span
                                    style={{
                                      color: "#555555",
                                    }}
                                  >
                                    Frequency:{" "}
                                  </span>
                                  {medication.frequency || "N/A"}
                                </Typography>
                                <Typography fontWeight={500} fontSize={16}>
                                  <span
                                    style={{
                                      color: "#555555",
                                    }}
                                  >
                                    Pharmacy:{" "}
                                  </span>
                                  {medication.pharmacy || "N/A"}
                                </Typography>
                                <Typography fontWeight={500} fontSize={16}>
                                  <span
                                    style={{
                                      color: "#555555",
                                    }}
                                  >
                                    Pharmacy Phone:{" "}
                                  </span>
                                  {medication.pharmacy_number
                                    .replace(/\D+/g, "")
                                    .replace(
                                      /(\d{3})(\d{3})(\d{4})/,
                                      "($1) $2-$3"
                                    ) || "N/A"}
                                </Typography>
                                <Typography fontWeight={500} fontSize={16}>
                                  <span
                                    style={{
                                      color: "#555555",
                                    }}
                                  >
                                    Prescribing Doctor:{" "}
                                  </span>
                                  {medication.doctor || "N/A"}
                                </Typography>
                              </>
                            )}
                          </div>
                        </Grid>
                      </Grid>

                      <Divider
                        sx={{
                          color: "black",
                          bgcolor: "black",
                          width: "100%",
                        }}
                      />
                    </>
                  );
                })
            ) : (
              <></>
            )}

            {getAllergiesBlock()}
          </Grid>
        </Grid>

        {/* Disabilities section */}
        <Divider
          sx={{
            color: "black",
            bgcolor: "black",
            width: "100%",
          }}
        />
        {getDisabilitiesBlock()}

        <Divider
          sx={{
            color: "black",
            bgcolor: "black",
            width: "100%",
          }}
        />
        {getHeightWeightEyeColorBlock()}

        <Divider
          sx={{
            color: "black",
            bgcolor: "black",
            width: "100%",
          }}
        />
        {getBirthMarksBock()}

        <Divider
          sx={{
            color: "black",
            bgcolor: "black",
            width: "100%",
          }}
        />
        {getSurgeriesBlock()}
        <Divider
          sx={{
            color: "black",
            bgcolor: "black",
            width: "100%",
          }}
        />

        {/* Emergency contact section */}
        <Box
          sx={{
            p: 2,
          }}
        >
          {/* Primary Emergency Contact */}
          <Typography
            fontWeight={700}
            fontSize={25}
            sx={{
              color: "#000094",
            }}
          >
            PRIMARY EMERGENCY CONTACT
          </Typography>
          {subscriber?.contactPhone1 ? (
            <>
              <Typography fontWeight={500} fontSize={28}>
                {`${subscriber?.contactFirstName1} ${subscriber?.contactLastName1}` ||
                  "No name"}
              </Typography>
              <Typography fontWeight={800} fontSize={23}>
                {`${subscriber?.contactPhone1
                  .replace(/\D+/g, "")
                  .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}` ||
                  "No phone number"}
              </Typography>
              <Typography
                fontWeight={800}
                fontSize={23}
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {`${subscriber?.contactEmail1}` || "No email information"}
              </Typography>
              {subscriber?.contactPhone1 && (
                <>
                  <Grid container direction="row" columnSpacing={1}>
                    <Grid item>
                      <Button
                        variant="contained"
                        startIcon={<LocalPhoneIcon />}
                        onClick={() =>
                          setCommsModal({
                            type: "tel",
                            open: true,
                            contact: subscriber?.contactPhone1,
                          })
                        }
                        sx={{
                          bgcolor: "#007036",
                          px: 2,
                          borderRadius: 2,
                          textTransform: "none",
                        }}
                      >
                        Phone Call
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        startIcon={<MessageIcon />}
                        onClick={() =>
                          setCommsModal({
                            type: "sms",
                            open: true,
                            contact: subscriber?.contactPhone1,
                          })
                        }
                        sx={{
                          bgcolor: "#000094",
                          px: 2,
                          borderRadius: 2,
                          textTransform: "none",
                        }}
                      >
                        Text Message
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    sx={{
                      bgcolor: "#FFF4EC",
                      padding: "16px",
                      gap: "8px",
                      borderRadius: "8px",
                      margin: "2rem 1rem 0 0",
                    }}
                  >
                    <Grid item>
                      <MdError fontSize={24} color="#B95000" />
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={16}
                        textAlign="left"
                        color="#B95000"
                        display="flex"
                        fontFamily="Source Sans Pro"
                      >
                        By using these functions above you may expose your caller ID
                        data.
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          ) : (
            <Typography
              fontWeight={700}
              fontSize={23}
              sx={{
                color: "#999999",
              }}
            >
              Not provided
            </Typography>
          )}
          <Divider
            sx={{
              color: "black",
              bgcolor: "black",
              width: "100%",
              my: 2.5,
            }}
          />
          {/* Secondary Emergency Contact */}
          <Typography
            fontWeight={700}
            fontSize={25}
            sx={{
              color: "#000094",
            }}
          >
            SECONDARY EMERGENCY CONTACT
          </Typography>
          {subscriber?.contactPhone2 ? (
            <>
              <Typography fontWeight={500} fontSize={28}>
                {`${subscriber?.contactFirstName2} ${subscriber?.contactLastName2}` ||
                  "No name"}
              </Typography>
              <Typography fontWeight={800} fontSize={23}>
                {`${subscriber?.contactPhone2
                  .replace(/\D+/g, "")
                  .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}` ||
                  "No phone number"}
              </Typography>
              <Typography
                fontWeight={800}
                fontSize={23}
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {`${subscriber?.contactEmail2}` || "No email information"}
              </Typography>
              {subscriber?.contactPhone2 && (
                <Grid container direction="row" columnSpacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      startIcon={<LocalPhoneIcon />}
                      onClick={() =>
                        setCommsModal({
                          type: "tel",
                          open: true,
                          contact: subscriber?.contactPhone2,
                        })
                      }
                      sx={{
                        bgcolor: "#007036",
                        px: 2,
                        borderRadius: 2,
                        textTransform: "none",
                      }}
                    >
                      Phone Call
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      startIcon={<MessageIcon />}
                      onClick={() =>
                        setCommsModal({
                          type: "sms",
                          open: true,
                          contact: subscriber?.contactPhone2,
                        })
                      }
                      sx={{
                        bgcolor: "#000094",
                        px: 2,
                        borderRadius: 2,
                        textTransform: "none",
                      }}
                    >
                      Text Message
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <Typography
              fontWeight={700}
              fontSize={23}
              sx={{
                color: "#999999",
              }}
            >
              Not provided
            </Typography>
          )}
        </Box>

        <Divider
          sx={{
            color: "black",
            bgcolor: "black",
            width: "100%",
          }}
        />

        {/* Vaccines and medical conditions section */}
        <Grid container direction="row" p={2}>
          {/* Vaccines section */}
          <Grid
            item
            xs={12}
            sm={6}
            mb={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              fontWeight={700}
              fontSize={25}
              sx={{
                color: "#000094",
              }}
            >
              VACCINATIONS
            </Typography>
            {!vaccine?.data.length ? (
              <Typography fontWeight={700} fontSize={23}>
                No vaccinations
              </Typography>
            ) : (
              vaccine?.data.map((vaccine) => {
                return (
                  <Grid
                    key={vaccine.id}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    rowSpacing={1}
                  >
                    <Grid item>
                      <BsDot size={40} />
                    </Grid>
                    <Grid item xs>
                      <Typography fontWeight={700} fontSize={23}>
                        {vaccine.name}
                      </Typography>
                      {!vaccine.dose &&
                      !vaccine.lastDose &&
                      !vaccine.booster ? (
                        <Typography
                          fontWeight={500}
                          fontSize={15}
                          sx={{
                            color: "#999999",
                          }}
                        >
                          No information provided
                        </Typography>
                      ) : (
                        <>
                          <Typography fontWeight={500} fontSize={16}>
                            <span
                              style={{
                                color: "#555555",
                              }}
                            >
                              # of Doses:{" "}
                            </span>
                            {vaccine.dose || "N/A"}
                          </Typography>
                          <Typography fontWeight={500} fontSize={16}>
                            <span
                              style={{
                                color: "#555555",
                              }}
                            >
                              Last Dose Date:{" "}
                            </span>
                            {vaccine.lastDose
                              ? new Date(vaccine.lastDose)
                                  .toISOString()
                                  .substr(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("/")
                              : "N/A"}
                          </Typography>
                          <Typography
                            fontWeight={500}
                            fontSize={16}
                            sx={{
                              color: "#DA1414",
                            }}
                          >
                            <span
                              style={{
                                color: "#555555",
                              }}
                            >
                              # of Boosters:{" "}
                            </span>
                            {vaccine.booster || "N/A"}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                );
              })
            )}
          </Grid>

          {/* Medical conditions section */}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              fontWeight={700}
              fontSize={25}
              sx={{
                color: "#000094",
              }}
            >
              MEDICAL CONDITIONS
            </Typography>
            {!medicalcondition?.data.length ? (
              <Typography fontWeight={700} fontSize={23}>
                Don&rsquo;t have any medical conditions
              </Typography>
            ) : (
              medicalcondition?.data.map((mc) => {
                return (
                  <Grid
                    key={mc?.id}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    rowSpacing={1}
                  >
                    <Grid item>
                      <BsDot size={40} />
                    </Grid>
                    <Grid item xs>
                      <Typography fontWeight={700} fontSize={23}>
                        {mc.clinicName}
                      </Typography>
                      {!mc.description ? (
                        <Typography
                          fontWeight={500}
                          fontSize={15}
                          sx={{
                            color: "#999999",
                          }}
                        >
                          No information provided
                        </Typography>
                      ) : (
                        <Typography fontWeight={500} fontSize={16}>
                          <span
                            style={{
                              color: "#555555",
                            }}
                          >
                            Advising Physician:{" "}
                          </span>
                          {mc.description || "N/A"}
                        </Typography>
                      )}
                      {mc.specificNeeds && (
                        <Typography
                          fontWeight={500}
                          fontSize={16}
                          align={"justify"}
                        >
                          <span
                            style={{
                              color: "#555555",
                            }}
                          >
                            Specific Needs:{" "}
                          </span>
                          {mc.specificNeeds || "N/A"}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                );
              })
            )}
          </Grid>
        </Grid>

        <Divider
          sx={{
            color: "black",
            bgcolor: "black",
            width: "100%",
          }}
        />

        {/* Medications and supplements section */}
        <Grid container direction="row" p={2}>
          {/* Medications section */}
          <Grid
            item
            xs={12}
            sm={6}
            mb={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              fontWeight={700}
              fontSize={25}
              sx={{
                color: "#000094",
              }}
            >
              MEDICATIONS
            </Typography>
            {!medication?.data.length ? (
              <Typography fontWeight={700} fontSize={23}>
                Don&rsquo;t take any medications
              </Typography>
            ) : (
              medication?.data.map((medication) => {
                return (
                  <Grid
                    key={medication?.id}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    rowSpacing={1}
                  >
                    <Grid item>
                      <BsDot size={40} />
                    </Grid>
                    <Grid item xs>
                      <Typography fontWeight={700} fontSize={23}>
                        {medication.name}
                      </Typography>
                      {!medication.dose &&
                      !medication.doctor &&
                      !medication.frequency &&
                      !medication.pharmacy &&
                      !medication.pharmacy_number ? (
                        <Typography
                          fontWeight={500}
                          fontSize={15}
                          sx={{
                            color: "#999999",
                          }}
                        >
                          No information provided
                        </Typography>
                      ) : (
                        <>
                          <Typography fontWeight={500} fontSize={16}>
                            <span
                              style={{
                                color: "#555555",
                              }}
                            >
                              Dose:{" "}
                            </span>
                            {medication.dose || "N/A"}
                          </Typography>
                          <Typography fontWeight={500} fontSize={16}>
                            <span
                              style={{
                                color: "#555555",
                              }}
                            >
                              Frequency:{" "}
                            </span>
                            {medication.frequency || "N/A"}
                          </Typography>
                          <Typography fontWeight={500} fontSize={16}>
                            <span
                              style={{
                                color: "#555555",
                              }}
                            >
                              Pharmacy:{" "}
                            </span>
                            {medication.pharmacy || "N/A"}
                          </Typography>
                          <Typography fontWeight={500} fontSize={16}>
                            <span
                              style={{
                                color: "#555555",
                              }}
                            >
                              Pharmacy Phone:{" "}
                            </span>
                            {medication.pharmacy_number
                              .replace(/\D+/g, "")
                              .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") ||
                              "N/A"}
                          </Typography>
                          <Typography fontWeight={500} fontSize={16}>
                            <span
                              style={{
                                color: "#555555",
                              }}
                            >
                              Prescribing Doctor:{" "}
                            </span>
                            {medication.doctor || "N/A"}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                );
              })
            )}
          </Grid>

          {/* Supplements section */}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              fontWeight={700}
              fontSize={25}
              sx={{
                color: "#000094",
              }}
            >
              SUPPLEMENTS
            </Typography>
            {!supplement?.data.length ? (
              <Typography fontWeight={700} fontSize={23}>
                Don&rsquo;t take any supplements
              </Typography>
            ) : (
              supplement?.data.map((supplement) => {
                return (
                  <Grid
                    key={supplement?.id}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    rowSpacing={1}
                  >
                    <Grid item>
                      <BsDot size={40} />
                    </Grid>
                    <Grid item xs>
                      <Typography fontWeight={700} fontSize={23}>
                        {supplement.name}
                      </Typography>
                      {!supplement.dose && !supplement.frequency ? (
                        <Typography
                          fontWeight={500}
                          fontSize={15}
                          sx={{
                            color: "#999999",
                          }}
                        >
                          No information provided
                        </Typography>
                      ) : (
                        <>
                          <Typography fontWeight={500} fontSize={16}>
                            <span
                              style={{
                                color: "#555555",
                              }}
                            >
                              Dose:{" "}
                            </span>
                            {supplement.dose || "N/A"}
                          </Typography>
                          <Typography fontWeight={500} fontSize={16}>
                            <span
                              style={{
                                color: "#555555",
                              }}
                            >
                              Frequency:{" "}
                            </span>
                            {supplement.frequency || "N/A"}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                );
              })
            )}
          </Grid>
        </Grid>

        <Divider
          sx={{
            color: "black",
            bgcolor: "black",
            width: "100%",
          }}
        />

        {/* Caregiver section */}
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            fontWeight={700}
            fontSize={25}
            sx={{
              color: "#000094",
            }}
          >
            CAREGIVER INFO
          </Typography>
          {(subscriber?.careGiver || subscriber?.hasCareGiver) && (
            <Box
              sx={{
                borderRadius: "8px",
                bgcolor: "#007036",
                padding: "8px 12px",
                color: "white",
                width: "fit-content",
                fontSize: "15px",
                fontWeight: 500,
              }}
            >
              I {subscriber?.careGiver ? "am" : "have"} a caregiver
            </Box>
          )}
          {(subscriber?.careCompany || subscriber?.hasCareGiverCompany) && (
            <Box
              sx={{
                borderRadius: "8px",
                bgcolor: "black",
                padding: "8px 12px",
                color: "white",
                width: "fit-content",
                fontSize: "15px",
                fontWeight: 500,
              }}
            >
              Work{subscriber?.careCompany ? "" : "s"} for a Home Care Company
            </Box>
          )}
          {subscriber?.contactPhone1 ? (
            <>
              <Typography fontWeight={500} fontSize={28}>
                {`${
                  subscriber?.caregiverFirstName ||
                  subscriber?.hasCaregiverFirstName
                } ${
                  subscriber?.caregiverLastName ||
                  subscriber?.hasCaregiverLastName
                }` || "No name"}
              </Typography>
              <Typography fontWeight={800} fontSize={23}>
                {subscriber?.caregiverPhone &&
                  `${subscriber?.caregiverPhone
                    .replace(/\D+/g, "")
                    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}`}
                {subscriber?.hasCaregiverPhone &&
                  `${subscriber?.hasCaregiverPhone
                    .replace(/\D+/g, "")
                    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}`}
                {!subscriber?.caregiverPhone &&
                  !subscriber?.hasCaregiverPhone &&
                  "No phone number"}
              </Typography>
              <Typography
                fontWeight={800}
                fontSize={23}
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {subscriber?.caregiverEmail || subscriber?.hasCaregiverEmail
                  ? `${
                      subscriber?.caregiverEmail ||
                      subscriber?.hasCaregiverEmail
                    }`
                  : "No email information"}
              </Typography>
            </>
          ) : (
            <Typography
              fontWeight={700}
              fontSize={23}
              sx={{
                color: "#999999",
              }}
            >
              Not provided
            </Typography>
          )}
          {!subscriber?.hasCareGiverCompany && !subscriber?.careCompany ? (
            <Typography
              fontWeight={500}
              fontSize={15}
              sx={{
                color: "#999999",
              }}
            >
              No information provided
            </Typography>
          ) : (
            <>
              <Typography fontWeight={500} fontSize={16}>
                <span
                  style={{
                    color: "#555555",
                  }}
                >
                  Company Name:{" "}
                </span>
                {subscriber?.careCompany
                  ? subscriber?.hasCaregiverCompanyName || "N/A"
                  : "N/A"}
              </Typography>
              <Typography fontWeight={500} fontSize={16}>
                <span
                  style={{
                    color: "#555555",
                  }}
                >
                  Company Address:{" "}
                </span>
                {subscriber?.careCompany
                  ? subscriber?.hasCaregiverCompanyAddress || "N/A"
                  : "N/A"}
              </Typography>
              <Typography fontWeight={500} fontSize={16}>
                <span
                  style={{
                    color: "#555555",
                  }}
                >
                  Company Phone:{" "}
                </span>
                {subscriber?.careCompany
                  ? (subscriber?.hasCaregiverCompanyPhone &&
                      subscriber?.hasCaregiverCompanyPhone.replace(
                        /\D+/g,
                        ""
                      )) ||
                    "N/A"
                  : "N/A"}
              </Typography>
              <Typography fontWeight={500} fontSize={16}>
                <span
                  style={{
                    color: "#555555",
                  }}
                >
                  Company Email:{" "}
                </span>
                {subscriber?.careCompany
                  ? subscriber?.hasCaregiverCompanyEmail || "N/A"
                  : "N/A"}
              </Typography>
            </>
          )}
          <Typography
            fontWeight={800}
            fontSize={23}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            Documents
          </Typography>
          {documents.filter((doc) => doc.option === "hasCaregiver").length ===
          0 ? (
            <Typography
              fontWeight={500}
              fontSize={15}
              sx={{
                color: "#999999",
              }}
            >
              No documents provided
            </Typography>
          ) : (
            documents
              .filter((doc) => doc.option === "hasCaregiver")
              .map((d) => (
                <>
                  <Typography fontWeight={500} fontSize={16}>
                    <span
                      style={{
                        color: "#555555",
                      }}
                    ></span>
                    {d.fileName}{" "}
                    <a
                      style={{
                        color: "#000094",
                        cursor: "pointer",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                      href={docsUrl.find((doc) => doc?.id === d?.id)?.url || ""}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                    </a>
                  </Typography>
                </>
              ))
          )}
        </Box>

        <Divider
          sx={{
            color: "black",
            bgcolor: "black",
            width: "100%",
          }}
        />

        {/* Primary Care Physician section */}
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            fontWeight={700}
            fontSize={25}
            sx={{
              color: "#000094",
            }}
          >
            PRIMARY CARE PHYSICIAN
          </Typography>

          {subscriber?.physicianFirstName ? (
            <>
              <Typography fontWeight={500} fontSize={28}>
                {subscriber?.physicianFirstName +
                  " " +
                  subscriber?.physicianLastName}
              </Typography>
              <Grid container alignItems="center" columnGap={2}>
                <Grid item columns={6}>
                  <Typography fontWeight={800} fontSize={23}>
                    {subscriber?.physicianPhone &&
                      `${subscriber?.physicianPhone
                        .replace(/\D+/g, "")
                        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}`}
                    {!subscriber?.physicianPhone && "No phone number"}
                  </Typography>
                </Grid>
                <Grid style={{ height: "1.5rem" }} item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item columns={6}>
                  <Typography
                    fontWeight={800}
                    fontSize={23}
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {subscriber?.physicianEmail
                      ? `${subscriber?.physicianEmail}`
                      : "No email information"}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography
              fontWeight={700}
              fontSize={23}
              sx={{
                color: "#999999",
              }}
            >
              Not provided
            </Typography>
          )}

          <Typography fontWeight={500} fontSize={16}>
            <span
              style={{
                color: "#555555",
              }}
            >
              Street Address:{" "}
            </span>
            {subscriber?.physicianAddress || "No information provided"}
          </Typography>

          <Typography fontWeight={500} fontSize={16}>
            <span
              style={{
                color: "#555555",
              }}
            >
              City:{" "}
            </span>
            {subscriber?.physicianCity || "No information provided"}
          </Typography>

          <Typography fontWeight={500} fontSize={16}>
            <span
              style={{
                color: "#555555",
              }}
            >
              State:{" "}
            </span>
            {subscriber?.physicianState || "No information provided"}
          </Typography>

          <Typography fontWeight={500} fontSize={16}>
            <span
              style={{
                color: "#555555",
              }}
            >
              Zip Code:{" "}
            </span>
            {subscriber?.physicianZip || "No information provided"}
          </Typography>

          <Typography
            fontWeight={800}
            fontSize={23}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            Documents
          </Typography>
          {documents.filter((doc) => doc.option === "care_physician").length ===
          0 ? (
            <Typography
              fontWeight={500}
              fontSize={15}
              sx={{
                color: "#999999",
              }}
            >
              No documents provided
            </Typography>
          ) : (
            documents
              .filter((doc) => doc.option === "care_physician")
              .map((d) => (
                <>
                  <Typography fontWeight={500} fontSize={16}>
                    <span
                      style={{
                        color: "#555555",
                      }}
                    ></span>
                    {d.fileName}
                    {"  "}
                    <a
                      style={{
                        color: "#000094",
                        cursor: "pointer",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                      href={docsUrl.find((doc) => doc?.id === d?.id)?.url || ""}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                    </a>
                  </Typography>
                </>
              ))
          )}
        </Box>

        <Divider
          sx={{
            color: "black",
            bgcolor: "black",
            width: "100%",
          }}
        />

        {/* Preferred hospital section */}
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            fontWeight={700}
            fontSize={25}
            sx={{
              color: "#000094",
            }}
          >
            PREFERRED HOSPITAL
          </Typography>
          {subscriber?.hospitalName ? (
            <>
              <Typography fontWeight={500} fontSize={28}>
                {subscriber?.hospitalName}
              </Typography>
              <Typography fontWeight={800} fontSize={23}>
                {subscriber?.hospitalPhone &&
                  `${subscriber?.hospitalPhone
                    .replace(/\D+/g, "")
                    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}`}
                {!subscriber?.hospitalPhone && "No phone number"}
              </Typography>
            </>
          ) : (
            <Typography
              fontWeight={700}
              fontSize={23}
              sx={{
                color: "#999999",
              }}
            >
              Not provided
            </Typography>
          )}
          {!subscriber?.hospitalAddress ? (
            <Typography
              fontWeight={500}
              fontSize={15}
              sx={{
                color: "#999999",
              }}
            >
              No information provided
            </Typography>
          ) : (
            <>
              <Typography fontWeight={500} fontSize={16}>
                <span
                  style={{
                    color: "#555555",
                  }}
                >
                  Address:{" "}
                </span>
                {subscriber?.hospitalAddress || "N/A"}
              </Typography>
            </>
          )}
        </Box>

        <Divider
          sx={{
            color: "black",
            bgcolor: "black",
            width: "100%",
          }}
        />

        {/* Pets section */}
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!pet?.data.length ? (
            <>
              <Typography
                fontWeight={700}
                fontSize={25}
                sx={{
                  color: "#000094",
                }}
              >
                PET CARE INFO #1
              </Typography>
              <Typography
                fontWeight={700}
                fontSize={23}
                sx={{
                  color: "#999999",
                }}
              >
                Not provided
              </Typography>
            </>
          ) : (
            pet?.data.map((_pet, index) => (
              <Fragment key={index}>
                <Typography
                  fontWeight={700}
                  fontSize={25}
                  sx={{
                    color: "#000094",
                  }}
                >
                  PET CARE INFO #{index + 1}
                </Typography>
                {/* Pet Info section */}
                <Typography
                  fontWeight={500}
                  fontSize={18}
                  sx={{
                    mt: 1,
                  }}
                >
                  Pet Info
                </Typography>
                <Typography fontWeight={500} fontSize={25}>
                  {_pet.name === "N/A" ? "Not provided" : _pet.name}
                </Typography>
                <Typography fontWeight={500} fontSize={16}>
                  <span
                    style={{
                      color: "#555555",
                    }}
                  >
                    Age:{" "}
                  </span>
                  {_pet.age ?? "Not provided"}
                </Typography>
                <Typography fontWeight={500} fontSize={16}>
                  <span
                    style={{
                      color: "#555555",
                    }}
                  >
                    Species:{" "}
                  </span>
                  {_pet.specie || "Not provided"}
                </Typography>
                <Typography fontWeight={500} fontSize={16}>
                  <span
                    style={{
                      color: "#555555",
                    }}
                  >
                    Breed:{" "}
                  </span>
                  {_pet.breed || "Not provided"}
                </Typography>
                {/* Pet Sitter info section */}
                <Typography
                  fontWeight={500}
                  fontSize={18}
                  sx={{
                    mt: 1,
                  }}
                >
                  Pet Sitter Information
                </Typography>
                <Typography fontWeight={500} fontSize={25}>
                  {_pet.petSitterName === "N/A"
                    ? "Not provided"
                    : _pet.petSitterName}
                </Typography>
                <Typography fontWeight={500} fontSize={16}>
                  <span
                    style={{
                      color: "#555555",
                    }}
                  >
                    Email:{" "}
                  </span>
                  {_pet.petSitterEmail || "Not provided"}
                </Typography>
                <Typography fontWeight={500} fontSize={16}>
                  <span
                    style={{
                      color: "#555555",
                    }}
                  >
                    Phone:{" "}
                  </span>
                  {_pet.petSitterPhone
                    ? _pet.petSitterPhone
                        .replace(/\D+/g, "")
                        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
                    : "Not provided"}
                </Typography>
                {/* Vet clinic section */}
                <Typography
                  fontWeight={500}
                  fontSize={18}
                  sx={{
                    mt: 1,
                  }}
                >
                  Vet Clinic
                </Typography>
                {_pet.vetClinicName && _pet.vetClinicName !== "N/A" ? (
                  <>
                    <Typography fontWeight={500} fontSize={25}>
                      {_pet.vetClinicName}
                    </Typography>
                    <Typography fontWeight={500} fontSize={16}>
                      <span
                        style={{
                          color: "#555555",
                        }}
                      >
                        Email:{" "}
                      </span>
                      {_pet.vetEmail || "Not provided"}
                    </Typography>
                    <Typography fontWeight={500} fontSize={16}>
                      <span
                        style={{
                          color: "#555555",
                        }}
                      >
                        Phone:{" "}
                      </span>
                      {_pet.vetPhone
                        ? _pet.vetPhone
                            .replace(/\D+/g, "")
                            .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
                        : "Not provided"}
                    </Typography>
                    <Typography fontWeight={500} fontSize={16}>
                      <span
                        style={{
                          color: "#555555",
                        }}
                      >
                        Address:{" "}
                      </span>
                      {_pet.vetAddress || "Not provided"}
                    </Typography>
                    <Typography fontWeight={500} fontSize={16}>
                      <span
                        style={{
                          color: "#555555",
                        }}
                      >
                        Doctor Name:{" "}
                      </span>
                      {_pet.vetDoctorName || "Not provided"}
                    </Typography>
                    <Typography fontWeight={500} fontSize={16}>
                      <span
                        style={{
                          color: "#555555",
                        }}
                      >
                        Additional Info/Special Medical Requirements:{" "}
                      </span>
                      {_pet.vetAdditionalInfo || "Not provided"}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    fontWeight={700}
                    fontSize={23}
                    sx={{
                      color: "#999999",
                    }}
                  >
                    Not provided
                  </Typography>
                )}
                {pet?.data.length - 1 !== index && (
                  <Divider
                    sx={{
                      color: "black",
                      bgcolor: "black",
                      width: "100%",
                      my: 2,
                    }}
                  />
                )}
              </Fragment>
            ))
          )}
        </Box>

        <Divider
          sx={{
            color: "black",
            bgcolor: "black",
            width: "100%",
          }}
        />

        {/* Documents section */}
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            fontWeight={700}
            fontSize={25}
            sx={{
              color: "#000094",
            }}
          >
            DOCUMENTS
          </Typography>
          {documents.length === 0 ? (
            <Typography
              fontWeight={700}
              fontSize={23}
              sx={{
                color: "#999999",
              }}
            >
              Not provided
            </Typography>
          ) : (
            <>
              {/* Health insurance section */}
              <Typography fontWeight={500} fontSize={25}>
                Health Insurance
              </Typography>
              {documents.filter(
                (d) => d.option === "health_insurance_back" && d.index === 0
              )[0] ? (
                <>
                  <Typography fontWeight={500} fontSize={16}>
                    <span
                      style={{
                        color: "#555555",
                      }}
                    >
                      Insurance Company Name:{" "}
                    </span>
                    {documents.filter(
                      (d) =>
                        d.option === "health_insurance_back" && d.index === 0
                    )[0].extraField1 || "Not provided"}
                  </Typography>
                  <Typography fontWeight={500} fontSize={16}>
                    <span
                      style={{
                        color: "#555555",
                      }}
                    >
                      Member ID Number:{" "}
                    </span>
                    {documents.filter(
                      (d) =>
                        d.option === "health_insurance_back" && d.index === 0
                    )[0].extraField2 || "Not provided"}
                  </Typography>
                  <Typography fontWeight={500} fontSize={16}>
                    <span
                      style={{
                        color: "#555555",
                      }}
                    >
                      Group Number:{" "}
                    </span>
                    {documents.filter(
                      (d) =>
                        d.option === "health_insurance_back" && d.index === 0
                    )[0].extraField3 || "Not provided"}
                  </Typography>
                  <Grid container direction="row" columnSpacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography fontWeight={500} fontSize={13}>
                        Front
                      </Typography>
                      {docsUrl.find(
                        (doc) =>
                          doc.id ===
                          documents.filter(
                            (d) =>
                              d.option === "health_insurance_front" &&
                              d.index === 0
                          )[0]?.id
                      )?.url ? (
                        !["pdf", "doc", "docx"].includes(
                          documents
                            .filter(
                              (d) =>
                                d.option === "health_insurance_front" &&
                                d.index === 0
                            )[0]
                            .fileName.split(".")
                            .pop()!
                        ) ? (
                          <img
                            src={
                              docsUrl.find(
                                (doc) =>
                                  doc.id ===
                                  documents.filter(
                                    (d) =>
                                      d.option === "health_insurance_front" &&
                                      d.index === 0
                                  )[0]?.id
                              )?.url || ""
                            }
                            style={{
                              padding: "8px",
                              border: "1px solid rgba(0, 0, 0, 0.08)",
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              padding: 1,
                              border: "1px solid rgba(0, 0, 0, 0.08)",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 200,
                              }}
                            >
                              <HiOutlineClipboardDocument
                                style={{
                                  marginTop: "70px",
                                  fontSize: 76,
                                }}
                              />
                              <Typography fontSize={15} fontWeight={500}>
                                {
                                  documents.filter(
                                    (d) =>
                                      d.option === "health_insurance_front" &&
                                      d.index === 0
                                  )[0].fileName
                                }
                              </Typography>
                              <a
                                style={{
                                  color: "#000094",
                                  cursor: "pointer",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                                href={
                                  docsUrl.find(
                                    (doc) =>
                                      doc.id ===
                                      documents.filter(
                                        (d) =>
                                          d.option ===
                                            "health_insurance_front" &&
                                          d.index === 0
                                      )[0]?.id
                                  )?.url || ""
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                View
                              </a>
                            </Box>
                          </Box>
                        )
                      ) : (
                        <Box
                          sx={{
                            padding: 1,
                            border: "1px solid rgba(0, 0, 0, 0.08)",
                          }}
                        >
                          <Box
                            sx={{
                              bgcolor: "#F2F4F8",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 200,
                            }}
                          >
                            <Typography
                              fontSize={15}
                              fontWeight={500}
                              sx={{
                                color: "#B4BCCB",
                              }}
                            >
                              No image to display
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography fontWeight={500} fontSize={13}>
                        Back
                      </Typography>
                      {docsUrl.find(
                        (doc) =>
                          doc?.id ===
                          documents.filter(
                            (d) =>
                              d.option === "health_insurance_back" &&
                              d.index === 0
                          )[0]?.id
                      )?.url ? (
                        !["pdf", "doc", "docx"].includes(
                          documents
                            .filter(
                              (d) =>
                                d.option === "health_insurance_back" &&
                                d.index === 0
                            )[0]
                            .fileName.split(".")
                            .pop()!
                        ) ? (
                          <img
                            src={
                              docsUrl.find(
                                (doc) =>
                                  doc?.id ===
                                  documents.filter(
                                    (d) =>
                                      d.option === "health_insurance_back" &&
                                      d.index === 0
                                  )[0]?.id
                              )?.url || ""
                            }
                            style={{
                              padding: "8px",
                              border: "1px solid rgba(0, 0, 0, 0.08)",
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              padding: 1,
                              border: "1px solid rgba(0, 0, 0, 0.08)",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 200,
                              }}
                            >
                              <HiOutlineClipboardDocument
                                style={{
                                  marginTop: "70px",
                                  fontSize: 76,
                                }}
                              />
                              <Typography fontSize={15} fontWeight={500}>
                                {
                                  documents.filter(
                                    (d) =>
                                      d.option === "health_insurance_back" &&
                                      d.index === 0
                                  )[0].fileName
                                }
                              </Typography>
                              <a
                                style={{
                                  color: "#000094",
                                  cursor: "pointer",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                                href={
                                  docsUrl.find(
                                    (doc) =>
                                      doc?.id ===
                                      documents.filter(
                                        (d) =>
                                          d.option ===
                                            "health_insurance_back" &&
                                          d.index === 0
                                      )[0]?.id
                                  )?.url || ""
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                View
                              </a>
                            </Box>
                          </Box>
                        )
                      ) : (
                        <Box
                          sx={{
                            padding: 1,
                            border: "1px solid rgba(0, 0, 0, 0.08)",
                          }}
                        >
                          <Box
                            sx={{
                              bgcolor: "#F2F4F8",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 200,
                            }}
                          >
                            <Typography
                              fontSize={15}
                              fontWeight={500}
                              sx={{
                                color: "#B4BCCB",
                              }}
                            >
                              No image to display
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Typography
                  fontWeight={700}
                  fontSize={23}
                  sx={{
                    color: "#999999",
                  }}
                >
                  Not provided
                </Typography>
              )}
              <Divider
                sx={{
                  color: "black",
                  bgcolor: "black",
                  width: "100%",
                  my: 2,
                }}
              />
              {/* Drivers License section */}
              <Typography fontWeight={500} fontSize={25}>
                Drivers License
              </Typography>
              {documents.filter(
                (d) => d.option === "driver_license" && d.index === 0
              )[0] ? (
                <>
                  <Grid container direction="row" columnSpacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography fontWeight={500} fontSize={13}>
                        Front
                      </Typography>
                      {docsUrl.find(
                        (doc) =>
                          doc.id ===
                          documents.filter(
                            (d) => d.option === "driver_license"
                          )[0].id
                      )?.url ? (
                        !["pdf", "doc", "docx"].includes(
                          documents
                            .filter(
                              (d) =>
                                d.option === "driver_license" && d.index === 0
                            )[0]
                            .fileName.split(".")
                            .pop()!
                        ) ? (
                          <img
                            src={
                              docsUrl.find(
                                (doc) =>
                                  doc?.id ===
                                  documents.filter(
                                    (d) =>
                                      d.option === "driver_license" &&
                                      d.index === 0
                                  )[0]?.id
                              )?.url || ""
                            }
                            style={{
                              padding: "8px",
                              border: "1px solid rgba(0, 0, 0, 0.08)",
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              padding: 1,
                              border: "1px solid rgba(0, 0, 0, 0.08)",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 200,
                              }}
                            >
                              <HiOutlineClipboardDocument
                                style={{
                                  marginTop: "70px",
                                  fontSize: 76,
                                }}
                              />
                              <Typography fontSize={15} fontWeight={500}>
                                {
                                  documents.filter(
                                    (d) =>
                                      d.option === "driver_license" &&
                                      d.index === 0
                                  )[0].fileName
                                }
                              </Typography>
                              <a
                                style={{
                                  color: "#000094",
                                  cursor: "pointer",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                                href={
                                  docsUrl.find(
                                    (doc) =>
                                      doc.id ===
                                      documents.filter(
                                        (d) =>
                                          d.option === "driver_license" &&
                                          d.index === 0
                                      )[0].id
                                  )?.url || ""
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                View
                              </a>
                            </Box>
                          </Box>
                        )
                      ) : (
                        <Box
                          sx={{
                            padding: 1,
                            border: "1px solid rgba(0, 0, 0, 0.08)",
                          }}
                        >
                          <Box
                            sx={{
                              bgcolor: "#F2F4F8",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 200,
                            }}
                          >
                            <Typography
                              fontSize={15}
                              fontWeight={500}
                              sx={{
                                color: "#B4BCCB",
                              }}
                            >
                              No image to display
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Typography
                  fontWeight={700}
                  fontSize={23}
                  sx={{
                    color: "#999999",
                  }}
                >
                  Not provided
                </Typography>
              )}
              <Divider
                sx={{
                  color: "black",
                  bgcolor: "black",
                  width: "100%",
                  my: 2,
                }}
              />
              {/* Additional documents section */}
              {documents.filter((d) =>
                ["health_insurance_front", "health_insurance_back"].includes(
                  d.option
                )
              ).length === 0 ? (
                <>
                  <Typography fontWeight={500} fontSize={25}>
                    Additional Document #1
                  </Typography>
                  <Typography
                    fontWeight={700}
                    fontSize={23}
                    sx={{
                      color: "#999999",
                    }}
                  >
                    Not provided
                  </Typography>
                </>
              ) : (
                <>
                  {Array.from({ length: maxIndex }, (_, i) => (
                    <Fragment key={i}>
                      <Typography fontWeight={500} fontSize={25}>
                        Additional Document #{i + 1}
                      </Typography>
                      <Grid container direction="row" columnSpacing={3}>
                        {documents
                          .filter(
                            (d) =>
                              ["health_insurance_front"].includes(d.option) &&
                              d.index === i + 1
                          )
                          .map((doc) => (
                            <>
                              <Grid item xs={12} sm={6}>
                                <Typography fontWeight={500} fontSize={13}>
                                  Front
                                </Typography>
                                {doc.option === "health_insurance_front" &&
                                docsUrl.find((d) => d.id === doc.id)?.url ? (
                                  !["pdf", "doc", "docx"].includes(
                                    doc?.fileName?.split(".")[
                                      // eslint-disable-next-line no-unexpected-multiline
                                      doc?.fileName?.split(".").length - 1
                                    ].toString()
                                  ) ? (
                                    <img
                                      src={
                                        docsUrl.find((d) => d.id === doc.id)
                                          ?.url || ""
                                      }
                                      style={{
                                        padding: "8px",
                                        border: "1px solid rgba(0, 0, 0, 0.08)",
                                        width: "100%",
                                      }}
                                    />
                                  ) : (
                                    <Box
                                      sx={{
                                        padding: 1,
                                        border: "1px solid rgba(0, 0, 0, 0.08)",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: 200,
                                        }}
                                      >
                                        <HiOutlineClipboardDocument
                                          style={{
                                            marginTop: "70px",
                                            fontSize: 76,
                                          }}
                                        />
                                        <Typography
                                          fontSize={15}
                                          fontWeight={500}
                                        >
                                          {doc.fileName}
                                        </Typography>
                                        <a
                                          style={{
                                            color: "#000094",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                          href={
                                            docsUrl.find((d) => d.id === doc.id)
                                              ?.url || ""
                                          }
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          View
                                        </a>
                                      </Box>
                                    </Box>
                                  )
                                ) : (
                                  <Box
                                    sx={{
                                      padding: 1,
                                      border: "1px solid rgba(0, 0, 0, 0.08)",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        bgcolor: "#F2F4F8",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: 200,
                                      }}
                                    >
                                      <Typography
                                        fontSize={15}
                                        fontWeight={500}
                                        sx={{
                                          color: "#B4BCCB",
                                        }}
                                      >
                                        No image to display
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}
                              </Grid>
                            </>
                          ))}
                        {documents
                          .filter(
                            (d) =>
                              ["health_insurance_back"].includes(d.option) &&
                              d.index === i + 1
                          )
                          .map((doc) => (
                            <>
                              <Grid item xs={12} sm={6}>
                                <Typography fontWeight={500} fontSize={13}>
                                  Back
                                </Typography>
                                {doc.option === "health_insurance_back" &&
                                docsUrl.find((d) => d?.id === doc?.id)?.url ? (
                                  !["pdf", "doc", "docx"].includes(
                                    doc?.fileName?.split(".")[
                                      // eslint-disable-next-line no-unexpected-multiline
                                      doc?.fileName?.split(".").length - 1
                                    ].toString()
                                  ) ? (
                                    <img
                                      src={
                                        docsUrl.find((d) => d?.id === doc?.id)
                                          ?.url || ""
                                      }
                                      style={{
                                        padding: "8px",
                                        border: "1px solid rgba(0, 0, 0, 0.08)",
                                        width: "100%",
                                      }}
                                    />
                                  ) : (
                                    <Box
                                      sx={{
                                        padding: 1,
                                        border: "1px solid rgba(0, 0, 0, 0.08)",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: 200,
                                        }}
                                      >
                                        <HiOutlineClipboardDocument
                                          style={{
                                            marginTop: "70px",
                                            fontSize: 76,
                                          }}
                                        />
                                        <Typography
                                          fontSize={15}
                                          fontWeight={500}
                                        >
                                          {doc.fileName}
                                        </Typography>
                                        <a
                                          style={{
                                            color: "#000094",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                          href={
                                            docsUrl.find(
                                              (d) => d?.id === doc?.id
                                            )?.url || ""
                                          }
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          View
                                        </a>
                                      </Box>
                                    </Box>
                                  )
                                ) : (
                                  <Box
                                    sx={{
                                      padding: 1,
                                      border: "1px solid rgba(0, 0, 0, 0.08)",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        bgcolor: "#F2F4F8",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: 200,
                                      }}
                                    >
                                      <Typography
                                        fontSize={15}
                                        fontWeight={500}
                                        sx={{
                                          color: "#B4BCCB",
                                        }}
                                      >
                                        No image to display
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}
                              </Grid>
                            </>
                          ))}
                      </Grid>
                    </Fragment>
                  ))}
                </>
              )}
            </>
          )}
        </Box>

        <Dialog
          open={commsModal.open}
          onClose={() =>
            setCommsModal({ type: null, open: false, contact: null })
          }
        >
          <Grid p={7} container direction="column" textAlign="center">
            <Grid item>
              <Typography color="#000094" fontSize={23} fontWeight="bold">
                This action may use your mobile data
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="#232D42" fontSize={13}>
                Data rates may apply according to your plan
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              sx={{
                bgcolor: "#FFF4EC",
                padding: "16px",
                gap: "8px",
                borderRadius: "8px",
                marginTop: "2rem",
              }}
            >
              <Grid item>
                <MdError fontSize={24} color="#B95000" />
              </Grid>
              <Grid item xs>
                <Typography
                  fontSize={16}
                  textAlign="left"
                  color="#B95000"
                  display="flex"
                  fontFamily="Source Sans Pro"
                >
                  By using this function you may expose your caller ID data.
                </Typography>
              </Grid>
            </Grid>
            <Grid mt={3} item container justifyContent="center" gap={2}>
              <Button
                variant="outlined"
                onClick={() =>
                  setCommsModal({ type: null, open: false, contact: null })
                }
                sx={{
                  px: 2,
                  fontWeight: "bold",
                  color: "#000094",
                  borderColor: "#000094",
                  borderRadius: 2,
                  borderWidth: 2,
                  textTransform: "none",
                  "&:hover": {
                    px: 2,
                    fontWeight: "bold",
                    color: "#000094",
                    borderColor: "#000094",
                    borderRadius: 2,
                    borderWidth: 2,
                    textTransform: "none",
                    opacity: 0.5,
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                href={`${commsModal.type}:${commsModal.contact}`}
                sx={{
                  bgcolor: "#000094",
                  px: 2,
                  fontWeight: "bold",
                  borderWidth: 2,
                  borderRadius: 2,
                  textTransform: "none",
                  "&:hover": {
                    bgcolor: "#000094",
                    px: 2,
                    fontWeight: "bold",
                    borderWidth: 2,
                    borderRadius: 2,
                    textTransform: "none",
                    color: "#ffff",
                    opacity: 0.5,
                  },
                }}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    </>
  );
};

export default FrPersonalInfo;
