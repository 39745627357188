import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useDocuments } from "../../../Hooks/useSuscribersService";
import { Subscriber } from "../../../Interfaces/subscriber";
import { MdError } from "react-icons/md";

interface Props {
  member: Subscriber;
  frId: string;
  scanMethod?: string;
  registerId?: any;
  outlined?: boolean;
  margin?: string;
  minWidth?: string;
  width?: string;
  score?: string;
}

const MemberCard: FC<Props> = ({
  member,
  scanMethod,
  registerId,
  outlined,
  margin,
  minWidth,
  width,
  score,
  frId,
}) => {
  const navigate = useNavigate();
  const { avatar } = useDocuments(member.id, "avatar");

  return (
    <Grid
      container
      direction="column"
      sx={{
        bgcolor: "#EDECF5",
        px: 2,
        py: 1.5,
        borderRadius: "12px",
        border: outlined ? "2px solid #000094" : "",
        cursor:
          member.subCognitoId ||
            member.contactFirstName1 ||
            member.status === "Invited"
            ? "pointer"
            : "default",
        gap: "10px",
        padding: "16px",
        margin: margin ? margin : "16px 16px",
      }}
      maxWidth={425}
      minWidth={minWidth ? minWidth : "22rem"}
      width={width ? width : "100%"}
      onClick={() => {
        //Invited status assigned to dealer subscriber, details user are visible
        if (
          member.subCognitoId ||
          member.contactFirstName1 ||
          member.status === "Invited"
        ) {
          navigate(`/subscriberInfo/details?mmh_id=${member.accessCode!}`, { state: { scanMethod, registerId } });
        }
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs container direction="row">
          <Grid item mr={2}>
            <img
              src={avatar || "/avatar_placeholder.png"}
              width={45}
              height={60}
              style={{
                objectFit: "cover",
                border: "2px solid #000094",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            />
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            justifyContent="space-around"
            alignItems="flex-start"
          >
            {!member.memberType && (
              <Grid item>
                <Typography
                  fontSize={10}
                  textAlign="left"
                  px={1}
                  sx={{
                    bgcolor: "#0048B2",
                    color: "white",
                    borderRadius: "2px",
                  }}
                >
                  Account Holder
                </Typography>
              </Grid>
            )}
            <Typography fontSize={23} fontWeight={600} color="#432C81">
              {member.firstName} {member.lastName}
            </Typography>
            <Grid item display="flex" flexDirection="row" gap={1}>
              <Typography
                fontSize={10}
                textAlign="left"
                px={1}
                sx={{
                  bgcolor:
                    member.memberType === "Child" ? "#298BD0" : "#000094",
                  color: "white",
                  borderRadius: "2px",
                }}
              >
                {member.memberType ?? "Adult"}
              </Typography>
              {score && (
                <Typography
                  fontSize={10}
                  textAlign="left"
                  px={1}
                  sx={{
                    bgcolor: "#287D3C",
                    color: "white",
                    borderRadius: "2px",
                    width: "5rem",
                  }}
                >
                  {`Match: ${score} `}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            color: member.subCognitoId ? "#000094" : "#A5ABB3",
          }}
        >
          <ArrowForwardIosIcon />
        </Grid>
      </Grid>
      {!member.subCognitoId &&
        member.status !== "Invited" &&
        !member.contactFirstName1 && (
          <Grid
            item
            container
            justifyContent="space-between"
            sx={{
              bgcolor: "#FFF4EC",
              padding: "16px",
              gap: "8px",
              borderRadius: "8px",
            }}
          >
            <Grid item>
              <MdError fontSize={24} color="#B95000" />
            </Grid>
            <Grid item xs>
              <Typography
                fontSize={16}
                textAlign="left"
                color="#B95000"
                display="flex"
                fontFamily="Source Sans Pro"
              >
                Please be advised that this account has not completed
                registration and contains no information or data at this time.
              </Typography>
            </Grid>
          </Grid>
        )}
    </Grid>
  );
};

export default MemberCard;
