import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import { useStepVerificationContext } from "../../contexts/StepVerificationContext";
import useAuthUserData from "../../Hooks/useAuthUserData";
import {
  updateFirstResponderbyId,
  usernameExists,
} from "../../Services/firstResponderService";
import EditClassField from "../EditClassField";

interface Props {
  open: boolean;
}

const EditProfileDialog: FC<Props> = ({ open }) => {
  const { user, loading: loadingA } = useAuthUserData();
  const { dialogStatus, isActive, handleClose, resetStatusDialog } =
    useStepVerificationContext();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [office, setOffice] = useState("");
  const [activeSessionLength, setActiveSessionLength] = useState(60);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setOffice(user.office);
      setZipCode(user.zipCode);
      setActiveSessionLength(user.activeSessionLength ?? 60);
      setCity(user.city);
      setState(user.state);
      setUsername(user.username);
    }
  }, [user]);

  const handleSubmit = async () => {
    if (
      !firstName ||
      !lastName ||
      !office ||
      !city ||
      !state ||
      !username ||
      !zipCode ||
      !activeSessionLength
    ) {
      setError("Please fill all the fields");
      return;
    }

    setLoading(true);
    const invalidUsername = await usernameExists(username);
    if (invalidUsername && username !== user.username) {
      setError("Username already exists");
      setLoading(false);
      return;
    }

    updateFirstResponderbyId(user.id, {
      firstName,
      lastName,
      office,
      activeSessionLength,
      city,
      zipCode,
      state,
      username,
    })
      .then((res: any) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((err: any) => {
        setLoading(false);
        setError(err.message);
      });
  };

  const handleZipChange = (value: string) => {
    if (value.length > 0) {
      setZipCode(new RegExp(/\d+/g).test(value) ? value : zipCode);
    } else {
      setZipCode("");
    }
  };

  const cleanForm = () => {
    setSuccess(false);
    setError(null);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        cleanForm();
      }}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          bgcolor: "white",
          color: "black",
        },
      }}
    >
      <Grid container direction="column" alignItems="center">
        <Grid item container p={2} direction="row-reverse">
          <IconButton
            onClick={() => {
              handleClose();
              cleanForm();
            }}
            sx={{ color: "#000094" }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
        {success ? (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            pt={3}
            px={8}
            pb={8}
          >
            <Grid item color="#86E0A7">
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: 76,
                }}
              />
            </Grid>
            <Grid item>
              <Typography color="#242627" fontSize={25} fontWeight={700}>
                Profile successfully updated
              </Typography>
            </Grid>
            <Grid item mt={0.5}>
              <Typography color="#384057" fontSize={16} fontWeight={400}>
                No further action required
              </Typography>
            </Grid>
            <Grid item mt={3}>
              <Button
                variant="outlined"
                sx={{
                  color: "#000094",
                  textTransform: "none",
                  border: "1px solid rgba(0, 0, 148, 0.4)",
                }}
                onClick={() => {
                  handleClose();
                  cleanForm();
                }}
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            direction="column"
            alignItems="left"
            px={8}
            pb={8}
            rowSpacing={3}
          >
            <Grid item display="flex">
              <AccountCircleOutlinedIcon
                sx={{
                  fontSize: 26,
                  color: "#000094",
                  ml: -4.3,
                  mr: 1,
                  mt: 0.7,
                }}
              />
              <Typography fontSize={25} fontWeight={700}>
                Edit Profile
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="row"
              columnSpacing={3}
              alignItems="stretch"
            >
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  First Name
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  Last Name
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              columnSpacing={3}
              alignItems="stretch"
            >
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  City
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
              <Grid container item xs={6} columnSpacing={3}>
                <Grid item xs={6}>
                  <Typography fontSize={13} fontWeight={600}>
                    State
                  </Typography>
                  <TextField
                    type="text"
                    variant="outlined"
                    placeholder="Required"
                    fullWidth
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize={13} fontWeight={600}>
                    Zip Code
                  </Typography>
                  <TextField
                    type="text"
                    variant="outlined"
                    placeholder="Required"
                    fullWidth
                    value={zipCode}
                    onChange={(e) => handleZipChange(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              columnSpacing={3}
              alignItems="stretch"
            >
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  Agency/Department
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={office}
                  onChange={(e) => setOffice(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <EditClassField
                  value={activeSessionLength}
                  setValue={setActiveSessionLength}
                  label="Agency Default Class"
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              columnSpacing={3}
              alignItems="stretch"
            >
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  Email
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#F0F0F0",
                    color: "black",
                    padding: "16px 16px 16px 18px",
                    borderRadius: "8px",
                  }}
                >
                  {user?.email}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={13} fontWeight={600}>
                  Username
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Required"
                  fullWidth
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={loading}
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: "#000094",
                  textTransform: "none",
                  padding: "8px 20px",
                  mr: 2,
                }}
                onClick={handleSubmit}
              >
                Update
              </LoadingButton>
            </Grid>
            {error && (
              <Grid item>
                <Typography color="red" fontSize={13} fontWeight={600}>
                  {error}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default EditProfileDialog;
