import LockOpenIcon from "@mui/icons-material/LockOpen";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import PrincipalLayout from "../../components/Common/PrincipalLayout";
import { CognitoAuthService } from "../../Services/CognitoAuthService";

const ResetPassword = () => {
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const cognitoAuthService = new CognitoAuthService();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const handleSubmit = () => {
    if (password.length < 8) {
      setError("Password must be at least 8 characters");
      return;
    }

    if (!password.match(/[A-Z]+/)) {
      setError("Password must contain at least one uppercase character");
      return;
    }

    if (!password.match(/\d+/)) {
      setError("Password must contain at least one number");
      return;
    }

    if (
      !password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
      )
    ) {
      setError("Password must contain at least one special character");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    setLoading(true);
    cognitoAuthService
      .confirmForgotPassword(
        String(params.get("email")?.replace(" ", "+")),
        verificationCode,
        password
      )
      .then(() => {
        try {
          // Handle success here
          console.log("Password changed successfully");
          // window.location.href = "/admin";
          setSuccess(true);
          setLoading(false);
        } catch (err) {
          console.log("Error in success block:", err);
          setLoading(false);
        }
      })
      .catch((error) => {
        try {
          // Handle error here
          console.log("Error from cognitoAuthService:", error);
          setError(error.message);
          setLoading(false);
        } catch (err) {
          console.log("Error in error block:", err);
          setLoading(false);
        }
      });
  };
  return (
    <PrincipalLayout revert>
      {success ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          direction="column"
          color="black"
          position="relative"
          top="35%"
          px={10}
        >
          <Grid item color="#86E0A7">
            <LockOpenIcon sx={{ fontSize: 100 }} />
          </Grid>
          <Grid item>
            <Typography fontSize={25} fontWeight={600}>
              Password Changed
            </Typography>
            <Typography fontSize={16} fontWeight={400}>
              Now you can log in with your new password
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                setSuccess(false);
                setPassword("");
                setConfirmPassword("");
                window.location.href = "/admin";
              }}
              sx={{
                py: 1.5,
                px: 3,
                textTransform: "none",
                fontWeight: 600,
                my: 2,
                color: "#000094",
                borderColor: "#000094",
                borderRadius: 2.4,
              }}
            >
              Back to Login
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          direction="column"
          color="black"
          position="relative"
          top="25%"
          px={20}
          rowSpacing={3}
        >
          <Grid item>
            <Typography fontSize={35} fontWeight={600}>
              Reset Password
            </Typography>
            <Typography fontSize={16} fontWeight={400}>
              Password must contain at least 8 characters, at least one capital
              letter, one number, and one special symbol
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontSize={13} fontWeight={700}>
              Verification Code
            </Typography>
            <TextField
              type="text"
              variant="outlined"
              placeholder="Required"
              fullWidth
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Typography fontSize={13} fontWeight={700}>
              New Password
            </Typography>
            <TextField
              type={showPassword ? "text" : "password"}
              variant="outlined"
              placeholder="Required"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        color: "#000094",
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Typography fontSize={13} fontWeight={700}>
              Confirm New Password
            </Typography>
            <TextField
              type={showConfirmPassword ? "text" : "password"}
              variant="outlined"
              placeholder="Required"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        color: "#000094",
                      }}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              sx={{
                py: 1.5,
                px: 3,
                textTransform: "none",
                my: 2,
                color: "#fff",
                backgroundColor: "#000094",
                borderRadius: 2,
              }}
            >
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Save New Password"
              )}
            </Button>
          </Grid>
          {error && (
            <Grid item>
              <Typography fontSize={16} fontWeight={400} color="#FF0000">
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </PrincipalLayout>
  );
};

export default ResetPassword;
