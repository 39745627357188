import { FC, useState } from "react"

import { FileUploader } from "react-drag-drop-files"

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined"
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"

import Stack from "@mui/material/Stack"
import AlertMessageErrorUpload from "../../../components/AlertMessage/AlertMessageErrorUpload"
import { StepProps } from "../util"
import sampleFile from "./../../../Assets/sample_file.xlsx"
interface Props extends StepProps {
  file: File | null
  setFile: (file: File | null) => void
}

const UploadFile: FC<Props> = ({ nextStep, prevStep, setFile, file }) => {
  const fileTypes = ["CSV", "XLSX", "XLS"]
  const [colorBackground, setColorBackground] = useState("#F8FAFF")
  const [borderColor, setBorderColor] = useState("dashed #000094")
  const [errorAlert, setErrorAlert] = useState("")

  const dragDesign = () => {
    return (
      <div style={{ paddingTop: "32px", marginBottom: "32px" }}>
        <Typography color="#242627" fontSize={19} fontWeight={600}>
          Drag and drop your file here or upload
        </Typography>
        <Typography color="#242627" fontSize={15} fontWeight={400}>
          Supported file formats: CSV, XLSX, XLS. Max file size: 20MB
        </Typography>
        <Button
          variant="outlined"
          component="label"
          sx={{
            color: "#000094",
            textTransform: "none",
            border: "1px solid rgba(0, 0, 148, 0.4)",
            mr: 2,
            borderRadius: "8px",
            mt: 2,
          }}
        >
          <strong>Upload File</strong>
        </Button>
      </div>
    )
  }

  function DragDrop() {
    const handleChange = (file: File) => {
      setFile(file)
      setColorBackground("#F3FCF6")
      setBorderColor("solid #C7F1D6")
      setErrorAlert("")
    }

    return (
      <>
        <FileUploader
          multiple={false}
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          children={dragDesign()}
          maxSize={20}
          onTypeError={(err: any) => {
            setBorderColor("dashed #FC6868")
            setColorBackground("#FFFAFA")
            setErrorAlert("invalideFile")
          }}
          onSizeError={(err: any) => {
            setBorderColor("dashed #FC6868")
            setColorBackground("#FFFAFA")
            setErrorAlert("invalideFile")
          }}
        />
      </>
    )
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="center"
      textAlign="center"
      bgcolor="#fff"
      sx={{ height: "100%" }}
    >
      {errorAlert && (
        <>
          <AlertMessageErrorUpload advice={errorAlert} />
        </>
      )}
      <Grid item mt={5.5} direction="row-reverse">
        <CloudUploadOutlinedIcon
          sx={{
            fontSize: 76,
          }}
        />
      </Grid>
      <Grid item>
        <Typography color="#242627" fontSize={25} fontWeight={700}>
          Upload a CSV file with users data
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="#242627" fontSize={16}>
          <strong>
            After uploading you&quot;ll be able to match the data from your file
            with the data we need to collect.
          </strong>
          <br />
          Make sure your file contains the following information: First Name,
          Last Name, Email Address, Agency Employee ID, Badge #, City, State.
        </Typography>
      </Grid>
      <Grid
        item
        width="80%"
        bgcolor={colorBackground}
        borderRadius="8px"
        border={`1px ${borderColor}`}
        mt={"32px"}
      >
        {file ? (
          <>
            <div style={{ paddingTop: "32px", marginBottom: "32px" }}>
              <Typography color="#242627" fontSize={19} fontWeight={600}>
                {file.name}
              </Typography>
              <Typography color="#242627" fontSize={15} fontWeight={400}>
                {(file.size / (1024 * 1024)).toFixed(3)} MB
              </Typography>
            </div>
          </>
        ) : (
          <>
            <DragDrop />
          </>
        )}
      </Grid>
      <Grid item my={4}>
        <Typography color="#242627" fontSize={19} fontWeight={600}>
          Need Help?
        </Typography>
        <Box
          display="flex"
          sx={{
            cursor: "pointer",
          }}
        >
          <Link
            href={sampleFile}
            download="sample_spreadsheet"
            sx={{ color: "#000094" }}
            underline="none"
          >
            <Stack direction="row" spacing={1}>
              <FileOpenOutlinedIcon sx={{ color: "#000094" }} />
              <Typography color="#000094" fontSize={15} fontWeight={500}>
                Download a sample spreadsheet
              </Typography>
            </Stack>
          </Link>
        </Box>
      </Grid>
      <Grid item width="80%">
        <Divider />
      </Grid>
      <Grid item my={4}>
        <Button
          variant="contained"
          sx={{
            color: "white",
            backgroundColor: "#000094",
            textTransform: "none",
            padding: "8px 20px",
            mr: 2,
            borderRadius: "8px",
          }}
          onClick={() => {
            if (file) {
              nextStep()
            } else {
              setErrorAlert("missingFile")
            }
          }}
        >
          Next Step
        </Button>
      </Grid>
    </Grid>
  )
}

export default UploadFile
