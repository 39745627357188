import { FC } from 'react'
import { Button, Dialog, styled as muStyled } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from "@mui/material/Typography"

import { useStepVerificationContext } from "../../contexts/StepVerificationContext"

interface Props {
  open: boolean
}

const SuccessfulPassChangeDialog: FC<Props> = ({ open }) => {
  const {
    handleClose,
  } = useStepVerificationContext();

  const StyledButton = muStyled(Button)({
    height: '48px',
    width: '67px',
    textTransform: 'none',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: "#00009466",
    color: '#000094',
    backgroundColor: "#FFF"
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose()
        // cleanForm()
      }}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          bgcolor: "white",
          color: "black",
        },
      }}
    >
      <div style={{ width: '100%', paddingBottom: '64px', paddingTop: '64px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <CheckCircleOutlineIcon style={{ color: '#86E0A7', fontSize: '63.33px' }} />
        </div>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Typography color="#242627" fontSize={25} fontWeight={700}>
            Password successfully changed
          </Typography>
        </div>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Typography color="#242627" fontSize={16} fontWeight={400}>
            Now you can log in with the new password
          </Typography>
        </div>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <StyledButton
            variant="contained"
            sx={{ mt: 2, mb: 1 }}
            disabled={false}
            onClick={() => { handleClose() }}>
            <Typography fontSize={15} fontWeight={600}>
              Ok
            </Typography>
          </StyledButton>
        </div>
      </div>
    </Dialog>
  )
}

export default SuccessfulPassChangeDialog