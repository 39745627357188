import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SUPPORT_PHONE_NUMBER } from "../../Helpers/Constants";

interface Props {
  advice: string;
  open: boolean;
}

const AlertMessageQRScan: FC<Props> = ({ advice, open }) => {
  const navigate = useNavigate();
  const [tittle, setTittle] = useState("");
  const [paragraphOne, setParagraphOne] = useState("");
  const [paragraphTwoP1, setParagraphTwoP1] = useState("");
  const [span1, setSpan1] = useState("");

  useEffect(() => {
    switch (advice) {
      case "subscriberNotFound":
        setTittle("We did not find any subscriber");
        setParagraphOne(
          `We did not find any subscriber associated with this QR code. Please check the code and try again.`
        );
        setParagraphTwoP1(
          `If this continues, please contact the MyMedHistory Help Team at `
        );
        setSpan1(`${SUPPORT_PHONE_NUMBER}`);
        break;
      case "subscriberNotValid":
        setTittle("This account is currently inactive");
        break;
      case "biometricErrorNotFound":
        setTittle("We did not find any subscriber");
        setParagraphOne(
          `MMH User not found. Please scan the QR code located on the Medical ID Card or Vehicle. If the issue persists, contact the MyMedHistory Help Team at `
        );
        setSpan1(`${SUPPORT_PHONE_NUMBER}`);
        break;
      default:
      case "error":
        setTittle("Something didn't work as expected");
        setParagraphOne(
          `Something didn't work when scanning the QR code. Please check the code and try again.`
        );
        setParagraphTwoP1(
          `If this continues, please contact the MyMedHistory Help Team at `
        );
        setSpan1(`${SUPPORT_PHONE_NUMBER}`);
        break;
    }
  }, []);

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "307px", // Set your width here
          },
        },
      }}
    >
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          py={"26px"}
          px={"20px"}
        >
          <Grid item color="#FF4747">
            <ErrorOutlineOutlinedIcon
              sx={{
                fontSize: 24,
              }}
            />
          </Grid>
          <Grid mt={"10px"}>
            <Grid item container px={2}>
              <Typography
                color="#000094"
                fontSize={23}
                fontWeight={600}
                fontFamily={"Source Sans Pro"}
                align="center"
              >
                {tittle}
              </Typography>
            </Grid>
            <Grid item mt={"24px"}>
              <Typography
                color="#232D42"
                fontSize={13}
                fontWeight={400}
                fontFamily={"Source Sans Pro"}
                align="center"
              >
                {paragraphOne}
              </Typography>
              <Typography
                color="#232D42"
                fontSize={13}
                fontWeight={400}
                fontFamily={"Source Sans Pro"}
                align="center"
              >
                {paragraphTwoP1}
                <a
                  href={"tel:" + span1}
                  style={{ fontWeight: "700", color: "#232D42" }}
                >
                  {span1}
                </a>
              </Typography>
            </Grid>
          </Grid>
          <Grid item mt={"24px"}>
            <LoadingButton
              loading={false}
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#000094",
                textTransform: "none",
                padding: "8px 20px",
              }}
              onClick={() => {
                navigate("/SearchSubscriber");
              }}
            >
              Try again
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AlertMessageQRScan;
