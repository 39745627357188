import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import SearchIcon from "@mui/icons-material/Search"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import AdminUsersTable from "../../components/AdminUsersTable"
import AlertMessage from "../../components/AlertMessage/AlertMessage"
import DashboardLayout from "../../components/Common/DashboardLayout"
import EditAdminUserDialog from "../../components/EditAdminUserDialog"
import useAuthGuard from "../../Hooks/useAuthGuard"
import { useFirstRespondersForAgency } from "../../Hooks/useFirstResponderService"

export const STATUSES = {
  owner: {
    label: "Owner",
    color: "#5891FF",
  },
  expired: {
    label: "Expired Users",
    color: "#FF8642",
  },
  invited: {
    label: "Invited Users",
    color: "#7B61FF",
  },
  active: {
    label: "Active Users",
    color: "#01BB4B",
  },
  blocked: {
    label: "Blocked Users",
    color: "#FC6868",
  },
}

export type StatusesType = keyof typeof STATUSES

const AdminUsers = () => {
  useAuthGuard({
    requiredAdmin: true,
  })
  const { firstResponders, loading } = useFirstRespondersForAgency()
  const [searchInput, setSearchInput] = useState("")
  const [openNewAdminUserDialog, setOpenNewAdminUserDialog] = useState(false)

  const filteredFirstResponders = firstResponders?.filter((fr) => {
    const searchableAttributes = ["firstName", "lastName", "email"] as const
    const isSearchInputMatched = searchableAttributes.reduce((acc, attr) => {
      return (
        acc ||
        fr[attr as typeof searchableAttributes[number]]
          ?.toLowerCase()
          .includes(searchInput.toLowerCase())
      )
    }, false)
    return isSearchInputMatched
  })

  const [search, setSearch] = useSearchParams()
  const [modalMessage, setModalMessage] = useState("")
  const [advice, setAdvice] = useState("")

  useEffect(() => {
    setAdvice(search.get("advice") || "")
    setModalMessage(search.get("message") || "")
  }, [search])

  return (
    <>
      <DashboardLayout page="adminUsers">
        {advice && advice != "" ? (
          <AlertMessage advice={advice} message={modalMessage} />
        ) : (
          ""
        )}
        <Grid container direction="column" rowSpacing={3} alignItems="stretch">
          {/* Actions and filter */}
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item>
              <TextField
                placeholder="Search..."
                size="small"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                sx={{
                  width: "200px",
                  color: "black",
                  backgroundColor: "white",
                  mx: 2,
                  "& fieldset": { border: "none" },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{
                          color: "#000094",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ style: { color: "black" }, color: "black" }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: "#000094",
                  textTransform: "none",
                }}
                onClick={() => setOpenNewAdminUserDialog(true)}
                startIcon={<AddCircleOutlineOutlinedIcon />}
              >
                Add Admin User
              </Button>
            </Grid>
          </Grid>
          {/* Table */}
          <Grid item>
            <AdminUsersTable
              users={filteredFirstResponders.filter(
                (fr) => fr.rol === "firstResponderAdmin"
              )}
              isloading={!!loading}
            />
          </Grid>
        </Grid>
      </DashboardLayout>
      <EditAdminUserDialog
        open={openNewAdminUserDialog}
        handleClose={() => setOpenNewAdminUserDialog(false)}
      />
    </>
  )
}

export default AdminUsers
