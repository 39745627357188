import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
import Button from "@mui/material/Button"
import { FC, useState } from "react"
import DeleteUsersDialog from "../../DeleteUsersDialog"
import UnblockDialog from "../../UnblockDialog"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ActionProps } from "../utils"

const Blocked: FC<ActionProps> = ({ users, isSelection }) => {
  const [openDialog, setOpenDialog] = useState<
    "unblock" | "delete" | null
  >(null)

  return (
    <>
      <Button
          variant="outlined"
          sx={{
            color: "#000094",
            textTransform: "none",
            border: "1px solid rgba(0, 0, 148, 0.4)",
          }}
          startIcon={<CheckCircleOutlineIcon />}
          onClick={() => setOpenDialog("unblock")}
        >
          Unblock All Listed ({users.length})
        </Button>
        <Button
            variant="outlined"
            sx={{
              color: "#000094",
              textTransform: "none",
              border: "1px solid rgba(0, 0, 148, 0.4)",
            }}
            startIcon={<DeleteOutlineOutlinedIcon />}
            onClick={() => setOpenDialog("delete")}
          >
            Delete Selected ({users.length})
        </Button>
      <UnblockDialog
        open={openDialog !== null && openDialog !== "delete"}
        users={users}
        handleClose={() => setOpenDialog(null)}
        bulk={openDialog === "unblock"}
      />
      <DeleteUsersDialog
        open={openDialog === "delete"}
        users={users}
        handleClose={() => setOpenDialog(null)}
      />
    </>
  )
}

export default Blocked
