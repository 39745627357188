import { Box, Button, Grid, styled as muStyled } from "@mui/material";

import XIco from "../../Assets/ico-x.svg";
import useAuthGuard from "../../Hooks/useAuthGuard";

import "./FaceScanner.css";
import Webcam from "react-webcam";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router";
import { useRef } from "react";
import {
  getFamilyHolder,
  searchUsersByImage,
} from "../../Services/userService";

const FaceScanner = ({ setLoadingData, setError }: any) => {
  useAuthGuard({
    requiredAdmin: false,
  });

  const navigate = useNavigate();
  const webcamRef = useRef<any>(null);

  const videoConstraints = {
    facingMode: "environment",
  };

  const StyledButtonQr = muStyled(Button)({
    width: 50,
    height: 50,
    borderRadius: "50%",
    backgroundColor: "#fff",
    padding: "30px 30px",
    border: "none",
  });

  const capture = async () => {
    try {
      setLoadingData(true);
      let url = "";
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        const base64Image = imageSrc.split(",")[1];
        const faces: any = await searchUsersByImage(base64Image);
        console.log("🍉 ~ capture ~ faces:", faces);
        if (faces.primaryUser) {
          const family = await getFamilyHolder(faces.primaryUser.id);
          console.log("🍉 ~ capture ~ family:", family);
          if (family.length > 0)
            //Family group  page
            url = `/subscriberInfo?mmh_id=MMH${faces.primaryUser.accessCode}`;
          //Profile page
          else
            url = `/subscriberInfo/details?mmh_id=${faces.primaryUser.accessCode}`;
          navigate(url, { state: { scanMethod: "biometric" } });
        } else if (faces.alternativeUsers) {
          navigate("/alternativeFaces", { state: { ...faces.alternativeUsers, scanMethod: "biometric" } });
        } else {
          setError(true);
        }
      }
    } catch (error) {
      console.log("🍉 ~ capture ~ error:", error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        p={2}
        style={{}}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={2}
      >
        <Webcam
          forceScreenshotSourceSize
          style={{
            width: "100%",
            height: "90%",
            top: 0,
            left: 0,
            boxSizing: "border-box",
            maxHeight: "500px",
          }}
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          screenshotQuality={0.7}
        />
        <LoadingButton
          loading={false}
          variant="contained"
          sx={{
            color: "white",
            backgroundColor: "#000094",
            textTransform: "none",
            padding: "8px 20px",
            width: "25rem",
            height: "3.5rem",
            fontSize: "1.5rem",
          }}
          onClick={capture}
        >
          Capture
        </LoadingButton>
        <div className="qr-close">
          <Box>
            <StyledButtonQr
              variant="contained"
              onClick={() => navigate("/SearchSubscriber")}
            >
              <img src={XIco} alt="logo QR" className="logo-qr" />
            </StyledButtonQr>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default FaceScanner;
