import { FC, ReactElement } from "react";
import logo2 from "../../Assets/logo2.png";
import "./style.css";

export const Logo: FC = (): ReactElement => {
  return (
    <div className="logo-container">
      <div className="container-bg">
        <div className="container-content">
          <img src={logo2} alt="logo" />
          <p
            className="mt-4"
            style={{
              fontSize: "36px",
              fontWeight: "700",
              marginTop: "5px",
              marginBottom: "1px",
            }}
          >
            MyMedHistory
          </p>
          <p>First Responder Admin</p>
        </div>
        <span className="curve"></span>
      </div>
    </div>
  );
};
