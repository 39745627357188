import { FC, useState, useEffect } from 'react'
import { Button, ButtonProps, Box, Dialog, TextField, Grid, IconButton, InputAdornment, styled as muStyled } from '@mui/material';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Input from "@mui/material/Input"
import Typography from "@mui/material/Typography"
import styles from "./ChangePassword.module.css"
import { Form, Header } from "semantic-ui-react"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { CognitoAuthService } from "../../Services/CognitoAuthService";

import { useStepVerificationContext } from "../../contexts/StepVerificationContext"

interface Props {
  open: boolean
}

const ChangePasswordDialog: FC<Props> = ({ open }) => {
  const {
    handleClose,
    setDialogToOpen,
  } = useStepVerificationContext();

  const [oldPassword, setOldPassword] = useState({ value: "", showPassword: false })
  const [newPassword, setNewPassword] = useState({ value: "", showPassword: false })
  const [confirmationNewPassword, setConfirmationNewPassword] = useState({ value: "", showPassword: false })
  const [userEmail, setUserEmail] = useState('')
  const [oldPasswordVer, setOldPasswordVer] = useState(true)
  const [newPasswordVer, setNewPasswordVer] = useState(true)
  const [specialCharVer, setSpecialCharVer] = useState(true)
  const [success, setSuccess] = useState(false)

  const cognitoAuthService = new CognitoAuthService();

  useEffect(() => {
    if (newPassword.value === confirmationNewPassword.value) setNewPasswordVer(true)
    else setNewPasswordVer(false)
  }, [newPassword, confirmationNewPassword])

  useEffect(() => {
    async function getSessionData() {
      const request: any = await cognitoAuthService.getCurrentAuthenticatedUser();
      setUserEmail(request.attributes.email)
    }
    getSessionData()
  }, [])


  const handleLogin = async () => {
    try {
      await cognitoAuthService.signIn(userEmail, oldPassword.value)
      return true
    }
    catch (err) {
      return false
    }
  }

  const handleCleanForm = async () => {
    setOldPassword({ value: "", showPassword: false })
    setNewPassword({ value: "", showPassword: false })
    setConfirmationNewPassword({ value: "", showPassword: false })
    setOldPasswordVer(true)
    setNewPasswordVer(true)
    setSuccess(false)
  }

  const handleCloseDialog = async () => {
    handleCleanForm()
    handleClose()
  }

  const handlePasswordChange = async () => {
      const userLogin: any = await handleLogin()
      try {
        if (userLogin && specialCharVer) {
          await cognitoAuthService.changePasswordUser(oldPassword.value, newPassword.value)
          setSuccess(true)
          handleCleanForm()
          setDialogToOpen('successful-change-password')
        }
        else {
          setOldPasswordVer(false)
        }
      }
      catch (err) {
        console.log(err)
        setSuccess(false)
      }
    }

  const handleClickShowOldPassword = () => {
    setOldPasswordVer(true)
    setOldPassword({
      ...oldPassword,
      showPassword: !oldPassword.showPassword,
    });
  };

  const handleClickShowNewPassword = () => {
    setNewPassword({
      ...newPassword,
      showPassword: !newPassword.showPassword,
    });
  };

  const handleClickShowConfNewPassword = () => {
    setConfirmationNewPassword({
      ...confirmationNewPassword,
      showPassword: !confirmationNewPassword.showPassword,
    });
  };

  const validatePassword = (password: string) => {
    const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    if (!password || password.length === 0 || password.length < 8) {
      setSpecialCharVer(false)
    }
    if (pattern.test(password)) {
      setSpecialCharVer(true)
    } else {
      setSpecialCharVer(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        // cleanForm()
      }}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          bgcolor: "white",
          color: "black",
        },
      }}
    >

      <Form
        autoComplete="off"
        className={styles.inputForm}
        onSubmit={() => handlePasswordChange()}
      >
        <Grid container direction="column" alignItems="center">
          <Grid item container p={2} direction="row-reverse">
            <IconButton
              onClick={() => {
                handleCloseDialog()
              }}
              sx={{ color: "#000094" }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
          <div style={{ width: '100%', paddingBottom: '64px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%', paddingLeft: '70.33px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <LockOutlinedIcon style={{ color: '#000094', fontSize: '28px' }} />
              </div>
              <div style={{ marginLeft: '13.33px' }}>
                <Typography color="#242627" fontSize={25} fontWeight={700}>
                  Change Password
                </Typography>
              </div>
            </div>
            {/* <Grid item container p={2} direction="row">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', paddingLeft: '100px' }}>
                <Typography fontSize={13} fontWeight={700}>
                  Old Password
                </Typography>
                <TextField
                  type={oldPassword.showPassword ? "text" : "password"}
                  variant="outlined"
                  placeholder="Required"
                  style={{ width: 328, borderRadius: 8 }}
                  value={oldPassword.value}
                  onChange={(e) => {
                    setOldPassword({
                      ...oldPassword,
                      value: e.target.value,
                    })
                    setOldPasswordVer(true)
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowOldPassword}
                        edge="end"
                      >
                        {oldPassword.showPassword ? <VisibilityOffOutlinedIcon style={{ color: '#000094' }} /> : <VisibilityOutlinedIcon style={{ color: '#000094' }} />}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
                {!oldPasswordVer ? <Typography color="#FF627E" fontSize={13} fontWeight={400}>
                  Invalid Password
                </Typography> : null}
              </div>
            </Grid> */}

            <Grid item container p={2} direction="row">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end', width: '100%', paddingLeft: '100px' }}>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%', paddingLeft: '100px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%' }}>
                    <label className={styles.labelFields}>Old Password</label>
                    <Input
                      name="oldpassword"
                      type={oldPassword.showPassword ? "text" : "password"}
                      className={styles.passwordField}
                      inputProps={{
                        style: {
                          fontSize: "1.28571429em",
                          borderRight: "none",
                          borderTop: "none",
                          borderBottom: "none",
                        },
                      }}
                      placeholder="Required"
                      onChange={(e) => {
                        setOldPasswordVer(true)
                        setOldPassword({
                          ...oldPassword,
                          value: e.target.value,
                        })
                      }}
                      value={oldPassword.value}
                      autoComplete="off"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowOldPassword}
                          // onMouseDown={handleMouseDownPassword}
                          >
                            {oldPassword.showPassword ? (
                              <VisibilityOffOutlinedIcon style={{ color: "#000094" }} />
                            ) : (
                              <VisibilityOutlinedIcon style={{ color: "#000094" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      required
                    />
                    {!oldPasswordVer ? <Typography color="#FF627E" fontSize={13} fontWeight={400}>
                      Invalid Password
                    </Typography> : null}
                  </div>
                </div>
              </div>
            </Grid>
            
            {/* <Grid item container p={2} direction="row">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end', width: '100%', paddingLeft: '100px' }}>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%', paddingLeft: '100px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Typography fontSize={13} fontWeight={700}>
                      New Password
                    </Typography>
                    <TextField
                      type={newPassword.showPassword ? "text" : "password"}
                      variant="outlined"
                      placeholder="Required"
                      style={{ width: 328, borderRadius: 8, marginBottom: 0 }}
                      value={newPassword.value}
                      onChange={(e) => setNewPassword({
                        ...newPassword,
                        value: e.target.value,
                      })}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            edge="end"
                          >
                            {newPassword.showPassword ? <VisibilityOffOutlinedIcon style={{ color: '#000094' }} /> : <VisibilityOutlinedIcon style={{ color: '#000094' }} />}
                          </IconButton>
                        </InputAdornment>
                      }}
                    />
                    {(!newPasswordVer && newPassword.value.length > 0 && confirmationNewPassword.value.length > 0) ? <Typography color="#FF627E" fontSize={13} fontWeight={400} marginTop={"4px"}>
                      Passwords do not match
                    </Typography> : null}
                  </div>
                  <div style={{ marginLeft: '32px', marginRight: '64px', paddingTop: '18px' }}>
                    <Typography color="#384057" fontSize={16} fontWeight={400}>
                      Password must contain at least 8 characters, at least one capital letter, one number, and one special symbol
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item container p={2} direction="row">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', paddingLeft: '100px' }}>
                <Typography fontSize={13} fontWeight={700}>
                  Confirm New Password
                </Typography>
                <TextField
                  type={confirmationNewPassword.showPassword ? "text" : "password"}
                  variant="outlined"
                  placeholder="Required"
                  style={{ width: 328, borderRadius: 8 }}
                  value={confirmationNewPassword.value}
                  onChange={(e) => setConfirmationNewPassword({
                    ...confirmationNewPassword,
                    value: e.target.value,
                  })}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfNewPassword}
                        edge="end"
                      >
                        {confirmationNewPassword.showPassword ? <VisibilityOffOutlinedIcon style={{ color: '#000094' }} /> : <VisibilityOutlinedIcon style={{ color: '#000094' }} />}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
                {(!newPasswordVer && newPassword.value.length > 0 && confirmationNewPassword.value.length > 0) ? <Typography color="#FF627E" fontSize={13} fontWeight={400}>
                  Passwords do not match
                </Typography> : null}
              </div>
            </Grid> */}
            <Grid item container p={2} direction="row">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end', width: '100%', paddingLeft: '100px' }}>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%', paddingLeft: '100px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%' }}>
                    <label className={styles.labelFields}>New Password</label>
                    <Input
                      name="password"
                      type={newPassword.showPassword ? "text" : "password"}
                      className={styles.passwordField}
                      inputProps={{
                        style: {
                          fontSize: "1.28571429em",
                          borderRight: "none",
                          borderTop: "none",
                          borderBottom: "none",
                        },
                      }}
                      placeholder="Required"
                      onChange={(e) => {
                        validatePassword(e.target.value)
                        setNewPassword({
                          ...newPassword,
                          value: e.target.value,
                        })
                      }}
                      value={newPassword.value}
                      autoComplete="off"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowNewPassword}
                          // onMouseDown={handleMouseDownPassword}
                          >
                            {newPassword.showPassword ? (
                              <VisibilityOffOutlinedIcon style={{ color: "#000094" }} />
                            ) : (
                              <VisibilityOutlinedIcon style={{ color: "#000094" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      required
                    />
                    {(!newPasswordVer && newPassword.value.length > 0 && confirmationNewPassword.value.length > 0) ? <Typography color="#FF627E" fontSize={13} fontWeight={400} marginTop={"4px"}>
                      Passwords do not match
                    </Typography> : !specialCharVer ? <Typography color="#FF627E" fontSize={13} fontWeight={400} marginTop={"4px"}>
                        {`Password do not meet the requirements`}
                    </Typography> : <Typography color="transparent" fontSize={13} fontWeight={400}> - </Typography>}
                  </div>
                  <div className={styles.passwordRequirements}>
                    <Typography color="#384057" fontSize={16} fontWeight={400}>
                      Password must contain at least 8 characters, at least one capital letter, one number, and one special symbol
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item container p={2} direction="row">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', paddingLeft: '100px' }}>
                <label className={styles.labelFields}>Confirm New Password</label>
                <Input
                  name="confirmpassword"
                  // style={{ width: 328, borderRadius: 8, marginBottom: 0 }}
                  className={styles.passwordField}
                  type={confirmationNewPassword.showPassword ? "text" : "password"}
                  onChange={(e) => setConfirmationNewPassword({
                    ...confirmationNewPassword,
                    value: e.target.value,
                  })}
                  placeholder="Required"
                  inputProps={{
                    style: {
                      fontSize: "1.28571429em",
                      borderRight: "none",
                      borderTop: "none",
                      borderBottom: "none",
                    },
                  }}
                  value={confirmationNewPassword.value}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfNewPassword}
                      // onMouseDown={handleMouseDownPassword}
                      >
                        {confirmationNewPassword.showPassword ? (
                          <VisibilityOffOutlinedIcon style={{ color: "#000094" }} />
                        ) : (
                          <VisibilityOutlinedIcon style={{ color: "#000094" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  required
                />
                {(!newPasswordVer && newPassword.value.length > 0 && confirmationNewPassword.value.length > 0) ? <Typography color="#FF627E" fontSize={13} fontWeight={400}>
                  Passwords do not match
                </Typography> : <Typography color="transparent" fontSize={13} fontWeight={400}> - </Typography>}
              </div>
            </Grid>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', paddingLeft: '117px' }}>
            <Form.Button
                className={styles.confirmButtonStyle}
                type="submit"
                // onClick={() => handlePasswordChange()}
                style={{
                  width: '176px',
                  // marginLeft: '100px',
                  height: '48px',
                  textTransform: 'none',
                  fontSize: '1rem',
                  color: '#FFF',
                  borderRadius: '8px',
                  backgroundColor: '#000094'
                }}
              >
                Change Password
              </Form.Button>
            </div>
          </div>
        </Grid>
      </Form>
    </Dialog>
  )
}

export default ChangePasswordDialog