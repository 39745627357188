import { Scan } from "../Hooks/useScans"
import { SCANS_API } from "./CONSTANTS"
import customAxios from "./customAxios"

export const registerScan = (
  firstResponderId: string,
  scan: Pick<Scan, "method" | "idSubscriber" | "type">
) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .post(`${SCANS_API}${firstResponderId}`, scan)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    } catch (error) {
      reject(error)
    }
  })
}

export const updateScanRegister = (frID: string, registerId: string, scanType: string, method: string) => {

  return new Promise((resolve, reject) => {
    try {
      customAxios
        .patch(`${SCANS_API}${frID}/${registerId}`, {
          type: scanType,
          method
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    } catch (error) {
      reject(error)
    }
  })
}

export const getScans = (firstResponderId: string) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(`${SCANS_API}${firstResponderId}?limit=10000&sort=createdAt,DESC`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    } catch (error) {
      reject(error)
    }
  })
}

export const updateScanById = (
  firstResponderId: string,
  scanId: string,
  prms: Scan
) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .put(`${SCANS_API}${firstResponderId}/${scanId}`, prms)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    } catch (error) {
      reject(error)
    }
  })
}

export const deleteScanById = (firstResponderId: string, scanId: string) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .delete(`${SCANS_API}${firstResponderId}/${scanId}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    } catch (error) {
      reject(error)
    }
  })
}
