import { FC, useState } from "react"

import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { Stack, styled as muStyled, TextField } from "@mui/material"
import Box from '@mui/material/Box';
import LoadingButton from "@mui/lab/LoadingButton"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

import { updateFirstResponderbyId } from "../../Services/firstResponderService";
import { sendVerificationCodeMFA } from "../../Services/emailService";
import { useStepVerificationContext } from "../../contexts/StepVerificationContext";

const StepVerificationInputPhone: FC = () => {
  const { userInfo } = useStepVerificationContext();
  const { control, formState: { errors }, handleSubmit } = useForm();
  // const {  handleClickButton } = useSendCode()

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState({
    success: false,
    error: false,
  });

  const onSubmit: SubmitHandler<any> = async ({ email }) => {
    setIsLoading(true)
    const customCode = Math.floor(10000 + Math.random() * 90000);
    await updateFirstResponderbyId(userInfo.id, {
      mfaCode: String(customCode),
    })
      .then(async (res: any) => {
        await sendVerificationCodeMFA(
          email,
          String(customCode)
        ).then(async (res: any) => {
          setStatus({
            success: true,
            error: false,
          });
        });
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setIsLoading(false);
        setStatus({
          success: false,
          error: true,
        });
      });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid item mt={2} ml={6}>
        <Typography color="#242627" fontSize={16} fontWeight={700}>
          1. Verify your email
        </Typography>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="flex-start"
          spacing={1}
          mt={1}
        >
          <Controller
            name="email"
            control={control}
            defaultValue={userInfo.email}
            render={({ field }) =>
              <TextField
                {...field}
                fullWidth
                name="email"
                id="email"
                autoFocus
                placeholder="email@mail.com"
                type="email"
                sx={{
                  width: 328,
                  height: 56,
                  borderRadius: 8,
                }}

              />
            }
            rules={{
              required: true,
            }}
          />
          <LoadingButton
            loading={isLoading}
            variant="outlined"
            sx={{
              paddingY: 1.6,
              paddingX: 3,
              color: "#000094",
              textTransform: "none",
              border: "1px solid rgba(0, 0, 148, 0.4)",
              fontSize: 15,
              fontWeight: 600
            }}
            type="submit"
          >
            Send Code
          </LoadingButton>
        </Stack>
        {errors.email?.type === 'required' &&
          <Typography mt={0.5} color="#FC6868">Email is required</Typography>
        }


        {status?.error &&
          <Typography mt={0.5} color="#FC6868">The code wasnt generated</Typography>
        }

        {status?.success &&
          <Typography mt={0.5} color="#01BB4B">Confirmation code has been successfully sent</Typography>
        }
      </Grid>
    </Box>
  )
}

export default StepVerificationInputPhone