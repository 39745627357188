import { Header, Modal, Checkbox } from "semantic-ui-react";
import { useState } from "react";
import EmailLink from "./emailLink";
import { SUPPORT_PHONE_NUMBER } from "../../../Helpers/Constants";


interface ModalProps {
  handleAgree: (agree: boolean) => void;
}

function ModalTermsAndConditions({ handleAgree }: ModalProps) {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleCheckbox = () => {
    setChecked(!checked);
    handleAgree(!checked);
  };

  const handleDisagree = () => {
    setChecked(false);
    handleAgree(false);
    setOpen(false);
  };

  const handleAgreeClick = () => {
    setChecked(true);
    setOpen(false);
    handleAgree(true);
  };

  return (
    <>
      <Checkbox checked={checked} onChange={handleCheckbox} />
      <span style={{ marginLeft: "1rem" }}>
        I acknowledge I have read and agree to the{" "}
      </span>
      <br />
      <Modal
        closeIcon
        open={open}
        trigger={
          <a
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              marginLeft: "2rem",
            }}
          >
            Terms and Services & Privacy Policy
          </a>
        }
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Header
          textAlign="center"
          content="Terms & Services and Privacy Policy"
        />
        <Modal.Content>
          <p style={{ color: "black" }}>
            <h2>Terms of Service</h2>
            Welcome to <b>www.MyMedHistory.com</b>, owned and operated by
            MyMedHistory, LLC (“MyMedHistory,” “we,” “us,” “our,” etc.). These
            Terms of Service (these “Terms” or this “Agreement”) govern your
            access to and use of the www.mymedhistory.com website and any
            associated downloadable software application, including without
            limitation our online or mobile services and software provided on or
            in connection with those services (collectively, the “Service(s)”).
            <br />
            <br />
            When you use the Services, these Terms constitute a binding
            agreement between MyMedHistory and you (“you,” “your,” etc.). BY
            CLICKING ON THE “I ACCEPT” BUTTON, OR COMPLETING THE ACCOUNT
            REGISTRATION PROCESS, OR OTHERWISE USING OUR SERVICES, YOU AGREE TO
            BE BOUND BY THESE TERMS AND ALL OF THE TERMS INCORPORATED HEREIN BY
            REFERENCE. YOU MUST BE OF THE AGE OF MAJORITY UNDER THE LAWS OF YOUR
            JURISDICTION TO ENTER INTO THIS AGREEMENT. If you do not agree to
            these Terms, you may not access or use the Service.
            <br />
            <br />
            MyMedHistory may modify these Terms from time to time, and modified
            Terms will take effect immediately upon posting to
            MyMedHistory&apos;s website. Your continued use of the Services
            after modified Terms have been posted will constitute your
            acceptance of such modified Terms.
            <br />
            <br />
            <b>1. Access to Services</b>
            <br />
            <br />
            Thank you for being an emergency responder or medical professional
            intending to use the Service to access the medical history
            information of your patients. MyMedHistory grants you a limited,
            non-exclusive, non-transferable, and revocable right to download,
            install, access, and use the Services in accordance with these
            Terms.
            <br />
            <br />
            <b> 2. Registration </b>
            <br />
            <br />
            Before you are able to engage in any transactions on the
            MyMedHistory platform, you must register for an account. Each user
            must register for their own account, and may not have more than one
            account. You will provide accurate information when you sign up to
            use the Services and will keep your account information up-to-date.
            You will also use your real name, or the legal name of the entity
            you represent, use your own email address, or one that you have
            permission to use, and you will not let anyone else use your
            account. You alone must protect the confidentiality of your
            registration information.
            <br />
            <br />
            <b>3. Use of Services </b>
            <br />
            <br />
            You will use the Service responsibly and in accordance with all
            laws, regulations, cannons of conduct, and rules of ethics
            applicable to your profession.
            <br />
            <br />
            <b> 4. Uptime </b>
            <br />
            <br />
            MyMedHistory will make commercially reasonable efforts to maintain
            the Services in operation and available on the internet. No minimum
            uptime or service level is guaranteed to you. MyMedHistory may
            terminate or change the Service at any time in its sole and absolute
            discretion.
            <br />
            <br />
            <b>5. Disclaimers & Limitations</b>
            <br />
            <br />
            You understand, acknowledge, and agree to all of the provisions of
            this Section 6.
            <br />
            <br />
            <b>
              {" "}
              Do not use MyMedHistory to respond to or seek treatment for
              medical emergencies. If you are suffering from a medical
              emergency, call 911 immediately.
            </b>
            <br />
            <br />
            &emsp; &#x2022; Your use of the Service is at your own risk.
            <br />
            <br />
            &emsp; &#x2022; The Service is intended solely as a helpful way for
            patients to make their pertinent medical history information
            available to you.
            <br />
            <br />
            &emsp; &#x2022; The Service is not a medical practice and does not
            render medical advice.
            <br />
            <br />
            &emsp; &#x2022; The Service is not a first-responder and cannot be
            used to summon emergency services.
            <br />
            <br />
            &emsp; &#x2022; The medical history information entered into the
            Services has been self-entered by the patient with no known medical
           oversight and it has not been reviewed by
            us for accuracy or for diagnostic purposes.
            <br />
            <br />
            &emsp; &#x2022; The Service may not be available in all geographic
            areas or at all times of day.
            <br />
            <br />
            &emsp; &#x2022; We are not responsible, and will bear no liability
            to you, for any events or circumstances beyond our reasonable
            control, including without limitation:
            <br />
            <br />
            &emsp; &emsp;&emsp;&emsp;&#x2022; insufficient cellular telephone or
            internet connectivity for the Service to operate;
            <br />
            &emsp; &emsp;&emsp;&emsp;&#x2022; inaccuracies in the information
            you retrieve from the Service;
            <br />
            &emsp; &emsp;&emsp;&emsp;&#x2022; your interpretation or
            misinterpretation of any medical history information; or
            <br />
            &emsp; &emsp;&emsp;&emsp;&#x2022; any diagnosis, misdiagnosis,
            treatment, failure to treat, malpractice, negligence, or other act
            or omission of yours.
            <br />
            <br />
            <b>6. Disclaimers of Warranty</b>
            <br />
            <br />
            You will have sole responsibility for conducting your use of the
            Services responsibly and legally. The Services are provided on an
            “AS IS” and “AS AVAILABLE” basis without warranties or conditions of
            any kind, either express or implied. You acknowledge and agree that
            MyMedHistory exercises no control over, and accepts no
            responsibility for, the acts or omissions of any patient or other
            third parties or internet service providers. MYMEDHISTORY MAKES NO
            WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT
            LIMITATION WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY,
            OR FITNESS FOR A PARTICULAR PURPOSE, NOR ANY WARRANTIES IMPLIED BY A
            COURSE OF PERFORMANCE, COURSE OF DEALING, OR USAGE OF TRADE.
            MyMedHistory will not be liable for any loss of any kind from any
            action taken or not taken in reliance on the Service. MyMedHistory
            cannot and does not represent or warrant that the service, content,
            any digital assets listed on our Service or our servers are free of
            viruses or other harmful components. We cannot guarantee the
            security of any data that you disclose online. You accept the
            inherent security risks of accessing information and dealing online
            over the internet and will not hold MyMedHistory responsible for any
            breach of security unless it is due to our gross negligence.
            <br />
            <br />
            WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO
            RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY LOSSES,
            DAMAGES, OR CLAIMS ARISING FROM: (A) USER ERROR INCLUDING WITHOUT
            LIMITATION FORGOTTEN PASSWORDS OR INCORRECT MEDICAL HISTORY
            INFORMATION; (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED DATA
            FILES; (D) UNAUTHORIZED ACCESS TO APPLICATIONS OR DATA; (E) ANY
            THIRD-PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF
            VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE
            SERVICE OR DIGITAL ASSETS.
            <br />
            <br />
            MyMedHistory makes no warranties that the Services will be
            uninterrupted or error-free or that any results obtained from the
            use of Services will be accurate or reliable. No oral or written
            information given by MyMedHistory will create any warranty, nor may
            you rely on such information or advice.
            <br />
            <br />
            Nothing in these Terms shall exclude or limit liability of either
            party for fraud, death or bodily injury caused by gross negligence,
            violation of laws, or any other activity that cannot be limited or
            excluded by legitimate means.
            <br />
            <br />
            <b> 7. Limitation of Liability</b>
            <br />
            <br />
            To the fullest extent permitted by law, neither MyMedHistory, nor
            its officers, directors, employees, shareholders, or agents will be
            liable for any consequential, indirect, incidental, special, or
            punitive damages, or loss of profits, revenue, data, or use by you
            or any third party, whether in an action in contract, tort, strict
            liability, or other legal theory. MyMedHistory will not be liable to
            you or any third party for any loss or damages that result or are
            alleged to have resulted from the use of or inability to use the
            Services, or that result from mistakes, omissions, interruptions,
            deletion of files, loss of data, errors, viruses, defects, delays in
            operations or transmission, or any failure of performance, whether
            or not limited to acts of nature, communications failure, theft,
            destruction, or unauthorized access to MyMedHistory&quot;s records,
            programs, equipment, or services, or any other condition whatsoever.
            <br />
            <br />
            Notwithstanding anything to the contrary in these Terms,
            MyMedHistory&quot;s maximum liability under these Terms for all
            direct and indirect damages, losses, costs, and causes of actions
            from any and all claims will not exceed the aggregate amount of one
            U.S. dollar.
            <br />
            <br />
            <b> 8. Indemnification </b>
            <br />
            <br />
            To the fullest extent permitted by applicable law, you will
            indemnify and hold harmless MyMedHistory, its employees, officers,
            directors, contractors, consultants, agents, representatives,
            affiliates, successors, and assigns from and against all actual or
            alleged third-party claims, damages, awards, judgements, losses,
            liabilities, obligations, penalties, interest, fees, costs, and
            expenses including without limitation reasonable attorney&quot;s
            fees, accruing to MyMedHistory as a result of your use or misuse of
            the Service or any other act or omission of yours, including without
            limitation claims arising from your treatment of any patient. You
            will cooperate with MyMedHistory and its employees, officers,
            directors, contractors, consultants, agents, representatives,
            affiliates, successors, and assigns in defending such claims.
            MyMedHistory will have control of the defense or settlement of any
            third-party claims.
            <br />
            <br />
            <b> 9. Trademarks and Copyrights </b>
            <br />
            <br />
            The name “MyMedHistory” and all other MyMedHistory marks, logos,
            taglines, custom graphics, and button icons appearing on the
            Services are service marks, trademarks, or trade dress of
            MyMedHistory and may not be used in connection with any product or
            service that is not offered by MyMedHistory in any manner that is
            likely to cause confusion among customers or in any manner that
            disparages or discredits MyMedHistory. All other trademarks, product
            names, and company names and logos used on the Services, if any, are
            the property of their respective owners.
            <br />
            <br />
            All software and audiovisual works of authorship on the Services,
            the selection and arrangement thereof, and the compilation of all
            content on the Services are the exclusive property of MyMedHistory
            and are protected by U.S. and international copyright laws.
            <br />
            <br />
            <b> 10. Governing Law; Arbitration.</b>
            <br />
            <br />
            This Agreement will be governed by the laws of the State of Florida,
            without regard to choice of laws provisions, no matter where in the
            world you live. In the event of a dispute arising out of this
            Agreement, the Parties will attempt to resolve any dispute by
            negotiation between the parties, in MyMedHistory&quot;s sole
            discretion. If you and we are unable to resolve the dispute,
            MyMedHistory may, at its sole decision, commence mediation or
            binding arbitration through the American Arbitration Association, or
            other forum. Judgment on the award rendered by the arbitrator may be
            entered into in any court having jurisdiction thereof. Any other
            disputes hereunder will be resolved before the courts of competent
            jurisdiction seated in or proximal to Ocala, Florida. All remedies
            available hereunder will be cumulative and not in lieu of any
            others.
            <br />
            <br />
            <b> 11. No Class Action</b>
            <br />
            <br />
            BY ACCEPTING THE SERVICE AND ENTERING INTO THIS AGREEMENT, YOU WAIVE
            THE RIGHT TO BRING OR PARTICIPATE IN A CLASS ACTION LAWSUIT AGAINST
            US. NEITHER YOU NOR WE MAY BE A REPRESENTATIVE OF OTHER POTENTIAL
            CLAIMANTS OR A CLASS OF POTENTIAL CLAIMANTS IN ANY DISPUTE, NOR MAY
            TWO OR MORE INDIVIDUALS&quot; DISPUTES BE CONSOLIDATED OR OTHERWISE
            DETERMINED IN ONE PROCEEDING. WHILE THE PROHIBITION ON CONSOLIDATED
            OR CLASS-WIDE PROCEEDINGS IN THIS AGREEMENT WILL CONTINUE TO APPLY
            IF YOU FAIL TO TIMELY PAY AMOUNTS DUE, WE MAY ASSIGN YOUR ACCOUNT
            FOR COLLECTION AND THE COLLECTION AGENCY MAY PURSUE SUCH CLAIMS IN
            COURT LIMITED STRICTLY TO THE COLLECTION OF THE PAST DUE DEBT AND
            ANY INTEREST OR COST OF COLLECTION PERMITTED BY LAW OR THE
            AGREEMENT.
            <br />
            <br />
            <b> 12. Termination </b>
            <br />
            <br />
            MyMedHistory retains the right to close or suspend your account in
            whole or in part, and to terminate your use of its Services at its
            discretion and without notice. You may terminate your account with
            MyMedHistory at your discretion. Any outstanding amounts due to
            MyMedHistory related to your account after termination will be due
            at the time of termination.
            <br />
            <br />
            <b> 13. Entire Agreement; Severability; Survival.</b>
            <br />
            <br />
            This Agreement represents the entire agreement between the parties,
            and supersedes all previous and contemporaneous representations,
            understandings, and agreements, if any, express or implied, whether
            written or oral. If any provision of this Agreement is held by a
            court of competent jurisdiction to be invalid, unenforceable, or
            void, the remainder of this Agreement will remain in full force and
            effect. Sections hereof that by their nature should survive
            termination hereof will so survive.
            <br />
            <br />
            <h2>Privacy Policy</h2>
            <br />
            Protecting your private information is our priority. This Privacy
            Policy <b>(“Privacy Policy”)</b> applies to <b>MyMedHistory, LLC</b>
            <b>(“MyMedHistory”, “we”, “us”, “our”</b>, and <b>“Company”)</b>,
            and our website(s), our marketing materials, the services we
            provide, and any software products and other materials we may
            provide (collectively, <b>“Service(s)”</b>). This Privacy Policy
            governs our data collection and usage practices regarding personally
            identifiable data that we may collect from or about you in
            connection with the Services. By accessing or using the Services,
            you consent to the data practices described in this Privacy Policy.
            <br />
            <br />
            <b>What Information Do We Collect and How Do We Collect It?</b>
            <br />
            <br />
            <b> Device Information</b>
            <br />
            <br />
            When you visit parts of the Services, we automatically collect
            certain information about your device, including information about
            your web browser, IP address, time zone, and some of the cookies
            that are installed on your device. Additionally, as you browse parts
            of the Services, we collect information about the individual web
            pages that you view, what website or search terms referred you to
            the Services, and information about how you interact with the
            Services. We may use cookies for purposes including without
            limitation: session management, user-retargeting for ad purposes on
            advertising platforms, and other uses as dictated by this Policy and
            our Terms of Service. We refer to this automatically-collected
            information as
            <b> “Device Information”</b>.
            <br />
            <br />
            We collect Device Information using the following technologies:
            <br />
            <br />
            &emsp; &emsp;&#x2022; “Cookies” are data files that are placed on
            your device or computer and often include an anonymous unique identifier. For more
            information about cookies, and how to disable cookies, visit
            http://www.allaboutcookies.org.
            <br />
            <br />
            &emsp; &emsp;&#x2022; “Log files” track actions occurring on parts
            of the Services, and collect data including your IP address, browser
            type, Internet service
            provider, referring/exit pages, and date/time stamps.
            <br />
            <br />
            &emsp; &emsp;&#x2022; “Web beacons”, “tags”, and “pixels” are
            electronic files used to record information about how you browse the
            Services.
            <br />
            <br />
            <b> Personal Information</b>
            <br />
            <br />
            In order to provide you with the Services, the Services may collect
            personally identifiable information, such as your:
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; first and last name;
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; home address;
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; e-mail address;
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; phone number;
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; IP address; and
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022;your personal medical history
            information.
            <br />
            <br />
            <b>Demographic Information</b>
            <br />
            <br />
            The Services may also collect anonymous demographic information,
            which is not unique to you, such as your age and gender.
            <br />
            <br />
            As used herein <b>“Personal Information”</b> means both Device
            Information and personally identifiable information, but not
            demographic information, which remains anonymous.
            <br />
            <br />
            <b> How Do We Use Your Information?</b>
            <br />
            <br />
            MyMedHistory collects and uses your Personal Information to:
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; operate its website(s) and deliver the
            Services, namely, providing your personal medical history
            information to emergency responders and medical professionals in the
            event of your incapacitation;
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; improve the Services;
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; communicate with you regarding Services
            you have requested from us;
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; screen incoming information for
            potential risk or fraud;
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; comply with legal obligations, respond
            to enforcement requests, and protect our rights and interests in the
            event of claims or disputes;
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; collect aggregate statistics about use
            of the Services;
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; understand and analyze how you use the
            Services; and
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; evaluate or conduct a merger,
            divestiture, restructuring, reorganization, dissolution, or other
            sale or transfer of some or all of MyMedHistory&apos;s assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar proceeding. Under these
            circumstances, your Personal Information will be transferred to and
            used by the acquiring entity, though
            we will take reasonable steps to ensure that your preferences are
            followed.
            <br />
            <br />
            We will not collect, use, or share additional categories of Personal
            Information for materially different, unrelated, or incompatible
            purposes other than as described above without providing you notice.
            <br />
            <br />
            <b>
              {" "}
              How and Why Does the Service Share Information with Third Parties?
            </b>
            <br />
            <br />
            We may disclose your personal information, without notice, if
            required to do so by law or in the good faith belief that such
            action is necessary to: (a) conform to the law or comply with legal
            process served on MyMedHistory; (b) protect and defend the rights or
            property of MyMedHistory; and (c) act under exigent circumstances to
            protect the personal safety of users of the Services or the public.
            <br />
            <br />
            <b>How Does MyMedHistory Process Your Personal Information? </b>
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; Legal basis for processing your
            information: We are committed to minimizing the information we
            collect and limiting its use and purposes
            to only: (i) where we have your permission; (ii) as necessary to
            deliver the Services; or (iii) as might be required or permitted for legal compliance or other
            lawful purpose. You may withdraw your consent to receiving our
            communications by clicking the
            unsubscribe link on any email you no longer wish to receive or by
            emailing us at {' '}
            <EmailLink
              label="info@mymedhistory.com"
              mailto="mailto:info@mymedhistory.com"
            />
            .
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; Storage and transfer of your
            information: Your Personal Information is processed at the
            Service&apos;s operating offices in the United
            States and any other places where the parties involved in the
            processing may be located. If you utilize the Services, or contact us by telephone or online
            from a country other than the United States, your Personal
            Information may be transferred to a country
            outside your own. Also, when you contact us or use the Services, we
            may provide you with support services
            that may require transfer of your Personal Information to locations
            that may be outside of your country of origin.
            In any case, your information remains governed by this Privacy
            Policy to the extent permissible in each applicable jurisdiction.
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; Retention of your Personal Information:
            Your Personal Information will be processed and stored for as long
            as required to complete the purpose for which
            it has been collected, such as providing you Services. Personal
            Information collected for MyMedHistory&apos;s
            legitimate business interests will be retained as long as needed to
            fulfill such purposes. MyMedHistory will
            comply with all relevant and data privacy laws and regulations as
            pertinent to retention of your Personal Information. To the degree required by law, you may request copies or
            deletion of your Personal Information. Once all applicable statutory retention periods expire, your
            Personal Information will be deleted from our database. Following
            the deletion of your Personal Information, your
            right to access, delete, and correct the Personal Information, as
            well as your right to data portability cannot
            be enforced, except as required by applicable laws.
            <br />
            <br />
            <b>What Are Your Rights Under the GDPR?</b>
            <br />
            <br />
            Under the General Data Protection Regulation <b>(“GDPR”)</b>,
            MyMedHistory is a data controller and data processor for the
            information we collect from you. If you are a European Union
            resident, you have the right to access personal information we hold
            about you and to ask that your personal information be corrected,
            updated, or deleted. If you would like to exercise this right,
            please contact us using the contact information below.
            <br />
            <br />
            Additionally, if you are a European Union resident, note that we are
            processing your information in order to fulfill contracts we might
            have with you (for example if you make an order through the
            Services), or otherwise to pursue our legitimate business interests.
            Additionally, please note that your information will be transferred
            outside of Europe, including to the United States.
            <br />
            <br />
            Note that you have specific rights under the GDPR, as follows:
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; You may withdraw your previously
            provided consent to the processing of your Personal Information at
            any time.
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; You may object to the processing of
            your Personal Information if it is being carried out for a purpose
            for which you
            have previously provided consent.
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; You may access your specific Personal
            Information in the possession of the Services, obtain disclosure
            regarding certain
            aspects of the processing, and obtain a copy of your Personal
            Information subject to processing.
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; You may seek to have your Personal
            Information updated or corrected.
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; Under certain circumstances, you may
            restrict the processing of your Personal Information, in which case
            we will refrain from
            any processing activity other than storage.
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; You may seek to have your Personal
            Information deleted. We will honor your request to the extent that
            retention of your Personal Information is no
            longer necessary for the Services to fulfill its legitimate business
            purposes or legal or 
            contractual record-keeping requirements.
            <br />
            <br />
            &emsp; &emsp; &emsp;&#x2022; You may receive a copy of your Personal
            Information in a structured, commonly-used machine readable format
            that may be
            transferred to another controller without hindrance.
            <br />
            <br />
            <b>
              What is your right to access specific information under the GDPR?
            </b>
            <br />
            <br />
            You have the right to request that the Services discloses certain
            information to you about our collection and use of your personal
            information over the past 12 months. Once we receive and confirm a
            verifiable request from you, we will disclose to you:
            <br />
            &emsp; &emsp;&#x2022; the categories of personal information we
            collected about you;
            <br />
            &emsp; &emsp;&#x2022; the categories of sources for the personal
            information we collected about you;
            <br />
            &emsp; &emsp;&#x2022; our business or commercial purpose for selling
            that personal information;
            <br />
            &emsp; &emsp;&#x2022; the categories of third parties with whom we
            share that personal information;
            <br />
            &emsp; &emsp;&#x2022; the specific pieces of personal information we
            collected about you
            <br />
            &emsp; &emsp;&#x2022; if we sold or disclosed your personal
            information for a business purpose, two separate lists disclosing:
            <br />
            &emsp; &emsp; &emsp; &emsp; &#x2022; sales, identifying the personal
            information categories that each category of recipient purchased;
            and
            <br />
            &emsp; &emsp; &emsp; &emsp; &#x2022; disclosures for a business
            purpose, identifying the personal information categories that each
            category of
            recipient obtained.
            <br />
            <br />
            <b> How Can You Exercise Your Rights? </b>
            <br />
            <br />
            You may request to exercise your rights to access, correction,
            deletion, and data portability of your Personal Information by
            contacting us at the contact information provided below. We will
            endeavor to respond to your request as promptly as possible but in
            any event in no more than 30 days from the receipt of your
            verifiable request.
            <br />
            <br />
            &emsp; &emsp; &emsp; &emsp; MyMedHistory, LLC
            <br />
            &emsp; &emsp; &emsp; &emsp;<b> PO BOX 773539</b>
            <br />
            &emsp; &emsp; &emsp; &emsp; <b>Ocala, FL 34477 </b>
            <br />
            <br />
            Only you may request to exercise your rights or those of your minor
            child, and your request must be verifiable. Your verifiable consumer
            request must:
            <br />
            <br />
            &emsp; &emsp;&#x2022; provide sufficient information that allows us
            to reasonably verify you are the person about whom we collected
            personal information or an
            authorized representative; and
            <br />
            &emsp; &emsp;&#x2022; describe your request will sufficient detail
            that allows us to properly understand, evaluate, and respond to it.
            <br />
            <br />
            We cannot respond to your request if we cannot verify your identity
            or authority to make the request and confirm the Personal
            Information relates to you. We will only use Personal Information
            provided in a verifiable consumer request to verify the
            requestor&apos;s identity or authority to make the request and for
            no other reason. Though you need not create an account to make a
            verifiable consumer request, we consider requests made through your
            password protected account sufficiently verified when the request
            relates to Personal Information associated with that specific
            account. If you have an account with us, we will deliver our written
            response to your account. If you do not have an account with us, we
            will deliver our written response by mail or electronically, at your
            option.
            <br />
            <br />
            For requests to transfer your Personal Information to another
            entity, we will provide your Personal Information in a format of our
            choosing that is readily useable and should allow you to transmit
            the information without hindrance, for example in .PDF, .TXT, or
            .DOC format. Any disclosures we provide will only cover the 12-month
            period preceding our receipt of your verifiable consumer request. If
            applicable, our response will provide the reasons we cannot comply
            with your request.
            <br />
            <br />
            We will not charge you a fee to process or respond to your
            verifiable consumer request unless it is excessive, repetitive, or
            manifestly unfounded. If we determine that the request warrants a
            fee, we will tell you why and provide a cost estimate before
            completing your request.
            <br />
            <br />
            <b>
              We will not discriminate against you for exercising your rights.
            </b>
            <br />
            <br />
            We will not discriminate against you for exercising any of your
            privacy rights. Unless permitted under applicable laws, solely due
            to the exercise of your rights hereunder, we will not:
            <br />
            <br />
            &emsp; &emsp;&#x2022; deny you goods or services;
            <br />
            &emsp; &emsp;&#x2022; charge you different prices or rates for goods
            or services, including through granting discounts or other benefits,
            or imposing penalties;
            <br />
            &emsp; &emsp;&#x2022; provide you a different level or quality of
            goods or services; or
            <br />
            &emsp; &emsp;&#x2022; suggest that you may receive a different price
            or rate for goods or services or a different level or quality of
            goods or services.
            <br />
            <br />
            <b>Security of Your Personal Information</b>
            <br />
            <br />
            We secure your personal information from unauthorized access, use,
            or disclosure. When personal information (such as a wallet address
            or payment information) is transmitted to other websites, it is
            protected through the use of encryption, for example, but not by way
            of limitation or guarantee, the Secure Sockets Layer (SSL) protocol.
            <br />
            <br />
            We strive to take appropriate security measures to protect against
            unauthorized access to or alteration of your personal information.
            Unfortunately, no data transmission over the Internet or any
            wireless network can be guaranteed to be 100% secure. As a result,
            while we strive to protect your personal information, you
            acknowledge that: (a) there are security and privacy limitations
            inherent to the Internet which are beyond our control; and (b)
            security, integrity, and privacy of any and all information and data
            exchanged between you and us through the Services cannot be
            guaranteed.
            <br />
            <br />
            <b>E-mail Communications</b>
            <br />
            <br />
            From time to time, we may contact you via email for the purpose of
            providing announcements, promotional offers, alerts, confirmations,
            surveys, and/or other general communication. In order to improve our
            Services, we may receive a notification when you open an email from
            the Services or click on a link therein.
            <br />
            <br />
            If you would like to stop receiving marketing or promotional
            communications via email from the Services, you may opt-out of such
            communications by clicking on the UNSUBSCRIBE button.
            <br />
            <br />
            <b> Contact Information </b>
            <br />
            <br />
            MyMedHistory welcomes your questions or comments regarding this
            Privacy Policy. If you believe that we have not adhered to this
            Privacy Policy, please contact us at:
            <br />
            <br />
            &emsp; &emsp;MyMedHistory, LLC
            <br />
            &emsp; &emsp;<b>PO BOX 773539</b>
            <br />
            &emsp; &emsp;<b>Ocala, FL 34477</b>
            <br />
            &emsp; &emsp;Email Address: {' '}
            <EmailLink
              label="info@mymedhistory.com"
              mailto="mailto:info@mymedhistory.com"
            />
            <br />
            &emsp; &emsp;Telephone number: <b>{SUPPORT_PHONE_NUMBER}</b>
            <br />
            <br />
            <b> Severability</b>
            <br />
            <br />
            If any provision of this Agreement is held by a court of competent
            jurisdiction to be invalid, unenforceable, or void, the remainder of
            this Agreement will remain in full force and effect. Sections hereof
            that by their nature should survive termination hereof will so
            survive.
            <br />
            <br />
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
            }}
          >
            <button
              style={{
                border: "2px solid #000094",
                borderRadius: "6px",
                color: "#000094",
                padding: "8px 12px",
                cursor: "pointer",
              }}
              onClick={handleAgreeClick}
            >
              I Agree
            </button>
          </div>
        </Modal.Content>
      </Modal>
      <br />
      <span style={{ marginLeft: "2rem" }}>
        {" "}
        and I acknowledge that the subscriber data provided by MyMedHistory has
        been entered into our system by the patient, not a medical professional
        and may be incomplete or inaccurate.
      </span>
      <br />
    </>
  );
}

export default ModalTermsAndConditions;
