import { Grid, styled as muStyled } from "@mui/material";
import Container from "@mui/material/Container";
import React, { FC, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import AlertMessageQRScan from "../components/AlertMessage/AlertMessageQRScan";
import HamburguerMenu from "../components/HamburguerMenu";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import Qrscan from "../components/QRscan/QrScan";
import useAuthGuard from "../Hooks/useAuthGuard";
import { LayoutContainer } from "../Layout/layout.container";
import { LayoutCenterContainer } from "../Layout/layoutCenter.container";
import { getUserIDByAccessCode } from "../Services/userService";
import { isValidStripeSubscription } from "../Services/membershipService";

export const QRScanCodePage: FC<any> = (): ReactElement => {
  useAuthGuard({
    requiredAdmin: false,
  });
  const MenuDiv = muStyled("div")({
    position: "absolute",
    top: "0",
    left: "0",
  });

  const navigate = useNavigate();
  const [isLoadingData, setLoadingData] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [adviceError, setAdviceError] = React.useState("error");

  const handlerNavigate = (url: string) => {
    navigate(url, { "state": { method: "QR" } });
  };

  const handlerQrResult = async (result: string) => {
    setLoadingData(true);

    if (!result || !result.includes("MMH")) return;

    try {
      const url = new URL(result);
      const searchParams = url.searchParams;

      // Get the value of 'mmh_id' from the search parameters
      const mmhId = searchParams.get("mmh_id");
      if (!mmhId) return;

      const numericalPart = mmhId.match(/\d+/);
      const extractedNumber = numericalPart ? numericalPart[0] : null;

      if (!extractedNumber) return;

      const idSubscriber = await getUserIDByAccessCode(extractedNumber);

      // Validate if the holder user has an active subscription in the system
      const { isValid } = await isValidStripeSubscription(idSubscriber);

      if (isValid) {
        console.log(url)
        handlerNavigate(`${url.pathname}${url.search}`);
      } else {
        setAdviceError("subscriberNotValid");
        setError(true);
      }
    } catch (error) {
      console.log("Invalid URL");
      setAdviceError("error");
      setError(true);
    } finally {
      setLoadingData(false);
    }
  };

  const handlerQrReturn = (result: any) => {
    navigate(-1);
  };

  return (
    <>
      {!isLoadingData ? (
        <AlertMessageQRScan open={error} advice={adviceError} />
      ) : (
        <></>
      )}
      {isLoadingData && (
        <LayoutCenterContainer
          children={
            <Container>
              <MenuDiv sx={{ mt: 2, ml: 2 }}>
                <Grid container direction="row" spacing={2}>
                  <HamburguerMenu />
                </Grid>
              </MenuDiv>
              <LoadingIndicator titleBefore="Logging in as a First Responder" />
            </Container>
          }
        ></LayoutCenterContainer>
      )}
      <LayoutContainer
        children={
          <Container>
            {!isLoadingData && (
              <Qrscan
                handlerResult={handlerQrResult}
                handlerReturn={handlerQrReturn}
              />
            )}
          </Container>
        }
      ></LayoutContainer>
      ;
    </>
  );
};
export default QRScanCodePage;
