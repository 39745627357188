import React from 'react'
import "./LabelAux.css";
import PropTypes from 'prop-types'

const LabelAux = ({content}) => {
  return (
        <div className='label-aux'>
            <p className='content'>
                {content}
            </p>
        </div>
  )
}

LabelAux.propTypes = {
    content: PropTypes.string
}

export default LabelAux
