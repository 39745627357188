import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface Props {
  advice: string;
  message: string;
}

const AlertMessage: FC<Props> = ({ advice, message }) => {
  const [open, setOpen] = useState(true);
  const [tittle, setTittle] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let url = new URL(window.location.href);
    window.history.pushState("", document.title, url);
  }, []);

  useEffect(() => {
    switch (advice) {
      case "blocker":
        setTittle("User/s has been blocked");
        break;
      case "unblocked":
        setTittle("User/s has been unblocked");
        break;
      case "deleted":
        setTittle("User/s has been deleted");
        break;
    }
  }, []);

  return (
    <Collapse in={open}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
              searchParams.delete("advice");
              searchParams.delete("message");
              setSearchParams(searchParams);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        variant="filled"
        severity="success"
        style={{
          background: "#73D697E5",
          width: "35%",
          margin: "auto",
          color: "#FFF",
        }}
      >
        <Typography color="#fff" fontSize={14} fontWeight={700}>
          {tittle}
        </Typography>
        <Typography color="#fff" fontSize={14}>
          {message}
        </Typography>
      </Alert>
    </Collapse>
  );
};

export default AlertMessage;
