import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useDefaultActiveSessionLengthForAgency } from "../../Hooks/useFirstResponderService";

interface Props {
  value: number;
  setValue: (value: number) => void;
  label: string;
}

const EditClassField: React.FC<Props> = ({ value, setValue, label }) => {
  const { defaultActiveSessionLength } =
    useDefaultActiveSessionLengthForAgency();

  return (
    <>
      <Typography fontSize={13} fontWeight={600}>
        {label}
        <Tooltip
          title={
            <Box
              sx={{
                color: "white",
                p: 1,
              }}
            >
              <Typography fontSize={12} fontWeight={400}>
                This setting determines how long the First Responder user can
                retain access to the Subscriber&rsquo;s profile after scanning
                the QR code. After the specified time, the session will end for
                security reasons.
              </Typography>
              <Typography
                fontSize={12}
                fontWeight={400}
                sx={{
                  mt: 2,
                }}
              >
                Classification:
              </Typography>
              <Typography fontSize={12} fontWeight={400} sx={{ ml: 2 }}>
                <strong>• Class 1</strong>: 60 minutes (EMT/Fire Department/Fire
                Rescue)
              </Typography>
              <Typography fontSize={12} fontWeight={400} sx={{ ml: 2 }}>
                <strong>• Class 2</strong>: 30 minutes (Law Enforcement)
              </Typography>
            </Box>
          }
          placement="top"
          color="white"
        >
          <InfoOutlinedIcon
            sx={{
              fontSize: 13,
              color: "#000094",
              mt: 1,
            }}
          />
        </Tooltip>
      </Typography>
      <Select
        fullWidth
        value={value}
        onChange={(e) => {
          setValue(e.target.value as number);
        }}
        defaultValue={defaultActiveSessionLength ?? 60}
      >
        <MenuItem value={60}>Class 1 (60 min.)</MenuItem>
        <MenuItem value={30}>Class 2 (30 min.)</MenuItem>
      </Select>
    </>
  );
};

export default EditClassField;
