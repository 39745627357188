import * as Yup from "yup";
import { FC, ReactElement } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { CustomButton } from "../CustomButton/CustomButton";

import PropTypes from "prop-types";
import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

export const EmailForm: FC<any> = (prop): ReactElement => {
  const [yupSchema, setYupSchema] = React.useState<any>(null);

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: yupSchema,
    onSubmit: (formData) => {},
  });

  const loadForm = () => {
    const yupDefinition: any = {};
    yupDefinition.email = Yup.string().email().required();

    const schema = Yup.object().shape(yupDefinition);
    setYupSchema(schema);
  };

  const handlerSubmit = () => {
    formik.submitForm().then(() => {
      if (formik.isValid && formik.values.email.length > 0)
        prop.handlerSendEmail(formik.values.email);
    });
  };

  const handlerChangeEmail = (e: any) => {
    formik.setValues({
      ...formik.values,
      email: e.target.value,
    });
  };

  React.useEffect(() => {
    loadForm();
  }, []);

  return (
    <div>
      <Typography fontSize={29} fontWeight={600} sx={{ paddingBottom: 4 }}>
        {prop.title}
      </Typography>
      <form className="w-100" onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              className="MiuTextField full-radius"
              placeholder="Enter email"
              variant="outlined"
              size="small"
              onChange={handlerChangeEmail}
              error={Boolean(formik.errors.email)}
            />
            {prop.elementLabel}
          </Grid>
        </Grid>
        <LoadingButton
          loading={prop.isLoading}
          size="large"
          variant="contained"
          sx={{
            width: "100%",
            height: 48,
            textTransform: "none",
            fontSize: "1rem",
            color: "#fff",
            borderRadius: "8px",
            backgroundColor: "#000094",
            mb: 1,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 5,
          }}
          onClick={() => {
            handlerSubmit!();
          }}
        >
          Send
        </LoadingButton>
      </form>
    </div>
  );
};

EmailForm.propTypes = {
  title: PropTypes.string,
  elementLabel: PropTypes.element,
  handlerSendEmail: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default EmailForm;
