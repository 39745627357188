import { CognitoUserSession, ISignUpResult } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoAuthService } from '../Services/CognitoAuthService';

export const useCognitoAwsV2 = () => {
  useCognitoAwsV2;
  const cognitoAuthService = new CognitoAuthService();
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const signIn = async (email: string, password: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      setError(null);
      try {
        Auth.signIn(email, password).then((res) => {
          resolve(res);
        })
          .catch((err) => {
            console.log('err', err)
            reject(err);
          });
      } catch (error) {
        reject(error);
      }

    });
  };

  const signOut = async () => {
    setIsLoading(true);
    setError(null);
    try {
      //clean session localStorage session storage and redirect to login page
      localStorage.clear();
      sessionStorage.clear();
      await Auth.signOut();
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const getAuthUser = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let request = await Auth.currentAuthenticatedUser();
      return request
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ): Promise<ISignUpResult | null> => {
    setIsLoading(true);
    setError(null);
    try {
      const signUpParams = {
        password,
        username: email,
        attributes: { given_name: firstName, family_name: lastName },
      };
      const user = await Auth.signUp(signUpParams);
      console.log(user);
      setIsAuthenticated(true);
      return user;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
    return null;
  };

  const confirmSignUp = async (email: string, code: string) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      setError(null);
      try {
        Auth.confirmSignUp(email, code).then((res) => {
          resolve(res);
        });
        setIsAuthenticated(true);
      } catch (error) {
        reject(error)
        setError(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  const currentUser = (): Promise<CognitoUserSession> => {
    return Auth.currentSession();
  };

  const completeNewPasswordChallenge = async (
    email: string,
    oldPassword: string,
    newPassword: string,
    formFirstName?: string,
    formLastName?: string
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      await cognitoAuthService.completeNewPasswordChallenge(
        email,
        oldPassword,
        newPassword,
        formFirstName,
        formLastName
      );
      navigate('/Dashboard');
    } catch (error) {
      console.log(error);
      setError(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  // const resendSignUp = async (email: string) => {
  //   setIsLoading(true);
  //   setError(null);
  //   try {
  //     const user = await cognitoAuthService.resendSignUp(email);
  //     handleSetUser(user);
  //     setIsAuthenticated(true);
  //   } catch (error) {
  //     setError(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const forgotPassword = async (
    email: string,
    clientMetadata: any | undefined = undefined,
    resetError: boolean = true
  ) => {
    setIsLoading(true);
    if (resetError) setError(null);
    try {
      clientMetadata
        ? await Auth.forgotPassword(email, clientMetadata)
        : await Auth.forgotPassword(email);
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // const confirmForgotPassword = async (
  //   email: string,
  //   code: string,
  //   password: string
  // ) => {
  //   setIsLoading(true);
  //   setError(null);
  //   try {
  //     const user = await cognitoAuthService.confirmForgotPassword(
  //       email,
  //       code,
  //       password
  //     );
  //     handleSetUser(user);
  //     setIsAuthenticated(true);
  //   } catch (error) {
  //     setError(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const resendForgotPassword = async (email: string) => {
  //   setIsLoading(true);
  //   setError(null);
  //   try {
  //     const user = await cognitoAuthService.forgotPassword(email);
  //     handleSetUser(user);
  //     setIsAuthenticated(true);
  //   } catch (error) {
  //     setError(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const currentUserSession = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUserSession, oldPassword, newPassword);
      setIsAuthenticated(true);
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const forgotPasswordSubmit = async (email: string, code: string, password: string) => {
    setIsLoading(true);
    setError(null);
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      setIsAuthenticated(true);
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // const currentSession = async () => {
  //   setIsLoading(true);
  //   setError(null);
  //   try {
  //     const cognitoUserSession = await Auth.currentSession();
  //     handleSetUser(cognitoUserSession);
  //     setIsAuthenticated(true);
  //   } catch (error) {
  //     setError(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // function handleSetUser(user: any) {
  //   setUser(user);
  // }

  async function getSessionGroups(): Promise<string[]> {
    let groups: string[] = [];
    const currentUserSession = await currentAuthenticatedUser();
    groups = currentUserSession.signInUserSession.accessToken.payload['cognito:groups'];
    return groups;
  }

  async function currentAuthenticatedUser(): Promise<any> {
    setIsLoading(true);
    setError(null);
    try {
      const currentUserSession = await Auth.currentAuthenticatedUser();
      // console.log(currentUserSession);
      setIsAuthenticated(true);
      return currentUserSession;
    } catch (error) {
      setError(error);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  }

  function getAccessToken(): string {
    // return user ? user.result.accessToken.jwtToken : "";
    return '';
  }

  // async function isTokenExpired(): Promise<boolean> {
  //   const currentSession = await Auth.currentSession();
  //   return currentSession.isValid();
  // }

  async function isSessionValid(): Promise<boolean> {
    const currentUser = await Auth.currentUserInfo();
    if (currentUser) {
      const currentSession = await Auth.currentSession();
      return currentSession.isValid();
    } else {
      return false;
    }
  }

  // function getSessionUserName(): string {
  //   return user.result.accessToken.payload["username"];
  // }

  return {
    // user,
    isAuthenticated,
    isLoading,
    error,
    signIn,
    signOut,
    signUp,
    getAuthUser,
    confirmSignUp,
    // resendSignUp,
    forgotPassword,
    // confirmForgotPassword,
    // resendForgotPassword,
    changePassword,
    forgotPasswordSubmit,
    // getCurrentSession,
    getSessionGroups,
    // getSessionUserName,
    currentAuthenticatedUser,
    getAccessToken,
    isSessionValid,
    completeNewPasswordChallenge,
    setError,
    currentUser,
  };
};

// export type UserApp = {
//   userAccessTokenInfo: UserAccessTokenInfo | undefined;
//   userIdTokenInfo: UserIdTokenInfo | undefined;
// };

// export type UserAccessTokenInfo = {
//   username: string;
//   cognitoGroups: [];

// };

// export type UserIdTokenInfo = {
//   cognitoUsername: string;
//   email: string
//   cognitoGroups: [];
//   familyName: string;
//   givenName: string;
// };
export { };
