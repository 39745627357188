import { useEffect, useState } from "react"
import { getFirstResponderByEmail } from "../Services/firstResponderService"
import { useCognitoAwsV2 } from "./useCognitoAwsv2"

export default function useAuthUserData() {
  const { currentAuthenticatedUser } = useCognitoAwsV2()
  const [cognitoUser, setCognitoUser] = useState<any>(null)
  const [user, setUser] = useState<any>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    currentAuthenticatedUser().then((cognitoUserData) => {
      if (!cognitoUserData) {
        setLoading(false)
      } else {
        getFirstResponderByEmail(cognitoUserData.attributes.email).then(
          (res) => {
            setUser(res.data[0])
            setCognitoUser(cognitoUserData)
            setLoading(false)
          }
        )
      }
    })
  }, [])

  return { user, loading, cognitoUser }
}
