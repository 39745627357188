import { Box, Container, CssBaseline, Snackbar } from "@mui/material"
import MuiAlert, { AlertProps } from "@mui/material/Alert"
import React, { FC, ReactElement } from "react"
import { useNavigate } from "react-router-dom"
import BoxNotificationEmail from "../components/BoxNotificationEmail/BoxNotificationEmail"
import { CustomButton } from "../components/CustomButton/CustomButton"
import EmailForm from "../components/Email/email"
import LabelAux from "../components/Informatives/LabelAux"
import { LayoutSign } from "../Layout/layout-sign/layout-sign"
import { CognitoAuthService } from "../Services/CognitoAuthService"
import { sendRecoverPasswordNotification } from "../Services/emailService"

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const PasswordRecoveryEmail: FC<any> = (): ReactElement => {
  const cognitoAuthService = new CognitoAuthService()
  const navigate = useNavigate()
  const [isForm, setIsForm] = React.useState<any>(true)
  const [email, setEmail] = React.useState<string>("")
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [open, setOpen] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>("")
  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  const emailLabel = (
    <LabelAux content={"Enter your email to recover your password"}></LabelAux>
  )
  const handlerSubmitEmail = async (emailParam: string) => {
    setIsLoading(true)
    await cognitoAuthService
      .forgotPassword(emailParam)
      .then(async () => {
        await sendRecoverPasswordNotification(
          emailParam,
          ",VerificationPassword"
        ).then((res) => {
          setEmail(emailParam)
          setIsLoading(false)
          setIsForm(false)
        })
      })
      .catch((error) => {
        let errorMessage =
          error.message == "Username/client id combination not found."
            ? "Email not found."
            : error.message
        setIsLoading(false)
        setErrorMessage(errorMessage)
        setOpen(true)
      })
  }

  const handlerNavigate = () => {
    navigate("/")
  }

  const notificationEmailLabel = `A recovery code has been sent to your ${email}. Please check your email for further instructions.`
  return (
    <>
      <LayoutSign
        children={
          <Container>
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              pt={3}
            >
              {isForm && (
                <EmailForm
                  title="Password Recovery"
                  handlerSendEmail={handlerSubmitEmail}
                  elementLabel={emailLabel}
                  isLoading={isLoading}
                />
              )}
              {!isForm && (
                <div>
                  <BoxNotificationEmail
                    title="Email Sent"
                    elementLabel={notificationEmailLabel}
                  />
                  <CustomButton
                    text="Back to login"
                    type="button"
                    onClick={handlerNavigate}
                    sx={{ mb: 1, marginLeft: 0, marginRight: 0, marginTop: 5 }}
                    isLoading={isLoading}
                  ></CustomButton>
                </div>
              )}
            </Box>
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {errorMessage}
              </Alert>
            </Snackbar>
          </Container>
        }
      ></LayoutSign>
    </>
  )
}

export default PasswordRecoveryEmail
