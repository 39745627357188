import { Auth } from 'aws-amplify';
import axios from 'axios';
import appConfig from '../Config/appConfig';
// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
// headers: { 'api-key': 'eyJz-CI6Ikp-4pWY-lhdCI6', 'Origin': 'https://d1qpy2p0a2fvkp.cloudfront.net/' }
const customAxios = axios.create({
  baseURL: `${appConfig.api.ENDPOINT}`,
  timeout: 100000,
});

// Step-2: Create request, response & error handlers
const requestHandler = async (request: any) => {
  let current;
  try {
    current = await Auth.currentSession();
  } catch (error) {
    current = null;
  }
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  request.headers.Authorization = current ? `Bearer ${current.getIdToken().getJwtToken()}` : ``;
  return request;
};

const responseHandler = (response: any) => {
  if (response.status === 401) {
    (window as any).location = '/login';
  }

  return response;
};

const errorHandler = (error: any) => {
  return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
  (request: any) => requestHandler(request),
  (error: any) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response: any) => responseHandler(response),
  (error: any) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;
