import { FC } from "react"

import { Stack } from "@mui/material"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

import StepVerificationInputCode from "./StepVerificationInputCode";
import StepVerificationInputPhone from "./StepVerificationInputPhone";
import StepVerificationInputEmail from "./StepVerificationInputEmail";

const StepVerificationForm: FC = () => {

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="start"
      pt={3}
      px={8}
      pb={8}
    >
      <Grid item color="#000094">
        <Stack direction="row" justifyContent="start" spacing={1}>
          <AdminPanelSettingsOutlinedIcon
            sx={{
              fontSize: 36,
            }}
          />
          <Typography color="#242627" fontSize={25} fontWeight={700}>
            Two Step Verification
          </Typography>
        </Stack>
      </Grid>
      {/* <StepVerificationInputPhone /> */}
      <StepVerificationInputEmail />
      <StepVerificationInputCode />
    </Grid >
  )
}

export default StepVerificationForm