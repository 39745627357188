import { CondOperator, RequestQueryBuilder } from "@nestjsx/crud-request";
import { Auth } from "aws-amplify";
import { FirstResponderData } from "../Interfaces/FirstResponder";
import { FIRSTRESPONDER_API } from "./CONSTANTS";
import customAxios from "./customAxios";

export const registerFirstResponder = (prms: any) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .post(`${FIRSTRESPONDER_API}`, prms)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("🍉 ~ file: firstResponderService.ts:19 ~ error:", error);
      reject(error);
    }
  });
};

export const getAllFirstResponder = () => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(`${FIRSTRESPONDER_API}?limit=10000`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getFirstRespondersForAgency: (
  agency: string
) => Promise<FirstResponderData[]> = (agency) => {
  // TODO: change to agency endpoint to retrieve the first responder for that agency
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get<FirstResponderData[]>(
          `${FIRSTRESPONDER_API}getFirstRespondersForAgency/${agency}?limit=10000`
        )
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getFirstResponderByID = (userId: any) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(`${FIRSTRESPONDER_API}${userId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const updateFirstResponderbyId = (
  userId: String,
  prms: any,
  emailChanged: String | null = null
) => {
  return new Promise((resolve, reject) => {
    customAxios
      .put(`${FIRSTRESPONDER_API}updateFr/${userId}/${emailChanged}`, prms)
      .then((res) => {
        resolve(res.data);
      })
      .catch((reason: any) => {
        reject(reason.response.data.message[0]);
      });
  });
};

export const deleteFirstResponderbyId = (userId: any) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .delete(`${FIRSTRESPONDER_API}${userId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getUserByCodeID: (
  codeId: number
) => Promise<FirstResponderData> = (codeId: number) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get<FirstResponderData>(
          `${FIRSTRESPONDER_API}getUserByCodeID/${codeId}`
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getEmailByCodeID = (codeId: string) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(`${FIRSTRESPONDER_API}getEmailByCodeID/${codeId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const updateStatusFirstResponderbyId = (usersId: any, status: any) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .put(`${FIRSTRESPONDER_API}updateStates/${status}/${usersId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log("🍉 ~ updateStatusFirstResponderbyId ~ error:", error);
      reject(error);
    }
  });
};

export const updateStatusFirstResponderbyEmail = (email: any, status: any) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .put(`${FIRSTRESPONDER_API}updateStates/${status}/${email}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getFirstResponderByEmail: (email: string) => Promise<any> = (
  email: string
) => {
  const qb = RequestQueryBuilder.create().setFilter({
    field: "email",
    operator: CondOperator.EQUALS,
    value: email,
  });

  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      customAxios
        .get(`${FIRSTRESPONDER_API}?${qb.query()}`)
        .then((res) => {
          // console.log(`${getUser.name} > axios res=`, res)
          resolve(res.data);
        })
        .catch((err) => {
          console.log("🍉 ~ file: firstResponderService.ts:207 ~ err:", err);
          reject(err);
        });
    } catch (error) {
      console.log("🍉 ~ file: firstResponderService.ts:211 ~ error:", error);
      reject(error);
    }
  });
};

export const sendCode: (phone: string) => Promise<any> = async (phone) => {
  const current = await Auth.currentSession();
  const {
    payload: { sub: cognito_id },
  } = current.getAccessToken();

  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(`${FIRSTRESPONDER_API}sendCode/+${phone}/${cognito_id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const verifyCode: (
  code: string,
  enableMfa: string
) => Promise<any> = async (code, enableMfa) => {
  const current = await Auth.currentSession();
  const {
    payload: { sub: cognito_id },
  } = current.getAccessToken();

  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(
          `${FIRSTRESPONDER_API}verifyCode/${code}/${cognito_id}?enableMfa=${enableMfa}`
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getUserByCognitoId: () => Promise<any> = async () => {
  const current = await Auth.currentSession();
  const {
    payload: { sub: cognito_id },
  } = current.getAccessToken();

  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(`${FIRSTRESPONDER_API}getUserByCognitoId/${cognito_id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const sendPhoneCodeMFA: (
  phone: string,
  subCognitoId: string
) => Promise<any> = (phone: string, subCognitoId: string) => {
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      customAxios
        .get(`${FIRSTRESPONDER_API}sendCode/${phone}/${subCognitoId}`)
        .then((res) => {
          // console.log(`${getUser.name} > axios res=`, res)
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getFirstResponderByUsername: (userName: string) => Promise<any> = (
  userName: string
) => {
  const qb = RequestQueryBuilder.create().setFilter({
    field: "username",
    operator: CondOperator.EQUALS,
    value: userName,
  });
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      customAxios
        .get(`${FIRSTRESPONDER_API}?${qb.query()}`)
        .then((res) => {
          // console.log(`${getUser.name} > axios res=`, res)
          resolve(res.data);
        })
        .catch((err) => {
          debugger; // eslint-disable-line no-debugger
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const usernameExists: (username: string) => Promise<boolean> = (
  username: string
) => {
  console.log(username);
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      customAxios
        .get(`${FIRSTRESPONDER_API}usernameExists/${username}`)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
