import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { Layout } from './Layout/layout.component';
import './Styles/MiuTextField.css';
import { Amplify } from 'aws-amplify';
import { CssBaseline } from '@mui/material';
import RouterApp from './Routes';
import { ThemePrimary } from './Styles/theme';
import AppConfig from './Config/appConfig';

Amplify.configure({
  identityPoolId: AppConfig.cognito.IDENTITY_POOL_ID,
  aws_cognito_region: AppConfig.cognito.REGION,
  aws_user_pools_id: AppConfig.cognito.USER_POOL_ID,
  aws_user_pools_web_client_id: AppConfig.cognito.APP_CLIENT_ID,
  Storage: {
    AWSS3: {
      bucket: AppConfig.s3.BUCKET, // REQUIRED -  Amazon S3 bucket name
      region: 'us-east-1',
    },
  },
});

const App = () => {
  const theme = createTheme(ThemePrimary);
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Layout>
          <CssBaseline />
          <RouterApp />
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export const theme = createTheme({});
export default App;
