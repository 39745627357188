import {
  SendFirstResponderAdminInvitation,
  SendFirstResponderInvitation,
} from "../Interfaces/EmailService"
import { USERS_API } from "./CONSTANTS"
import customAxios from "./customAxios"

export const sendRecoverIDCodeNotification: (email: string) => Promise<any> = (
  email
) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(`${USERS_API}sendRecoverIdCode/${email}`)
        .then((res) => {
          // console.log(`${confirmSignUp.name} axios res=`, res)
          resolve(res.data)
        })
        .catch((err) => {
          // console.error(`${confirmSignUp.name} axios err=`, err)
          reject(err)
        })
    } catch (error) {
      // console.error(`${confirmSignUp.name} axios err=`, error)
      reject(error)
    }
  })
}

// El slug deberia ser con , en vez de / para que nest no rompa
// Ejemplo: ",admin,reset" sería lo mismo que "/admin/reset"
// Pueden mirar como esta hecho en PasswordRecovery.tsx o en ForgotPasswordPopup.tsx
export const sendRecoverPasswordNotification: (
  email: string,
  slug: string
) => Promise<any> = (email, slug) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(`${USERS_API}sendRecoverPassword/${email}/${slug}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    } catch (error) {
      reject(error)
    }
  })
}

export const sendFirstResponderInvitation = ({
  idCode,
  password,
  adminUsername,
  email,
}: SendFirstResponderInvitation): Promise<any> => {
  console.log("🍉 ~ file: emailService.tsx:59 ~ email:", email);
  console.log("🍉 ~ file: emailService.tsx:59 ~ adminUsername:", adminUsername);
  console.log("🍉 ~ file: emailService.tsx:59 ~ password:", password);
  console.log("🍉 ~ file: emailService.tsx:59 ~ idCode:", idCode);
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(
          `${USERS_API}sendFirstResponderInvitation/FR${idCode}/${password}/${adminUsername}/${email}`
        )
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    } catch (error) {
      console.log("🍉 ~ file: emailService.tsx:77 ~ returnnewPromise ~ error:", error);
      reject(error)
    }
  })
}

export const sendFirstResponderAdminInvitation = ({
  adminUsername,
  email,
}: SendFirstResponderAdminInvitation): Promise<any> => {
  console.log("🍉 ~ file: emailService.tsx:81 ~ email:", email);
  console.log("🍉 ~ file: emailService.tsx:81 ~ adminUsername:", adminUsername);
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(
          `${USERS_API}sendFirstResponderAdminInvitation/${adminUsername}/${email}`
        )
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    } catch (error) {
      console.log("🍉 ~ file: emailService.tsx:96 ~ returnnewPromise ~ error:", error);
      reject(error)
    }
  })
}

export const sendMultipleFirstResponderInvitation: (
  adminUsername: string,
  prms: any
) => Promise<any> = (adminUsername, prms) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .post(
          `${USERS_API}sendMultipleFirstResponderInvitation/${adminUsername}`,
          prms
        )
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    } catch (error) {
      reject(error)
    }
  })
}

export const sendVerificationCodeMFA: (
  email: string,
  code: string
) => Promise<any> = (email, code) => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(`${USERS_API}sendVerificationCodeMFA/${email}/${code}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    } catch (error) {
      reject(error)
    }
  })
}
