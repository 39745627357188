import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import CircleIcon from "@mui/icons-material/Circle"
import SearchIcon from "@mui/icons-material/Search"
import Button from "@mui/material/Button"
import InputAdornment from "@mui/material/InputAdornment"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import { FC, MouseEvent, useState } from "react"
import EditUserDialog from "../../components/EditUserDialog"
import { STATUSES, StatusesType } from "../../pages/FRAdmin/ManageUsers"

interface Props {
  status: StatusesType
  setStatus: (status: StatusesType) => void
  searchInput: string
  setSearchInput: (searchInput: string) => void
}

const ManageUsersFilter: FC<Props> = ({
  status,
  setStatus,
  searchInput,
  setSearchInput,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openNewUserDialog, setOpenNewUserDialog] = useState(false)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        variant="contained"
        disableRipple
        disableElevation
        sx={{
          color: "black",
          backgroundColor: "white",
          textTransform: "none",
          ":hover": {
            backgroundColor: "#F0F0F0",
          },
        }}
        onClick={handleClick}
        endIcon={!open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      >
        <CircleIcon
          sx={{ color: STATUSES[status].color, mr: 1, fontSize: 12 }}
        />
        {STATUSES[status].label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "white",
            color: "black",
          },
        }}
      >
        {Object.keys(STATUSES).map((key) => (
          <MenuItem
            key={key}
            value={key}
            onClick={() => {
              setStatus(key as StatusesType)
              handleClose()
            }}
          >
            <CircleIcon
              sx={{
                color: STATUSES[key as StatusesType].color,
                mr: 1,
                fontSize: 12,
              }}
            />
            {STATUSES[key as StatusesType].label}
          </MenuItem>
        ))}
      </Menu>
      <TextField
        placeholder="Search..."
        size="small"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        sx={{
          width: "200px",
          color: "black",
          backgroundColor: "white",
          mx: 2,
          "& fieldset": { border: "none" },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                sx={{
                  color: "#000094",
                }}
              />
            </InputAdornment>
          ),
        }}
        inputProps={{ style: { color: "black" }, color: "black" }}
      />
      <Button
        variant="contained"
        sx={{
          color: "white",
          backgroundColor: "#000094",
          textTransform: "none",
        }}
        onClick={() => setOpenNewUserDialog(true)}
        startIcon={<AddCircleOutlineOutlinedIcon />}
      >
        Add User
      </Button>
      <EditUserDialog
        open={openNewUserDialog}
        handleClose={() => setOpenNewUserDialog(false)}
      />
    </>
  )
}

export default ManageUsersFilter
