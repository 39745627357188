/* eslint-disable react/no-unescaped-entities */
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { FC, useState } from "react"
import { CognitoAuthService } from "../../Services/CognitoAuthService"
import { sendRecoverPasswordNotification } from "../../Services/emailService"
import PrincipalLayout from "../Common/PrincipalLayout"
import { CustomButton } from "../CustomButton/CustomButton"

interface Props {
  open: boolean
  handleClose: () => void
}

const ForgotPasswordPopup: FC<Props> = ({ open, handleClose }) => {
  const cognitoAuthService = new CognitoAuthService()
  const [successScreen, setSuccessScreen] = useState(false)
  const [email, setEmail] = useState("")
  const [error, setError] = useState<string | null>(null)
  const [resent, setResent] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmitEmail = async () => {
    if (!email) {
      setError("Please enter your email")
      return
    }

    setLoading(true)
    await cognitoAuthService
      .forgotPassword(email)
      .then(async () => {
        await sendRecoverPasswordNotification(email, ",admin,reset")
          .then((res) => {
            setSuccessScreen(true)
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            setError("Something went wrong. Please try again later.")
            setLoading(false)
          })
      })
      .catch((err) => {
        console.log(err)
        setError("Email is invalid or not used by any account")
        setLoading(false)
      })
  }

  const clean = () => {
    setEmail("")
    setError(null)
    setSuccessScreen(false)
    setResent(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        clean()
        handleClose()
      }}
      fullScreen={successScreen}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          bgcolor: "white",
          color: successScreen ? "white" : "black",
        },
      }}
    >
      {successScreen ? (
        <PrincipalLayout revert>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            direction="column"
            color="black"
            position="relative"
            top="25%"
            px={10}
          >
            <Grid item color="#86E0A7">
              <MailOutlineIcon sx={{ fontSize: 100 }} />
            </Grid>
            <Grid item>
              <Typography fontSize={25} fontWeight={600}>
                Email has been sent
              </Typography>
              <Typography fontSize={16} fontWeight={400}>
                An email has been sent to your{" "}
                <Box display="inline" color="#86E0A7">
                  {email}
                </Box>
                .<br /> Please check for an email from mymedhistory.com and
                follow the instructions.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  clean()
                  handleClose()
                }}
                sx={{
                  py: 1.5,
                  px: 3,
                  textTransform: "none",
                  my: 2,
                  color: "#000094",
                  borderColor: "#000094",
                  borderRadius: 2.4,
                }}
              >
                Back to Login
              </Button>
            </Grid>
            <Grid item>
              <Typography fontSize={16} fontWeight={400} color="#6A7288">
                {resent
                  ? "We have resent the email to you"
                  : `Didn't receive a letter?`}
                <span
                  style={{
                    color: "#000094",
                    paddingLeft: 5,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={
                    resent
                      ? () => {
                        clean()
                        handleClose()
                      }
                      : () => {
                        setResent(true)
                        handleSubmitEmail()
                      }
                  }
                >
                  {resent ? "Ok" : "Re-Send"}
                </span>
              </Typography>
            </Grid>
            <Grid item>
              <Alert severity="warning" icon={false} sx={{ fontSize: 12, marginTop: 4, background: '#fff6d7'}}>
                If you do not see the email in a few minutes, check your  <b>"Junk Mail"</b> or <b>"Spam"</b> folder and add the MyMedHistory's email to your <b>"Safe Sender List." </b>If nothing helped, please try submitting the form again.
              </Alert>
            </Grid>
          </Grid>
        </PrincipalLayout>
      ) : (
        <Grid container direction="column" alignItems="center">
          <Grid item container p={2} direction="row-reverse">
            <IconButton
              onClick={() => {
                clean()
                handleClose()
              }}
              sx={{ color: "#000094" }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            pt={3}
            px={8}
            pb={8}
          >
            <Grid item color="#000094">
              <LockOutlinedIcon
                sx={{
                  fontSize: 76,
                }}
              />
            </Grid>
            <Grid item>
              <Typography color="#242627" fontSize={25} fontWeight={700}>
                Forgot your password or username?
              </Typography>
            </Grid>
            <Grid item mt={0.5}>
              <Typography color="#242627" fontSize={16} fontWeight={400}>
                Enter the email you registered with, and we&rsquo;ll send you an
                email with further instructions
              </Typography>
            </Grid>
            <Grid item mt={3}>
              <TextField
                placeholder="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                sx={{
                  m: 1,
                  width: "50ch",
                  border: "1px solid #b1b1b1",
                  borderRadius: 2,
                  input: {
                    color: "black",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon sx={{ color: "black" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <CustomButton
                text={loading ? "Loading..." : "Send Request"}
                onClick={handleSubmitEmail}
                disabled={loading}
              />
            </Grid>
            {error && (
              <Grid item>
                <Typography color="red" fontSize={16} fontWeight={400}>
                  {error}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

export default ForgotPasswordPopup
