import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthUserData from "./useAuthUserData";
import { FR_ROLE } from "../utils/constants";

const useAuthGuard = ({ requiredAdmin }: { requiredAdmin: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading } = useAuthUserData();

  useEffect(() => {
    if (!loading) {
      if (!user) {
        navigate("/", {
          state: `${location.pathname}${location.search}`,
        });
      } else if (requiredAdmin && user.rol === FR_ROLE.FIRST_RESPONDER_USER) {
        navigate("/SearchSubscriber");
      }
    }
  }, [user, loading]);
};

export default useAuthGuard;
