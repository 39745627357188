import useAuthGuard from "../../Hooks/useAuthGuard";
import useAuthUserData from "../../Hooks/useAuthUserData";
import { useLocation, useNavigate } from "react-router";
import { LayoutCenterContainer } from "../../Layout/layoutCenter.container";
import {
  Box,
  Container,
  Grid,
  Typography,
  styled as muStyled,
  useMediaQuery,
} from "@mui/material";
import HamburguerMenu from "../../components/HamburguerMenu";
import MemberCard from "../../components/MembersInfo/MemberCard";
import { CustomButton } from "../../components/CustomButton/CustomButton";

const BiometricAlternativeFaces = () => {
  useAuthGuard({
    requiredAdmin: false,
  });

  const MenuDiv = muStyled("div")({
    position: "absolute",
    top: "0",
    left: "0",
  });

  const location = useLocation();
  const navigate = useNavigate();
  const alternativeUsers = location.state || {};
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");

  const { user: frUser } = useAuthUserData();

  return (
    <LayoutCenterContainer
      children={
        <Container>
          <MenuDiv sx={{ mt: 2, ml: 2 }}>
            <Grid container direction="row" spacing={2}>
              <HamburguerMenu />
            </Grid>
          </MenuDiv>

          <Box
            sx={{
              color: "white",
              padding: 1,
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={1}
              mb={2}
            >
              <Grid item my={1} container direction="column">
                <Typography
                  color="white"
                  fontSize={20}
                  fontWeight={600}
                  fontFamily="Source Sans Pro"
                >
                  We’ve found {alternativeUsers.length} entries that may match
                  your request
                </Typography>
                <Typography
                  color="white"
                  fontSize={18}
                  fontWeight={400}
                  fontFamily="Source Sans Pro"
                >
                  Select a member to see the information
                </Typography>
              </Grid>

              {alternativeUsers.map((user: any) => (
                <MemberCard
                  key={user.id}
                  scanMethod={location?.state?.method}
                  member={user}
                  frId={frUser.id}
                  outlined
                  margin={isMobile ? "1rem 0px" : "1rem 1rem"}
                  minWidth={isMobile ? "unset" : "20rem"}
                  width={isTablet ? "20rem" : "100%"}
                  score={user.score}
                />
              ))}
              <Grid item my={1} container direction="column">
                <CustomButton
                  style={{
                    margin: "14px",
                    width: isMobile ? "100%" : "80%",
                  }}
                  text="Back to Scan Options"
                  onClick={() => navigate("/SearchSubscriber")}
                  sx={{ mt: 5, mb: 1 }}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      }
    ></LayoutCenterContainer>
  );
};

export default BiometricAlternativeFaces;
