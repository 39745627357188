import {
  Box,
  Button,
  createTheme,
  CssBaseline,
  styled as muStyled,
  ThemeProvider,
} from "@mui/material";
import QrReader from "react-qr-reader";
import XIco from "../../Assets/ico-x.svg";
import useAuthGuard from "../../Hooks/useAuthGuard";
import { ThemeBackgroundDark } from "../../Styles/theme";
import "./QrScan.css";

const Qrscan = (props: any) => {
  useAuthGuard({
    requiredAdmin: false,
  });
  const theme = createTheme(ThemeBackgroundDark);
  const StyledButtonQr = muStyled(Button)({
    width: 50,
    height: 50,
    borderRadius: "50%",
    backgroundColor: "#fff",
    padding: 35,
    border: "none",
  });

  const handlerReturn = () => {
    props.handlerReturn();
  };

  const onReadResult = (result: any) => {
    if (result) {
      props.handlerResult(result);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="qr-container">
        <QrReader
          onScan={onReadResult}
          onError={(error) => console.log("error QR", error)}
          className="qr-reader"
        />
        <div className="qr-close">
          <Box>
            <StyledButtonQr
              variant="contained"
              className="qr-button"
              onClick={handlerReturn}
            >
              <img src={XIco} alt="logo QR" className="logo-qr" />
            </StyledButtonQr>
          </Box>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Qrscan;
