import { FC  } from "react"

import Button from "@mui/material/Button"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import { useStepVerificationContext } from "../../contexts/StepVerificationContext"

const StepVerificationSuccess:FC = () => {
  const { setIsActive, handleClose, resetStatusDialog } = useStepVerificationContext();

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      pt={3}
      px={8}
      pb={8}
    >
      <Grid item color="#86E0A7">
        <CheckCircleOutlineIcon
          sx={{
            fontSize: 76,
          }}
        />
      </Grid>
      <Grid item>
        <Typography color="#242627" fontSize={25} fontWeight={700}>
          2-Step Verification successfully activated
        </Typography>
      </Grid>
      <Grid item mt={0.5} width={700}>
        <Typography color="#242627" fontSize={16} fontWeight={400} align="center">
          In addition to your password, each time you log in, you’ll need to enter the verification code sent to your email
        </Typography>
      </Grid>
      <Grid item mt={3}>
        <Button
          variant="outlined"
          sx={{
            color: "#000094",
            textTransform: "none",
            border: "1px solid rgba(0, 0, 148, 0.4)",
            fontWeight: 600
          }}
          onClick={() => {
            handleClose()
            resetStatusDialog()
            setIsActive(true)
          }}
        >
          Ok
        </Button>
      </Grid>
    </Grid>
  )
}

export default StepVerificationSuccess