import { FC, useEffect, useState } from "react";

import ReactInputVerificationCode from "react-input-verification-code";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import Box from "@mui/material/Box";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import Typography from "@mui/material/Typography";

import { useStepVerificationContext } from "../../contexts/StepVerificationContext";
import { useVerifyCode } from "../../Hooks/useFirstResponderService";
import { updateFirstResponderbyId } from "../../Services/firstResponderService";
import { sendVerificationCodeMFA } from "../../Services/emailService";

const StepVerificationDesactive: FC = () => {
  const { handleClose, setIsActive, userInfo } = useStepVerificationContext();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { isloading, status, handleClickButton } = useVerifyCode();
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusSend, setStatusSend] = useState({
    success: false,
    error: false,
  });

  const sendVerificationCode = async () => {
    setIsLoading(true);
    const customCode = Math.floor(10000 + Math.random() * 90000);
    await updateFirstResponderbyId(userInfo.id, {
      mfaCode: String(customCode),
    })
      .then(async (res: any) => {
        await sendVerificationCodeMFA(userInfo.email, String(customCode)).then(
          async (res: any) => {
            setStatusSend({
              success: true,
              error: false,
            });
          }
        );
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setIsLoading(false);
        setStatusSend({
          success: false,
          error: true,
        });
      });
  };

  const resendCode = async () => {
    sendVerificationCode();
  };
  const onSubmit: SubmitHandler<any> = async ({ code_confirmation }) => {
    handleClickButton!({
      code_confirmation,
      mfa: false,
      handleClose,
      setIsActive,
    });
  };

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      pt={3}
      px={8}
      pb={8}
    >
      <Grid item color="#000094">
        <ShieldOutlinedIcon
          sx={{
            fontSize: 76,
          }}
        />

        {/* <BlockOutlinedIcon sx={{
          fontSize: 46,
          position: 'absolute',
          bottom: '340px',
          left: '468px',
        }} /> */}
      </Grid>
      <Grid item>
        <Typography color="#242627" fontSize={25} fontWeight={700}>
          Deactivate 2-Step Verification
        </Typography>
      </Grid>

      <Grid item mt={0.5}>
        <Typography color="#6A7288" fontSize={15} fontWeight={500} mb={1}>
          We’ve sent you a confirmation code via email, please enter it in a
          fields below
        </Typography>
      </Grid>

      <Box
        component="form"
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="code_confirmation"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <ReactInputVerificationCode {...field} length={5} placeholder="" />
          )}
          rules={{
            required: true,
            minLength: 5,
          }}
        />

        {errors.code_confirmation?.type === "required" && (
          <Typography mt={1} color="#FC6868">
            Confirmation code is required
          </Typography>
        )}

        {errors.code_confirmation?.type === "minLength" && (
          <Typography mt={0.5} color="#FC6868">
            Minimum number of characters is 5
          </Typography>
        )}

        {status?.error && (
          <Typography mt={0.5} color="#FC6868">
            Invalid Code
          </Typography>
        )}

        <Typography
          align="center"
          color="#6A7288"
          fontSize={15}
          fontWeight={500}
          my={1}
        >
          {statusSend.success
            ? "We have resent the code to you"
            : "Didn’t receive a code?"}
          <LoadingButton
            loading={isLoading}
            variant="text"
            sx={{
              textTransform: "none",
              fontSize: "1rem",
              color: "#000094",
              textDecoration: "underline",
              minWidth: "38px",
            }}
            onClick={resendCode}
          >
            {statusSend.success ? "Ok" : "Re-Send"}
          </LoadingButton>
        </Typography>

        <LoadingButton
          loading={isloading}
          variant="contained"
          sx={{
            height: 48,
            width: 126,
            textTransform: "none",
            fontSize: "1rem",
            color: "#FFF",
            borderRadius: "8px",
            backgroundColor: "#000094",
            fontWeight: 700,
            mx: "auto",
          }}
          type="submit"
        >
          Deactivate
        </LoadingButton>
      </Box>
    </Grid>
  );
};

export default StepVerificationDesactive;
