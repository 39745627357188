/* eslint-disable react/no-unescaped-entities */
import { Alert, Box, Grid, Typography } from "@mui/material";
import { FC, ReactElement, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import successIcon from "../../Assets/successIcon.svg";
import PropTypes from "prop-types";
import { LayoutSign } from "../../Layout/layout-sign/layout-sign";
import { LayoutContainer } from "../../Layout/layout.container";
export const BoxNotificationEmail: FC<any> = (prop): ReactElement => {
  const navigate = useNavigate();

  return (
    <>
      {/* <Logo /> */}
      <Box
        bgcolor="primary.main"
        component="div"
        sx={{ backgroundColor: "white", borderRadius: 6 }}
        p={3}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item pb={2}>
            <img src={successIcon} width={64} height={64} />
            <Typography color="#000094" fontWeight={600} fontSize={41}>
              {prop.title}
            </Typography>
          </Grid>
          <Grid item pb={4} mt={3}>
            <Typography
              color="#232D42"
              fontWeight={400}
              fontSize={21}
              lineHeight={1}
            >
              {prop.elementLabel}
              {/* A recovery code has been sent to your email@domain.com.
                    Please check your email for further instructions. */}
            </Typography>
          </Grid>
          <Grid item >
            <Alert severity="warning" icon={false} sx={{ fontSize: 12, background: '#fff6d7' }}>
              If you do not see the email in a few minutes, check your  <b>"Junk Mail"</b> or <b>"Spam"</b> folder and add the MyMedHistory's email to your <b>"Safe Sender List." </b>If nothing helped, please try submitting the form again.
            </Alert>
          </Grid>
          <Grid item xs mx={2}>
            <Typography color="#232D42" fontWeight={400} fontSize={16}>
              Questions?
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing={0.5}
            justifyContent="center"
          >
            <Grid item>
              <Typography color="#232D42" fontWeight={700} fontSize={16}>
                Contact our support
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="#3A57E8" fontWeight={400} fontSize={16}>
                contact@gmail.com
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* <CustomButton
              text="Back to login"
              isLoading={isLoading}
              sx={{ mt: 5, mb: 1 }}
              onClick={goToLogin}
            /> */}
    </>
  );
};

BoxNotificationEmail.propTypes = {
  title: PropTypes.string,
  elementLabel: PropTypes.element,
};
export default BoxNotificationEmail;
