import { FC, useState } from "react"
import DashboardLayout from "../../components/Common/DashboardLayout"
import { CustomCard } from "../../components/CustomCard/CustomCard"
import { StepVerificationContextProvider } from "../../contexts/StepVerificationContext"
import useAuthGuard from "../../Hooks/useAuthGuard"
import useAuthUserData from "../../Hooks/useAuthUserData"
import { sendVerificationCodeMFA } from "../../Services/emailService"
import { updateFirstResponderbyId } from "../../Services/firstResponderService"

const Settings: FC = () => {
  useAuthGuard({
    requiredAdmin: true,
  })
  const { user, loading, cognitoUser } = useAuthUserData()
  const [changePassDialog, setChangePassDialog] = useState(false)
  const [successfulChangeDialog, setSuccessfulChangeDialog] = useState(false)

  const sendVerificationCode = async () => {
    const customCode = Math.floor(10000 + Math.random() * 90000)
    await updateFirstResponderbyId(user.id, {
      mfaCode: String(customCode),
    })
      .then(async (res: any) => {
        await sendVerificationCodeMFA(user.email, String(customCode)).then(
          async (res: any) => {}
        )
      })
      .catch((err: any) => {
        console.error(err)
      })
  }

  return (
    <DashboardLayout page="settings">
      <StepVerificationContextProvider>
        <CustomCard
          icon="AdminPanelSettings"
          title="2-Step Verification"
          subtitle="Prevent hackers from accesing your account with an additional layer of security"
          badge={true}
          buttonText="Active"
          nameDialog="step-verification"
          onSubmit={sendVerificationCode}
        />
        <CustomCard
          icon="AccountCircle"
          title="Edit Profile"
          subtitle="Update your personal and contact information"
          badge={false}
          buttonText="Edit"
          nameDialog="edit-profile"
        />
        <CustomCard
          icon="Lock"
          title="Change Password"
          subtitle="Change your password from time to time to ensure its security"
          badge={false}
          buttonText="Change"
          nameDialog="change-password"
        />
      </StepVerificationContextProvider>
    </DashboardLayout>
  )
}

export default Settings
