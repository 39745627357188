import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import CircleIcon from "@mui/icons-material/Circle"
import SearchIcon from "@mui/icons-material/Search"
import Button from "@mui/material/Button"
import InputAdornment from "@mui/material/InputAdornment"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import { FC, MouseEvent, useState } from "react"
import { STATUSES, StatusesType } from "../../../components/ImportUsers/PreviewResults/PreviewResults"
import Typography from "@mui/material/Typography"
import Box from '@mui/material/Box';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';


interface Props {
  status: StatusesType
  setStatus: (status: StatusesType) => void
  searchInput: string
  setSearchInput: (searchInput: string) => void
  validUsers: number
  invalidUsers: number
  duplicatesUsers: number
}

const ImportUsersFilter: FC<Props> = ({
  status,
  setStatus,
  searchInput,
  setSearchInput,
  validUsers,
  invalidUsers,
  duplicatesUsers
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openNewUserDialog, setOpenNewUserDialog] = useState(false)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2} >
        <Box gridColumn="span 2" textAlign={"left"}>
            <Button
            variant="contained"
            disableRipple
            disableElevation
            sx={{
              color: "black",
              backgroundColor: "white",
              textTransform: "none",
              ":hover": {
                backgroundColor: "#F0F0F0",
              },
            }}
            onClick={handleClick}
            endIcon={!open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          >
            {STATUSES[status].label}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                backgroundColor: "white",
                color: "black",
              },
            }}
          >
            {Object.keys(STATUSES).map((key) => (
              <MenuItem
                key={key}
                value={key}
                onClick={() => {
                  setStatus(key as StatusesType)
                  handleClose()
                }}
              >
                {STATUSES[key as StatusesType].label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box gridColumn="span 6" textAlign={"left"}>
          <Typography color="#242627" fontSize={15} >
            <RadioButtonUncheckedIcon style={{position: "relative" , top : "5px", color : "#9D9D9D"}}/> Total: {validUsers + invalidUsers + duplicatesUsers}&nbsp;
            <RadioButtonUncheckedIcon style={{position: "relative" , top : "5px", color : "#01BB4B"}}/> Valid: {validUsers} &nbsp;
            <RadioButtonUncheckedIcon style={{position: "relative" , top : "5px", color : "#FC6868"}}/> Invalid: {invalidUsers} &nbsp;
            <RadioButtonUncheckedIcon style={{position: "relative" , top : "5px", color : "#FF8642"}}/> Duplicates: {duplicatesUsers} 
          </Typography>
        </Box>
        <Box gridColumn="span 4" textAlign={"right"}>
        <TextField
        placeholder="Search..."
        size="small"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        sx={{
          width: "200px",
          color: "black",
          backgroundColor: "white",
          mx: 2,
          "& fieldset": { border: "none" },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                sx={{
                  color: "#000094",
                }}
              />
            </InputAdornment>
          ),
        }}
        inputProps={{ style: { color: "black" }, color: "black" }}
      />
        </Box>
      </Box>
    </>
  )
}

export default ImportUsersFilter
