import { DataGrid, GridColDef, GridComparatorFn } from "@mui/x-data-grid";
import { FC, useState } from "react";

const columns: GridColDef[] = [
  {
    field: "dateOfScan",
    headerName: "Date of Scan",
    flex: 2,
    valueGetter: (params) => {
      const date = new Date(params.row.createdAt);
      return `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`;
      // return `${date.getMonth()+1}.${Math.floor(Math.random() * 10) + 2}.${date.getFullYear()}`
    },
    sortComparator: (a: string, b: string) =>
      new Date(
        `${a.split(".")[2]}-${a.split(".")[0]}-${a.split(".")[1]}`
      ).getTime() -
      new Date(
        `${b.split(".")[2]}-${b.split(".")[0]}-${b.split(".")[1]}`
      ).getTime(),
  },
  {
    field: "timeOfScan",
    sortable: false,
    headerName: "Time of Scan",
    flex: 2,
    valueGetter: (params) => {
      const date = new Date(params.row.createdAt);
      return `${
        date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
      }:${
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
      } ${date.getHours() > 12 ? "pm" : "am"}`;
    },
  },
  {
    field: "subscriberName",
    sortable: false,
    headerName: "Subscriber Name",
    flex: 2,
    valueGetter: (params) => {
      return `${params.row.subscriber.firstName} ${
        params.row.subscriber.lastName || ""
      }`;
    },
  },
  {
    field: "subscriberMMHID",
    sortable: false,
    headerName: "Subscriber MMH ID",
    flex: 2,
    valueGetter: (params) => {
      return `${params.row.subscriber.accessCode}`;
    },
  },
  {
    field: "method",
    sortable: false,
    headerName: "Type of Scan",
    flex: 2,
    valueGetter: (params) => {
      const methods: any = {
        MMHID: "MMH ID",
        biometric: "Biometrics",
        QR: "QR",
      };
      return methods?.[params.row.method] ?? "N/A";
    },
  },
];

interface Props {
  scans?: any[];
  isloading: boolean;
}

const ScanHistoryTable: FC<Props> = ({ scans, isloading }) => {
  const [pageSize, setPageSize] = useState(10);

  return (
    <DataGrid
      sx={{
        color: "black",
        border: 0,
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        ".MuiDataGrid-row": {
          borderRadius: "4px",
          backgroundColor: "#FFFFFF",
        },
        ".MuiDataGrid-columnHeaders": {
          borderRadius: "4px",
          backgroundColor: "#E0E2E9",
        },
        "& .MuiCheckbox-root": {
          color: "#000094",
        },
        ".MuiDataGrid-columnHeaderTitle": {
          fontWeight: 400,
        },
      }}
      loading={isloading}
      rows={scans ?? []}
      columns={columns}
      disableColumnMenu
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 20, 50, 100]}
      autoHeight
      pagination
      getRowSpacing={(_) => {
        return {
          top: 6,
        };
      }}
      disableSelectionOnClick
    />
  );
};

export default ScanHistoryTable;
