import { useState } from "react"
import { FirstResponderData } from "../../Interfaces/FirstResponder"
import MapColumns from "./MapColumns"
import PreviewResults from "./PreviewResults"
import UploadFile from "./UploadFile"

const ImportUsers = () => {
  const [step, setStep] = useState(0)
  const [file, setFile] = useState<File | null>(null)
  const [firstResponders, setFirstResponders] = useState<FirstResponderData[]>(
    []
  ) // Estos son los que se mapean del archivo que se sube

  const nextStep = () => {
    setStep(step + 1)
  }
  const prevStep = () => {
    setStep(step - 1)
  }

  switch (step) {
    case 0:
      return (
        <UploadFile
          nextStep={nextStep}
          prevStep={prevStep}
          file={file}
          setFile={setFile}
        />
      )
    case 1:
      return (
        <MapColumns
          nextStep={nextStep}
          prevStep={prevStep}
          file={file}
          setFirstResponders={setFirstResponders}
        />
      )
    case 2:
      return (
        <PreviewResults
          nextStep={nextStep}
          prevStep={prevStep}
          firstResponders={firstResponders}
          setFirstResponders={setFirstResponders}
        />
      )
    default:
      return <div>Unimplemented step</div>
  }
}

export default ImportUsers
