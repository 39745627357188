import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import GroupAddIcon from "@mui/icons-material/GroupAdd"
import LoadingButton from "@mui/lab/LoadingButton"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import { FC } from "react"
import { useNavigate } from "react-router-dom"

interface Props {
  users: number
  open: boolean
  handleClose: () => void
  bulk: boolean
  success: boolean
  loading: boolean
  handleSubmit: (invite: boolean) => void
}

const SendImportInvitesDialog: FC<Props> = ({
  bulk,
  users,
  open,
  success,
  loading,
  handleClose,
  handleSubmit,
}) => {
  const navigate = useNavigate()

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose()
        if (success) navigate("/admin/manageUsers")
      }}
      fullWidth
      maxWidth="md"
    >
      <Grid container direction="column" alignItems="center">
        <Grid item container p={2} direction="row-reverse">
          <IconButton
            onClick={() => {
              handleClose()
              if (success) navigate("/admin/manageUsers")
            }}
            sx={{ color: "#000094" }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
        {success ? (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            pt={3}
            px={8}
            pb={8}
          >
            <Grid item color="#86E0A7">
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: 76,
                }}
              />
            </Grid>
            <Grid item>
              <Typography color="#242627" fontSize={25} fontWeight={700}>
                {bulk
                  ? "Users imported & invited successfully"
                  : "Users imported successfully"}
              </Typography>
            </Grid>
            <Grid item mt={0.5}>
              <Typography color="#242627" fontSize={16} fontWeight={400}>
                {bulk
                  ? "Invitation email with login credentials has been sent to all user." +
                    "You can edit and and track their status from the User Management screen."
                  : "You can edit and invite them from the User Management screen"}
              </Typography>
            </Grid>
            <Grid item mt={3}>
              <Button
                variant="outlined"
                sx={{
                  color: "#000094",
                  textTransform: "none",
                  border: "1px solid rgba(0, 0, 148, 0.4)",
                }}
                onClick={() => {
                  navigate("/admin/manageUsers")
                }}
              >
                Go to User Management
              </Button>
            </Grid>
            <Grid item mt={3}>
              <Button
                variant="text"
                sx={{
                  color: "#000094",
                  textTransform: "none",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  window.location.reload()
                }}
              >
                Import More Users
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            pt={3}
            px={8}
            pb={8}
          >
            <Grid item color="#000094">
              <GroupAddIcon
                sx={{
                  fontSize: 76,
                }}
              />
            </Grid>
            <Grid item>
              <Typography color="#242627" fontSize={25} fontWeight={700}>
                {bulk ? "Import & Invite Users" : "Import Users"}
              </Typography>
            </Grid>
            <Grid item mt={0.5}>
              <Typography
                color="#242627"
                fontSize={16}
                fontWeight={400}
                textAlign={"center"}
              >
                {bulk
                  ? "All imported users will be added to the User Management and automatically invited. Invitation email with login credentials will be send to all newly imported users."
                  : "All imported users will be added to the User Management section not invited. You’ll be able to send them invitation letter manually. Until then they will stay in a “New” status."}
              </Typography>
            </Grid>
            <Grid item mt={0.5}>
              <Typography color="#242627" fontSize={16} fontWeight={600}>
                Users to invite:{" "}
                <span
                  style={{
                    color: "#000094",
                  }}
                >
                  {users}
                </span>
              </Typography>
            </Grid>
            <Grid item mt={3}>
              <LoadingButton
                loading={loading}
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: "#000094",
                  textTransform: "none",
                  padding: "8px 20px",
                }}
                onClick={() => handleSubmit(bulk)}
              >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Dialog>
  )
}

export default SendImportInvitesDialog
