import BlockIcon from "@mui/icons-material/Block";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { FC, useEffect } from "react";
import { useUpdateStatusFirstResponder } from "../../Hooks/useFirstResponderService";

interface Props {
  bulk: boolean;
  users: any[];
  open: boolean;
  handleClose: () => void;
}

const BlockDialog: FC<Props> = ({ bulk, users, open, handleClose }) => {
  const { handleClickButton, response } = useUpdateStatusFirstResponder(
    users.map((x) => x.id).join(","),
    "blocked"
  );

  useEffect(() => {
    if (response && response.affected && response.affected > 0) {
      let message =
        "?advice=blocker&message=" +
        users
          .slice(0, 2)
          .map((x) => x.firstName + " " + x.lastName)
          .join(", ");
      let lengthMessage =
        response.affected >= 3
          ? " and " + (response.affected - 2) + " more "
          : "";
      window.location.replace(window.location.href + message + lengthMessage);
    }
  }, [response]);

  //TODO: send invitation should not send invitation to "active" users

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="md"
    >
      <Grid container direction="column" alignItems="center">
        <Grid item container p={2} direction="row-reverse">
          <IconButton
            onClick={() => {
              handleClose();
            }}
            sx={{ color: "#000094" }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          pt={3}
          px={8}
          pb={8}
        >
          <Grid item color="#000094">
            <BlockIcon
              sx={{
                fontSize: 76,
              }}
            />
          </Grid>
          <Grid item>
            <Typography color="#242627" fontSize={25} fontWeight={700}>
              Confirm User Block
            </Typography>
          </Grid>
          <Grid item mt={0.5}>
            <Typography color="#242627" fontSize={16} fontWeight={400}>
              Access to the system for this user will be suspended
            </Typography>
          </Grid>
          <Grid item mt={0.5}>
            <Typography color="#384057" fontSize={16} fontWeight={500}>
              User to block:{" "}
              <label style={{ color: "#000094" }}>
                {users.length === 1
                  ? `${users[0].firstName} ${users[0].lastName}`
                  : `${users.length}`}
              </label>
            </Typography>
          </Grid>
          <Grid item mt={3}>
            <LoadingButton
              loading={false}
              size="large"
              variant="contained"
              sx={{
                color: "#FFF",
                textTransform: "none",
                border: "1px solid rgba(0, 0, 148, 0.4)",
              }}
              onClick={() => {
                handleClickButton!();
                handleClose();
              }}
            >
              Confirm
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default BlockDialog;
