import Grid from "@mui/material/Grid"
import { FC, useEffect, useState } from "react"
import DashboardLayout from "../../components/Common/DashboardLayout";
import ScanHistoryTable from "../../components/ScanHistoryTable";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getScans } from "../../Services/scansService";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

const ScanHistory: FC = () => {
  const params = new URLSearchParams(location.search)
  const [filterDate, setFilterDate] = useState<Date | null>(null)
  const [loading, setLoading] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const [scans, setScans] = useState([])
  const [filteredScans, setFilteredScans] = useState([])


  useEffect(() => {
    getScansById()
  }, [])

  useEffect(() => {
    filterScans()
  }, [searchInput])

  useEffect(() => {
    filterScansByDate()
  }, [filterDate])

  useEffect(() => {
    setFilteredScans(scans)
  }, [scans])

  const filterScansByDate = () => {
    let newArr
    newArr = scans.filter((scan: any) => {
      if (!filterDate) return true
      const tempDate = new Date(scan.createdAt)
      return (
        tempDate.getDate() === filterDate.getDate() &&
        tempDate.getMonth() === filterDate.getMonth() &&
        tempDate.getFullYear() === filterDate.getFullYear()
      )
    })
    setFilteredScans([...newArr])
  }

  const filterScans = () => {
    let newArr
    if (searchInput) {
      newArr = scans.filter((scan: any) => {
        return (scan.subscriber.firstName !== null ? scan.subscriber.firstName.toUpperCase().includes(searchInput.toUpperCase()) : false) || (scan.subscriber.lastName !== null ? scan.subscriber.lastName.toUpperCase().includes(searchInput.toUpperCase()) : false) || (scan.subscriber.accessCode !== null ? scan.subscriber.accessCode.toUpperCase().includes(searchInput.toUpperCase()) : false)

      })
      setFilteredScans([...newArr])
    }
    else {
      setFilteredScans([...scans])
    }
  }
  const getScansById = async () => {
    setLoading(true)
    const request: any = await getScans(String(params.get("id")))
    console.log('request', request.data)
    setScans(request.data)
    setLoading(false)
  }
  return (
    <DashboardLayout page="scanHistory">
      <div style={{ display: 'flex', flexDirection: 'row', verticalAlign: 'center', marginBottom: '24px' }}>
        <div>
          <TextField
            placeholder="Search..."
            style={{ height: '48px', borderRadius: '8px', marginLeft: '0px' }}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            sx={{
              width: "400px",
              color: "black",
              backgroundColor: "white",
              mx: 2,
              "& fieldset": { border: "none" },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      color: "#000094",
                    }}
                  />
                </InputAdornment>
              ),
            }}
            inputProps={{ style: { color: "black", fontSize: 15 }, color: "black" }}
          />
        </div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Search by date of scan"
              inputFormat="MM/dd/yyyy"
              onChange={setFilterDate}
              value={filterDate}
              InputProps={{
                disableUnderline: true
              }}
              renderInput={(params: any) => (
                <TextField
                  variant="standard"
                  {...params}
                  sx={{
                    borderRadius: '8px'
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
      <Grid container direction="column" rowSpacing={3} alignItems="stretch">

        <Grid item>
          <ScanHistoryTable
            scans={filteredScans}
            isloading={loading}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}

export default ScanHistory
