/* eslint-disable react/prop-types */
import styles from './videoCard.module.css'
import { BsFillPlayFill } from 'react-icons/bs'
import { Modal } from 'semantic-ui-react'
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

const VideoCard = ({video} : any) => {
  const [openVideoPlayer, setOpenVideoPlayer] = useState(false)

  const handleClick = () => {
    setOpenVideoPlayer(true)
  }

  const handleClose = () => {
    setOpenVideoPlayer(false)
  }

  function getVideoId(url : any) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
}

  return (
    <div className={styles.videoCard}>
      <div className={styles.videoCardVideo}>
        <div className={styles.videoCardVideoPlayBtn} onClick={handleClick}>
          <BsFillPlayFill style={{position: "absolute"}}/>
        </div>
      </div>
      <div className={styles.videoCardSummary}>
        <div className={styles.videoCardSummaryCategory}>
          {video.category.toUpperCase()}
        </div>
        <div className={styles.videoCardSummaryTitle}>{video.title}</div>
        <div className={styles.videoCardSummaryDescription}>
          {video.description}
        </div>
        <div className={styles.videoCardSummaryDate}>
          Uploaded {new Date(video.createdAt).toDateString()}
        </div>
      </div>
      <Modal open={openVideoPlayer} onClose={handleClose} basic >
        <div className={styles.videoPlayer}>
          <CloseIcon onClick={handleClose} style={{position: "relative", left: "100%"}}  />
          <iframe
            className={styles.videoPlayerFrame}
            width="720"
            height="460"
            src={`https://www.youtube.com/embed/${getVideoId(video.url)}?feature=oembed&autoplay=1`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </Modal>
    </div>
  )
}

export default VideoCard
