import { Box, Container, Grid, TextField } from "@mui/material";
import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import LabelAux from "../components/Informatives/LabelAux";

import { Button, styled as muStyled } from "@mui/material";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ArrowRightIco from "../Assets/ico-arrow-right.svg";
import QRIco from "../Assets/ico-QR.svg";
import HamburguerMenu from "../components/HamburguerMenu/Hamburguer";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import useAuthGuard from "../Hooks/useAuthGuard";
import { FirstResponderData } from "../Interfaces/FirstResponder";
import { LayoutCenterContainer } from "../Layout/layoutCenter.container";
import { isValidStripeSubscription } from "../Services/membershipService";
import {
  getRekognitionSession,
  getUserIDByAccessCode,
} from "../Services/userService";
import Alert from "@mui/material/Alert";
import useAuthUserData from "../Hooks/useAuthUserData";
import { CustomButton } from "../components/CustomButton/CustomButton";

export const SearchSubscriber: FC<any> = (): ReactElement => {
  useAuthGuard({
    requiredAdmin: false,
  });

  const location = useLocation();
  const firstResponderEmail = location.state as string;
  const [yupSchema, setYupSchema] = useState<any>(null);
  const [firstResponder, setFirstResponder] = useState<FirstResponderData>();
  const [isLoadingData, setLoadingData] = useState<boolean>(false);
  const [isQr, setQr] = useState<boolean>(false);
  // useBackListener(() => setQr(false));
  const navigate = useNavigate();
  const ref = useRef(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const CANCELED_PLAN = "This account is currently inactive";
  const MMHID_NOT_FOUND =
    "We did not find any subscriber associated with this QR code";
  const ERROR = "Something went wrong, try again later";
  const [alertMessage, setAlertMessage] = useState<string>(MMHID_NOT_FOUND);

  const idMmhdLabel = (
    <LabelAux
      content={"Type in the subscribers personal MMH ID number"}
    ></LabelAux>
  );

  const StyledButtonQr = muStyled(Button)({
    width: 100,
    height: 100,
    borderRadius: "50%",
    backgroundColor: "#fff",
    padding: 50,
    border: "none",
  });

  const StyledButton = muStyled(Button)({
    width: "100%",
    height: 48,
    textTransform: "none",
    fontSize: "1rem",
    color: "#fff",
    borderRadius: "8px",
    backgroundColor: "#000094",
  });

  const MenuDiv = muStyled("div")({
    position: "absolute",
    top: "0",
    left: "0",
  });

  const SectionTitle = muStyled("h3")({
    color: "white",
    fontSize: "1.5rem",
    fontWeight: "600",
  });

  const SectionBox = muStyled("div")({
    borderRadius: "20px",
    border: "solid 1px #FFFFFF33",
    padding: "24px",
    width: "400px",
  });

  const OrBlock = muStyled("div")({
    color: "#BBBBBB",
    fontSize: "19px",
    margin: "12px 0px",
  });

  const formik = useFormik({
    initialValues: { id: "" },
    validationSchema: yupSchema,
    onSubmit: (formData) => { },
  });

  const loadForm = async () => {
    const yupDefinition: any = {};
    yupDefinition.id = Yup.string().required();

    const schema = Yup.object().shape(yupDefinition);
    setYupSchema(schema);
  };

  const handlerNavigate = (url: string, state?: any) => {
    navigate(url, state);
  };

  const handlerSubmitQr = () => {
    setLoadingData(false);
    handlerNavigate("/QRScanCode");
  };

  const handlerSubmitId = () => {
    formik.submitForm().then(async () => {
      if (formik.isValid && formik.values.id.length > 0) {
        await saveDataSubscriber({
          mmhid: formik.values.id,
        });
      }
    });
  };

  const handlerScanFace = () => {
    setLoadingData(false);
    handlerNavigate("/ScanFace");
  };

  const handlerChangeId = (e: any) => {
    formik.setValues({
      ...formik.values,
      id: e.target.value,
    });
  };

  const saveDataSubscriber = async ({ userid = "", mmhid = "" }) => {
    try {
      setQr(false);
      setLoadingData(true);
      setShowAlert(false);

      if (!mmhid) return;

      const idSubscriber = await getUserIDByAccessCode(mmhid);

      if (!idSubscriber) {
        setAlertMessage(MMHID_NOT_FOUND);
        setShowAlert(true);
        return;
      }

      //Validate if the holder user has an active subscription in the system
      const { isValid } = await isValidStripeSubscription(idSubscriber);

      if (isValid) {
        let url = `/subscriberInfo?mmh_id=MMH${mmhid}`;
        url += userid != "" ? `&id=${userid}` : "";

        handlerNavigate(url, { state: { method: "MMHID" } });
      } else {
        setAlertMessage(CANCELED_PLAN);
        setShowAlert(true);
      }
    } catch (err) {
      console.log(
        "🍉 ~ file: SearchSubscriber.tsx:162 ~ saveDataSubscriber ~ err:",
        err
      );
      setAlertMessage(ERROR);
      setShowAlert(true);
    } finally {
      setLoadingData(false);
      formik.resetForm();
    }
  };

  React.useEffect(() => {
    loadForm();
  }, []);

  return (
    <>
      {!isQr && (
        <LayoutCenterContainer
          children={
            <Container>
              <MenuDiv sx={{ mt: 2, ml: 2 }}>
                <Grid container direction="row" spacing={2}>
                  <HamburguerMenu />
                </Grid>
              </MenuDiv>
              {!isLoadingData && !isQr && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <SectionBox>
                    <SectionTitle>Scan the QR code</SectionTitle>
                    <div>
                      <Box sx={{ position: "relative" }}>
                        <StyledButtonQr
                          variant="contained"
                          className="qr-button"
                          onClick={handlerSubmitQr}
                        >
                          <img src={QRIco} alt="logo QR" className="logo-qr" />
                        </StyledButtonQr>
                      </Box>
                    </div>
                  </SectionBox>

                  <OrBlock>OR</OrBlock>

                  <div
                    style={{
                      borderRadius: "20px",
                      border: "solid 1px #FFFFFF33",
                      padding: "24px",
                      width: "400px",
                    }}
                  >
                    <SectionTitle>Enter MMH ID Number</SectionTitle>

                    <div>
                      {showAlert && (
                        <Grid
                          container
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={10} md={10} pb={1}>
                            <Alert severity="warning">{alertMessage}</Alert>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container>
                        <Grid item xs={10} md={10} pr={1}>
                          <TextField
                            fullWidth
                            className="MiuTextField full-radius"
                            placeholder="MMH ID Number"
                            variant="outlined"
                            size="small"
                            onChange={handlerChangeId}
                            error={Boolean(formik.errors.id)}
                          />
                          {idMmhdLabel}
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <StyledButton
                            variant="contained"
                            onClick={handlerSubmitId}
                            sx={{ m: 0 }}
                          >
                            <img
                              src={ArrowRightIco}
                              alt="logo QR"
                              className="logo-qr"
                            />
                          </StyledButton>
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                  <OrBlock>OR</OrBlock>

                  <SectionBox>
                    <SectionTitle>Use Facial Recognition</SectionTitle>
                    <CustomButton
                      text="Scan Member's Facial"
                      type="button"
                      onClick={handlerScanFace}
                    ></CustomButton>
                  </SectionBox>
                </Box>
              )}
              {isLoadingData && !isQr && (
                <LoadingIndicator titleBefore="Logging in as a First Responder" />
              )}
            </Container>
          }
        ></LayoutCenterContainer>
      )}
      {/* {isQr && (
        <LayoutContainer
          children={
            <Container>
              {isQr && (
                <Qrscan
                  handlerResult={handlerQrResult}
                  handlerReturn={handlerQrReturn}
                />
              )}
            </Container>
          }
        ></LayoutContainer>
      )} */}
    </>
  );
};

export default SearchSubscriber;
