import { styled as muStyled } from "@mui/material"
import { LayoutMobile } from "./layout.mobile"

const LayoutWrapper = muStyled("main")(() => ({
  display: "flex",
  flex: "1",
  overflow: "hidden",
}))

const LayoutContainer = muStyled("div")({
  display: "flex",
  flex: "1",
  overflow: "hidden",
})

const LayoutContent = muStyled("div")({
  flex: "1",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "> *": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
})

export const Layout: React.FC<any> = ({ children }) => {
  return (
    <>
      {/* <NavBar /> */}
      <LayoutWrapper>
        <LayoutContainer className="full-height">
          <LayoutContent>
            <LayoutMobile>{children}</LayoutMobile>
          </LayoutContent>
        </LayoutContainer>
      </LayoutWrapper>
    </>
  )
}
