export const USERS_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/users"
    : ""
}/users/`

export const VACCINES_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/vaccines"
    : ""
}/vaccines/`

export const ALLERGIES_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/allergies"
    : ""
}/allergies/`

export const SURGERIES_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/surgeries"
    : ""
}/surgeries/`

export const SUPPLEMENTS_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/supplements"
    : ""
}/supplements/`

export const MEDICATIONS_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/medications"
    : ""
}/medications/`

export const DISABILITIES_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/disabilities"
    : ""
}/disabilities/`

export const PETS_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/pets"
    : ""
}/pets/`

export const MEDICAL_CONDITION_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/medicalconditions"
    : ""
}/medicalconditions/`

export const FIRST_MEDICAL_CONDITION_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/firstmedicalconditions"
    : ""
}/firstmedicalconditions/`

export const DOCUMENTS_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/documents"
    : ""
}/documents/`

export const VIDEOS_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/videos"
    : ""
}/videos/`

export const EMAIL_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/customemail"
    : ""
}/customemail/`

export const MEMBERSHIP_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/membership"
    : ""
}/membership/`

export const PROMOTIONS_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/promotions"
    : ""
}/promotions/`

export const FIRSTRESPONDER_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/firstresponder"
    : ""
}/firstresponder/`

export const SCANS_API = `${
  (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local") ||
  !process.env.REACT_APP_STAGE
    ? "/scans"
    : ""
}/scans/`
