import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined"
import Grid from "@mui/material/Grid"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Typography from "@mui/material/Typography"
import { FC } from "react"

interface Props {
  matched: boolean
  header: string
  setMapping: (fileColumn: string | null) => void
  mapping?: string | null
  previews: string[]
  options: string[]
}

const MappedRow: FC<Props> = ({
  matched,
  header,
  setMapping,
  mapping,
  previews,
  options,
}) => {
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      textAlign="left"
      alignItems="center"
      py={2.5}
    >
      <Grid
        item
        xs={1}
        sx={{
          color: matched ? "#86E0A7" : "#999999",
        }}
        pl={2}
      >
        <CheckCircleOutlineOutlinedIcon />
      </Grid>
      <Grid item xs={3}>
        <Typography fontSize={14}>{header}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Select
          value={mapping}
          onChange={(e) => {
            if (e.target.value === "empty") {
              setMapping(null)
            } else setMapping(e.target.value as string)
          }}
          renderValue={(value) => {
            return value ? (
              <>{value}</>
            ) : (
              <Grid sx={{ color: "#aaa" }}>Please select</Grid>
            )
          }}
          displayEmpty
          sx={{
            width: "80%",
          }}
        >
          <MenuItem
            value="empty"
            dense
            sx={{
              color: "#aaa",
            }}
          >
            Unselect
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={4}>
        <Typography fontSize={14}>
          {previews[0]} <br /> {previews[1]}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default MappedRow
