import { MEMBERSHIP_API } from "./CONSTANTS";
import customAxios from "./customAxios";

export const isValidStripeSubscription = (
  userId: string
): Promise<{ isValid: boolean } | any> => {
  return new Promise((resolve, reject) => {
    try {
      customAxios
        .get(`${MEMBERSHIP_API}${userId}/isValidStripeSubscription`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("🍉 ~ file: membershipService.tsx:13 err:", err);
          reject(err);
        });
    } catch (error) {
      console.log("🍉 ~ file: membershipService.tsx:18 error:", error);
      reject(error);
    }
  });
};
