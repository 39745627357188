import { FC, useEffect, useState } from "react"

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import LoadingButton from "@mui/lab/LoadingButton"
import Dialog from "@mui/material/Dialog"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"

import { useDeleteFirstResponder } from "../../Hooks/useFirstResponderService"

interface Props {
  users: any[]
  open: boolean
  handleClose: () => void
}

const DeleteUsersDialog: FC<Props> = ({ users, open, handleClose }) => {
  const { handleClickButton, isloading, response } =
    useDeleteFirstResponder(users)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (!isloading) {
      // Error on delete
      if (Object.keys(response).length > 1) {
        setError(
          "You can't delete this user because he scanned a QR code at least once. If you want to remove his access to the app, you can block the user instead."
        )
      } else {
        handleClose()
      }
    }
  }, [isloading])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Grid container direction="column" alignItems="center">
        <Grid item container p={2} direction="row-reverse">
          <IconButton onClick={handleClose} sx={{ color: "#000094" }}>
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          pt={3}
          px={8}
          pb={8}
        >
          <Grid item color="#000094">
            <DeleteOutlineOutlinedIcon
              sx={{
                fontSize: 76,
              }}
            />
          </Grid>
          <Grid item>
            <Typography color="#242627" fontSize={25} fontWeight={700}>
              Confirm User{users.length !== 1 && "s"} Deletion
            </Typography>
          </Grid>
          <Grid item mt={0.5}>
            <Typography color="#242627" fontSize={16} fontWeight={400}>
              Selected user{users.length !== 1 && "s"} will be deleted from the
              system and won&rsquo;t be able to login anymore
            </Typography>
          </Grid>
          <Grid item mt={0.5}>
            <Typography color="#242627" fontSize={16} fontWeight={600}>
              Users to delete:{" "}
              <span
                style={{
                  color: "#000094",
                }}
              >
                {users.length === 1
                  ? `${users[0].firstName} ${users[0].lastName}`
                  : `${users.length}`}
              </span>
            </Typography>
          </Grid>
          <Grid item mt={3}>
            <LoadingButton
              loading={isloading}
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#000094",
                textTransform: "none",
                padding: "8px 20px",
              }}
              onClick={() => {
                handleClickButton!()
              }}
            >
              Confirm
            </LoadingButton>
          </Grid>
          <Grid item mt={2}>
            {error && (
              <Typography color="#ff0000" fontSize={12} fontWeight={600}>
                {error}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DeleteUsersDialog
