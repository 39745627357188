import { FC, useEffect, useState } from "react";
import MembersInfo from "../../components/MembersInfo";
import AlertMessageQRScan from "../../components/AlertMessage/AlertMessageQRScan";
import useAuthGuard from "../../Hooks/useAuthGuard";
import useAuthUserData from "../../Hooks/useAuthUserData";
import { useGetID, useQuery } from "./../../Hooks/useSuscribersService";
import { registerScan } from "./../../Services/scansService";
import { useLocation } from "react-router-dom";

const PersonalInfo = () => {
  useAuthGuard({
    requiredAdmin: false,
  });

  const { user } = useAuthUserData();
  const [isScanRegistered, setIsScanRegistered] = useState(false);
  const [registerId, setRegisterId] = useState(null)
  let query = useQuery();
  const navState = useLocation()
  try {
    const { idSuscriber, loadUser } = useGetID(
      query.get("mmh_id")!.toLowerCase().replaceAll("mmh", "")
    );

    useEffect(() => {
      const putScan = async () => {
        if (idSuscriber && user && !isScanRegistered) {
          const register: any = await registerScan(user.id, {
            idSubscriber: idSuscriber,
            type: 'Family Members',
            method: navState?.state?.method,
          });
          setRegisterId(register.id)
          setIsScanRegistered(true);
        }
      };
      putScan();
    }, [idSuscriber, user]);

    if (loadUser) return <div>Loading...</div>;
    if (!idSuscriber)
      return (
        <div>
          <AlertMessageQRScan open={true} advice={"subscriberNotFound"} />
        </div>
      );

    return (
      <div>
        <MembersInfo registerId={registerId} scanMethod={navState?.state?.method} idUser={idSuscriber} />
      </div>
    );
  } catch (err) {
    return (
      <div>
        <AlertMessageQRScan open={true} advice={"error"} />
      </div>
    );
  }
};

export default PersonalInfo;
