import { Box, Container } from '@mui/material'
import { FC, ReactElement } from 'react';
import { styled as muStyled } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';

const styles = {
  boxContainer: {
    marginTop: 6,
    alignItems: "center",
    textAlign: "center"
  }
}

const StyledContainer = muStyled(Container, {
  shouldForwardProp: (prop: any) => prop !== 'isRaised',
})<{ isRaised?: boolean; }>(({ isRaised = false }) => ({
  padding: 20,
  paddingTop: isRaised ? 0 : 10,
  paddingBottom: isRaised ? 0 : 60,
  position: "relative",
  zIndex: 5,
}));

const styleContainer = {
  background:"linear-gradient(180deg, #3B8AFF 0%, #0048B3 100%)"
};

interface LayoutContainerProps {
  isRaised?: boolean;
  children: React.ReactNode | React.ReactNode[];
  sx?: {};
}

export const LayoutContainer: FC<LayoutContainerProps> = (props): ReactElement => {
  return (
    <StyledContainer isRaised={props.isRaised} maxWidth={false} sx={props.sx} className="styleContainer">
      <CssBaseline />
      <Box sx={styles.boxContainer}>
        {props.children}
      </Box>
    </StyledContainer>
  )
}
