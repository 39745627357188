import DashboardLayout from "../../components/Common/DashboardLayout"
import ImportUsersComponent from "../../components/ImportUsers"
import useAuthGuard from "../../Hooks/useAuthGuard"

const ImportUsers = () => {
  useAuthGuard({
    requiredAdmin: true,
  })
  return (
    <DashboardLayout page="importUsers">
      <ImportUsersComponent />
    </DashboardLayout>
  )
}

export default ImportUsers
