import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import ImportUsersFilter from "../../../components/ImportUsers/ImportUsersFilter/ImportUsersFilter";
import useAuthUserData from "../../../Hooks/useAuthUserData";
import {
  useDefaultActiveSessionLengthForAgency,
  useFirstResponders,
  useFirstRespondersForAgency,
} from "../../../Hooks/useFirstResponderService";
import { FirstResponderData } from "../../../Interfaces/FirstResponder";
import { sendFirstResponderInvitation } from "../../../Services/emailService";
import {
  getFirstResponderByEmail,
  registerFirstResponder,
  updateFirstResponderbyId,
} from "../../../Services/firstResponderService";
import SendImportInvitesDialog from "../../SendImportInvitesDialog";
import ImportUsersTable from "../ImportUsersTable/ImportUsersTable";
import { StepProps } from "../util";

export const STATUSES = {
  all: {
    label: "All Results",
    color: "#000094",
  },
  valid: {
    label: "Valid",
    color: "#01BB4B",
  },
  invalid: {
    label: "Invalid",
    color: "#FC6868",
  },
  emailUsed: {
    label: "Email Used",
    color: "#FC6868",
  },
  fileDuplicate: {
    label: "Duplicate in file",
    color: "#FF8642",
  },
  agencyDuplicate: {
    label: "Duplicate in agency",
    color: "#FF8642",
  },
};

export type StatusesType = keyof typeof STATUSES;

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  border: "2px solid #000094",
  width: 22,
  height: 22,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage: theme.palette.mode === "dark" ? "#000094" : "#000094",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: theme.palette.mode === "dark" ? "#000094" : "#000094",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#000094",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 18,
    height: 18,
    backgroundImage: "radial-gradient(#fff ,#fff 43%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#000094",
  },
});

// Inspired by blueprintjs
function BpRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

interface Props extends StepProps {
  firstResponders: FirstResponderData[];
  setFirstResponders: (firstResponders: FirstResponderData[]) => void;
}

const PreviewResults: FC<Props> = ({
  setFirstResponders,
  prevStep,
  firstResponders,
}) => {
  const { user } = useAuthUserData();
  const { defaultActiveSessionLength } =
    useDefaultActiveSessionLengthForAgency();
  const {
    firstRespondersValidated,
    validAmount,
    invalidAmount,
    duplicatesAmount,
    duplicatesToImportAmount,
    loading: loadingValidation,
  } = useValidation(firstResponders);
  const [status, setStatus] = useState<StatusesType>("all");
  const [searchInput, setSearchInput] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<FRValidated[]>(
    firstRespondersValidated || []
  );
  const [overwrite, setOverwrite] = useState(false);
  const [success, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState<"invite" | "noinvite" | false>(
    false
  );

  const handleSubmit = () => {
    setLoading(true);
    const status = openDialog === "invite" ? "invited" : "new";

    const validFirstResponders = firstRespondersValidated.filter(
      (fr) => !["invalid", "emailUsed"].includes(fr.status)
    );

    const registerNewFirstRespondersPromises = validFirstResponders
      .filter((fr) => fr.status === "valid")
      .map(async (fr) => {
       /* const otp =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);*/

        const res = (await registerFirstResponder({
          ...fr,
          status,
          agency: user.agency,
          rol: "firstResponder",
          username: fr.email,
         // otp,
          activeSessionLength: defaultActiveSessionLength,
        })) as FirstResponderData;


        /*if (status === "invited") {
          await sendFirstResponderInvitation({
            idCode: res.mmhId,
            password: otp,
            adminUsername: user.username,
            email: fr.email,
          });
        }*/
      });

    let registerDuplicatesFirstRespondersPromises: Promise<void>[] = [];

    if (overwrite) {
      registerDuplicatesFirstRespondersPromises = validFirstResponders
        .filter((fr) => fr.status !== "valid")
        // get unique duplicates first responders based on email
        .filter((fr, index, arr) => {
          return (
            arr.findIndex((duplicateFr) => duplicateFr.email === fr.email) ===
            index
          );
        })
        .map(async (fr) => {
          const otp =
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);

          if (fr.status === "agencyDuplicate") {
            const existingFR: FirstResponderData =
              await getFirstResponderByEmail(fr.email);
            const res = (await updateFirstResponderbyId(existingFR.id, {
              ...fr,
              status: existingFR.status,
              activeSessionLength: defaultActiveSessionLength,
            })) as FirstResponderData;

            if (status === "invited") {
              const resEmail = await sendFirstResponderInvitation({
                idCode: res.mmhId,
                password: otp,
                adminUsername: user.username,
                email: fr.email,
              });
              return resEmail;
            } else {
              return res;
            }
          } else if (fr.status === "fileDuplicate") {
            const res = (await registerFirstResponder({
              ...fr,
              status,
              agency: user.agency,
              rol: "firstResponder",
              username: fr.email,
             // otp,
              activeSessionLength: defaultActiveSessionLength,
            })) as FirstResponderData;

            /*if (status === "invited") {
              await sendFirstResponderInvitation({
                idCode: res.mmhId,
                password: otp,
                adminUsername: user.username,
                email: fr.email,
              });
            }*/
          }
        });
    }

    Promise.allSettled([
      ...registerNewFirstRespondersPromises,
      ...registerDuplicatesFirstRespondersPromises,
    ]).then(() => {
      setLoading(false);
      setSucess(true);
    });
  };

  useEffect(() => {
    // filter users by status and search input
    if (firstRespondersValidated && !loadingValidation) {
      const _filteredUsers = firstRespondersValidated.filter((fr) => {
        const isStatusMatched = status === "all" ? true : fr.status === status;
        const searchableAttributes = [
          "firstName",
          "lastName",
          "email",
          "badge",
          "city",
          "state",
          "address",
          "zipCode",
        ] as const;
        const isSearchInputMatched = searchableAttributes.reduce(
          (acc, attr) => {
            return (
              acc ||
              fr[attr as (typeof searchableAttributes)[number]]
                ?.toLowerCase()
                .includes(searchInput.toLowerCase())
            );
          },
          false
        );

        return isStatusMatched && isSearchInputMatched;
      });
      setFilteredUsers(_filteredUsers);
    }
  }, [searchInput, status, firstRespondersValidated, user]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="center"
      textAlign="center"
      bgcolor="#fff"
      sx={{ height: "100%" }}
    >
      <Grid item height={"180px"}>
        <Typography color="#242627" fontSize={25} fontWeight={700}>
          Preview results before import
        </Typography>
        <Typography color="#242627" fontSize={16}>
          <br />
          Please make sure that all records you import are correct. After import
          completed all valid records will be added to the User Management
          section and you’ll be able to manage them from there.
        </Typography>
      </Grid>
      <Grid
        container
        direction="column"
        rowSpacing={3}
        alignItems="stretch"
        bgcolor={"#F2F4F8"}
      >
        {/* Actions and filter */}
        <Grid item container direction="row" justifyContent="space-between">
          <Grid item width={"100%"}>
            <ImportUsersFilter
              status={status}
              setStatus={setStatus}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              validUsers={validAmount}
              invalidUsers={invalidAmount}
              duplicatesUsers={duplicatesAmount}
            />
          </Grid>
        </Grid>
        {/* Table */}
        <Grid item>
          <ImportUsersTable
            users={filteredUsers}
            isloading={loadingValidation}
            setFirstResponders={setFirstResponders}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ height: "70px", padding: "15px" }}>
        <Grid item xs>
          <Button
            variant="outlined"
            sx={{ color: "#000094", textTransform: "none" }}
            startIcon={<ChevronLeftIcon />}
            onClick={prevStep}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6} textAlign={"right"}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-customized-radios"
              name="customized-radios"
              value={overwrite ? "overwrite" : "skip"}
              onChange={(e) => {
                setOverwrite(e.target.value! === "overwrite");
              }}
            >
              <FormControlLabel
                value="skip"
                control={<BpRadio />}
                label="Skip duplicates"
              />
              <FormControlLabel
                value="overwrite"
                control={<BpRadio />}
                label="Overwrite duplicates"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs>
          <Button
            variant="outlined"
            sx={{ color: "#000094", textTransform: "none", marginRight: "3px" }}
            onClick={() => {
              setOpenDialog("noinvite");
            }}
          >
            Import
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            color="success"
            onClick={() => {
              setOpenDialog("invite");
            }}
          >
            Import & Invite
          </Button>
          <SendImportInvitesDialog
            open={!!openDialog}
            users={
              overwrite ? validAmount + duplicatesToImportAmount : validAmount
            }
            handleClose={() => setOpenDialog(false)}
            bulk={openDialog === "invite"}
            success={success}
            loading={loading}
            handleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PreviewResults;

interface FRValidated {
  firstName: string;
  lastName: string;
  email: string;
  badge: string;
  city: string;
  state: string;
  address: string;
  zipCode: string;
  status: StatusesType;
}

type FRWithoutStatus = Omit<FRValidated, "status">;

const useValidation = (firstResponders: FRWithoutStatus[]) => {
  const { firstResponders: agencyFirstResponders, loading: agencyFRLoading } =
    useFirstRespondersForAgency();
  const { firstResponders: allFirstResponders, loading: allFRLoading } =
    useFirstResponders();
  const [firstRespondersValidated, setFirstRespondersValidated] = useState<
    FRValidated[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (agencyFRLoading || allFRLoading) return;

    let _firstResponderValidated: FRValidated[] = [];
    firstResponders.forEach((fr) => {
      if (
        !validateEmail(fr.email) ||
        !validateZipCode(fr.zipCode) ||
        !fr.firstName ||
        !fr.lastName ||
        !fr.badge ||
        !fr.city ||
        !fr.state ||
        !fr.address ||
        !fr.zipCode
      )
        return _firstResponderValidated.push({
          ...fr,
          status: "invalid",
        });

      if (agencyFirstResponders.map((_fr) => _fr.email).includes(fr.email))
        return _firstResponderValidated.push({
          ...fr,
          status: "agencyDuplicate",
        });

      if (allFirstResponders.some((_fr) => _fr.email === fr.email)) {
        return _firstResponderValidated.push({
          ...fr,
          status: "emailUsed",
        });
      }

      if (firstResponders.filter((_fr) => _fr.email === fr.email).length > 1)
        return _firstResponderValidated.push({
          ...fr,
          status: "fileDuplicate",
        });

      return _firstResponderValidated.push({ ...fr, status: "valid" });
    });

    setFirstRespondersValidated(_firstResponderValidated);
    setLoading(false);
  }, [
    firstResponders,
    agencyFRLoading,
    allFRLoading,
    agencyFirstResponders,
    allFirstResponders,
  ]);

  return {
    firstRespondersValidated,
    loading: loading || agencyFRLoading || allFRLoading,
    validAmount: firstRespondersValidated.filter((fr) => fr.status === "valid")
      .length,
    invalidAmount: firstRespondersValidated.filter((fr) =>
      ["invalid", "emailUsed"].includes(fr.status)
    ).length,
    duplicatesAmount: firstRespondersValidated.filter((fr) =>
      ["fileDuplicate", "agencyDuplicate"].includes(fr.status)
    ).length,
    duplicatesToImportAmount: Array.from(
      new Set(
        firstRespondersValidated
          .filter((fr) =>
            ["fileDuplicate", "agencyDuplicate"].includes(fr.status)
          )
          .map((fr) => fr.email)
      )
    ).length,
  };
};

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validateZipCode = (zipCode: string) => {
  return Boolean(new RegExp(/^[0-9]*$/).test(zipCode));
};
