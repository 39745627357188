import { FC, useState } from "react"

import ReactInputVerificationCode from "react-input-verification-code"
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import Box from '@mui/material/Box';
import LoadingButton from "@mui/lab/LoadingButton"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useStepVerificationContext } from "../../contexts/StepVerificationContext"
import { useVerifyCode } from "../../Hooks/useFirstResponderService";

const StepVerificationInputCode: FC = () => {
  const { setDialogStatus, isActive, setIsActive } = useStepVerificationContext();
  const { control, formState: { errors }, handleSubmit } = useForm();
  const { isloading, status, handleClickButton } = useVerifyCode()

  if (status?.success) {
    setDialogStatus({ start: false, stepVerify: false, stepverifySuccess: true })
  }

  const onSubmit: SubmitHandler<any> = async ({ code_confirmation }) => {
    handleClickButton!({ code_confirmation, mfa: true  })
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid item mt={2} ml={6}>
        <Typography color="#242627" fontSize={16} fontWeight={700}>
          2. Enter the confirmation code
        </Typography>

        <Typography color="#6A7288" fontSize={15} fontWeight={500} mb={1}>
          Please enter the code sent to your email
        </Typography>

        <Controller
          name="code_confirmation"
          control={control}
          defaultValue=""
          render={({ field }) =>
            <ReactInputVerificationCode {...field} length={5} placeholder="" />
          }
          rules={{
            required: true,
            minLength: 5
          }}
        />

        {errors.code_confirmation?.type === 'required' &&
          <Typography mt={1} color="#FC6868">Confirmation code is required</Typography>
        }

        {errors.code_confirmation?.type === 'minLength' &&
          <Typography mt={0.5} color="#FC6868">Minimum number of characters is 5</Typography>
        }

        {status?.error &&
          <Typography mt={0.5} color="#FC6868">Invalid Code</Typography>
        }
      </Grid>

      <Grid item mt={3} ml={6}>
        <LoadingButton
          loading={isloading}
          variant="contained"
          sx={{
            height: 48,
            width: 108,
            textTransform: 'none',
            fontSize: '1rem',
            color: '#FFF',
            borderRadius: '8px',
            backgroundColor: '#000094',
            fontWeight: 700,
          }}
          type="submit"
        >
          Activate
        </LoadingButton>
      </Grid>
    </Box>
  )
}

export default StepVerificationInputCode