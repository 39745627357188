import { CookieStorage } from "amazon-cognito-identity-js"

export const REMEMBER_COOKIE_KEY_USER = "__remembered_fr_user"
export const REMEMBER_COOKIE_KEY_ADMIN = "__remembered_fr_admin_user"

export function handleCookies() {
    return new CookieStorage({ "secure": true, domain: "/" })
}

export function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}