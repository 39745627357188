import HelpVideos from "../components/helpVideos/helpVideos"
import useAuthGuard from "../Hooks/useAuthGuard"
const PageNotFound = () => {
  useAuthGuard({
    requiredAdmin: false,
  })
  return (
    <div>
      <HelpVideos />
    </div>
  )
}

export default PageNotFound
