import React from 'react'
import PropTypes from 'prop-types'
import "./customAlert.css";
import Alert from "@mui/material/Alert";
import ErroIcon from "../../Assets/ico-error.svg"
const CustomAlert = ({type, message}) => {
  return (
    <div>
      <Alert severity={type}
        className={'custom-alert-' + type}
        icon={<img src={ErroIcon} height='20' width='20'></img>}>
              {message}
          </Alert>
    </div>
  )
}

CustomAlert.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string
}

export default CustomAlert
