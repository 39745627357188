// local variables
const local = {
  s3: {
    BUCKET: 'YOUR_DEV_S3_UPLOADS_BUCKET_NAME',
  },
  api: {
    ENDPOINT: 'http://localhost:3000/local',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_X9hLvctoF',
    APP_CLIENT_ID: '569onc4tkvhs7csqe2duappll4',
    IDENTITY_POOL_ID: 'us-east-1:93b9a3d4-c48e-4f07-a3da-aa013804187c',
  },
};

// dev variables
const dev = {
  s3: {
    BUCKET: 'YOUR_DEV_S3_UPLOADS_BUCKET_NAME',
  },
  api: {
    ENDPOINT: 'https://dev.api.mymedhistory.com',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_X9hLvctoF',
    APP_CLIENT_ID: '569onc4tkvhs7csqe2duappll4',
    IDENTITY_POOL_ID: 'us-east-1:93b9a3d4-c48e-4f07-a3da-aa013804187c',
  },
};

// stage variables
const stage = {
  s3: {
    BUCKET: 'YOUR_DEV_S3_UPLOADS_BUCKET_NAME',
  },
  api: {
    ENDPOINT: 'https://test.api.mymedhistory.com',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_2G0UprOj7',
    APP_CLIENT_ID: '39a8jdgdo8ef749fo676d185q',
    IDENTITY_POOL_ID: 'us-east-1:bd4093ba-0e0c-4641-b654-cd9d6b4bac39',
  },
};

// prod variables
const prod = {
  s3: {
    BUCKET: 'YOUR_DEV_S3_UPLOADS_BUCKET_NAME',
  },
  api: {
    ENDPOINT: 'https://api.mymedhistory.com',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_uKHSKvtA8',
    APP_CLIENT_ID: '32s62jm0unevvm4u8fblc62nas',
    IDENTITY_POOL_ID: 'us-east-1:e61dab2b-666a-48dd-b59c-10e28d64c6c7',
  },
};

const config =
  process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : process.env.REACT_APP_STAGE === 'stage'
    ? stage
    : process.env.REACT_APP_STAGE === 'dev'
    ? dev
    : local;

const sharedObj = {
  ...config,
};
export default sharedObj;
