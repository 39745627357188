export function RequiredStringField(fieldName: string, value: string): string {
  if (typeof value !== "string") return "";

  return !value.trim() ? `The field ${fieldName} is required` : "";
}

export function RequiredNumberField(fieldName: string, value: number): string {
  return !value ? `The field ${fieldName} must be greater than zero` : "";
}

export function IsLessThanNumberField(
  firstFieldName: string,
  firstValue: number,
  lastFieldName: string,
  lastValue: number
): string {
  return lastValue <= firstValue
    ? `The field ${lastFieldName} must be greater than ${firstFieldName}`
    : "";
}

export function MaxCharCountField(
  fieldName: string,
  value: string,
  maxValue: number
): string {
  return !RegExp(`^.{0,${maxValue}}$`).test(value)
    ? `The ${fieldName} field must not exceed ${maxValue} characters`
    : "";
}
export function MinCharCountField(
  fieldName: string,
  value: string,
  minValue: number
): string {
  return value.length < minValue
    ? `The ${fieldName} field must not be less than ${minValue} characters`
    : "";
}

export function IsValidEmail(email: string): string {
  return /^[\w-\\.\\+]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
    ? ""
    : "Invalid email format";
}

export const validateBulkEmpty = (users: any, isSelection: any): string =>
  !isSelection || users.length === 0 ? "" : `(${users.length})`;
